import * as React from "react";
import {
  DataGrid,
  //GridToolbarContainer,
  //GridToolbarExport,
  GridActionsCellItem,
  GridCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import { useSelector, useDispatch } from "react-redux";
import Iconify from "../Iconify";

import {
  linkEventToAnother,
  clearEventLink,
} from "../../features/personalDatabaseSlice";

//import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Box } from "@mui/material";

import { useSnackbar } from "notistack";
import CustomDialogue from "../CustomDialogue";

import { DataValidationError } from "../../utils/data/errors";
import Moment from "moment";

// Export toolbar example
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        debounceMs={500} // time before applying the new quick filter value
      />
    </Box>
  );
}

export default function LinkEventTable(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const myDialogue = React.useRef(null);

  const { event } = props;

  const events = useSelector(
    // we do not want to show system generated events category
    (state) =>
      state.personalDatabase.contents.events.filter(
        (cur_event) => cur_event.categoryId !== 0 && cur_event.id !== event.id
      )
  );

  // const processRowUpdate = (newRow) => {
  //   console.log("Storing...", newRow);
  //   dispatch(editCategory(newRow));
  //   return newRow;
  // };

  const columns = [
    {
      field: "datetime",
      headerName: "Date",
      width: 150,
      sortable: false,
      editable: false,

      valueGetter: ({ value }) => value && Moment(value).format("DD/MM/YYYY"),
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      sortable: false,
      minWidth: 200,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Link status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 150,
      // getActions: (params) => [
      //   <GridActionsCellItem
      //     icon={<DeleteIcon />}
      //     label="Delete"
      //     onClick={deleteCategoryById(params.id)}
      //     showInMenu
      //   />,
      // ],
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params);

          if (isLinkedToEvent) {
            // unlink?
            dispatch(clearEventLink({ event: params.row }));
          } else if (canBeLinked) {
            dispatch(
              linkEventToAnother({
                eventLinkOrigin: event,
                eventLinkTarget: params.row,
              })
            );
          } else {
            myDialogue.current
              .confirm("Event link override", [
                "Are you sure you override the other event's link and link it to the current one?",
              ])
              .then(() => {
                console.log("Proceeding");
                dispatch(
                  linkEventToAnother({
                    eventLinkOrigin: event,
                    eventLinkTarget: params.row,
                  })
                );
              })
              .catch((err) => {
                console.log("Cancelled/error", err);
              });
          }
        };

        const isLinkedToEvent =
          event.linkGroup === params.row.linkGroup && event.linkGroup !== 0;
        const canBeLinked = params.row.linkGroup === 0;
        return (
          <Button
            key={`button-${params.row.id}`}
            size="small"
            onClick={onClick}
            variant={isLinkedToEvent ? "text" : "outlined"}
            color={isLinkedToEvent ? "primary" : "inherit"}
            startIcon={
              isLinkedToEvent && <Iconify icon={"eva:checkmark-fill"} />
            }
          >
            {canBeLinked
              ? "Link"
              : isLinkedToEvent
              ? "Linked"
              : "Linked to another"}
          </Button>
        );
      },
    },

    // { field: 'lastName', headerName: 'Last name', width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  // const handleProcessRowUpdateError = React.useCallback((error) => {
  //   alert(error.message);
  // }, []);

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          // disable focus style
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader":
              {
                outline: "none !important",
              },
          }}
          rows={events}
          columns={columns}
          pageSize={10}
          // checkboxSelection={true}
          disableSelectionOnClick
          disableColumnSelector
          isRowSelectable={() => false}
          rowsPerPageOptions={[10, 25, 50]}
          components={{ Toolbar: QuickSearchToolbar }}
          // checkboxSelection
          // components={{ Toolbar: CustomToolbar }}
          // processRowUpdate={processRowUpdate}
          // onProcessRowUpdateError={handleProcessRowUpdateError}
          // experimentalFeatures={{ newEditfingApi: true }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
        />
      </div>
      <CustomDialogue ref={myDialogue} />
    </>
  );
}
