// material
import { Box, Container, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";

// components
import Page from "../components/Page";
import MeasurementsCategorySelector from "../components/measurements/MeasurementsCategorySelector";
import AddMeasurementFormDialogue from "../components/measurements/AddMeasurementFormDialogue";
import MeasurementBrowserTable from "../components/measurements/MeasurementBrowserTable";

import { useSelector } from "react-redux";

import { getAvailableUnits } from "../utils/units";

import ChartJSExample from "../components/charts/ChartJSExample";

// import {
//   VerticalTimeline,
//   VerticalTimelineElement,
// } from "react-vertical-timeline-component";
// import "react-vertical-timeline-component/style.min.css";
// import "../adhoc/vertical-load-more.css";

// test data structure

// view "window" - what are we looking at?
// import Moment from "moment";

export default function MeasurementsApp() {
  const [currentMeasurementCategoryId, setCurrentMeasurementCategoryId] =
    useState("");

  const [currentMeasurementCategory, setCurrentMeasurementCategory] = useState(
    {}
  );

  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );

  // const loadElements = () => {
  //   setElements(dataExamples.slice(currentWindowStart, currentWindowEnd));
  // };

  // useEffect(() => {
  //   loadElements();
  // }, []);

  const pageStyle = {};

  const onChange = () => {
    console.log("Changed");
  };

  const setCurrentMeasurementCategoryHandler = (categoryId) => {
    setCurrentMeasurementCategoryId(categoryId);
    console.log("Populating...");

    const currentCategory = personalDatabaseContents.measurementCategories.find(
      (obj) => obj.id === categoryId
    );

    // store the full category object
    setCurrentMeasurementCategory(currentCategory);
  };

  return (
    <Page title="Measurements | Holistory" style={pageStyle}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h3">Measurements</Typography>
        </Box>

        {personalDatabaseContents === null && <>No database loaded.</>}

        {personalDatabaseContents &&
          personalDatabaseContents.measurementCategories.length === 0 && (
            <>No measurement categories are present.</>
          )}

        {personalDatabaseContents &&
          personalDatabaseContents.measurementCategories.length > 0 && (
            <Box>
              <MeasurementsCategorySelector
                currentMeasurement={currentMeasurementCategoryId}
                setCurrentMeasurementCategory={
                  setCurrentMeasurementCategoryHandler
                }
              />
              <br />
              {currentMeasurementCategoryId && currentMeasurementCategory && (
                <>
                  <Box sx={{ pt: 5 }}>
                    {/* <strong>{currentMeasurementCategory.name}</strong>, ID:{" "}
                    {currentMeasurementCategoryId}
                    <br />
                    Type: {currentMeasurementCategory.type}
                    <br />
                    Available units:{" "}
                    {getAvailableUnits(currentMeasurementCategory.type).map(
                      (unit) => (
                        <span key={unit}>{unit} </span>
                      )
                    )} */}
                    <br />
                    <MeasurementBrowserTable
                      currentMeasurementCategory={currentMeasurementCategory}
                    />
                    <AddMeasurementFormDialogue
                      currentMeasurementCategory={currentMeasurementCategory}
                    />
                  </Box>
                  <Box sx={{ pb: 2, pt: 7 }}>
                    <Typography variant="h4">Visualise Data</Typography>
                  </Box>
                  {/* Chart example */}
                  <Box sx={{ pb: 7 }}>
                    <ChartJSExample
                      currentMeasurementCategory={currentMeasurementCategory}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}
      </Container>
    </Page>
  );
}
