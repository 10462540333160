import HTTP from "../http";
/**
 * Attempt to login the user with the provided email and password.
 * @param {String} email
 * @param {String} password
 * @param {String} csrf
 */
function login(email, password, csrf) {
  return HTTP.post(
    "/auth/login/",
    {
      username: email,
      password: password,
      csrftoken: csrf,
    },
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
}

function fetchToken() {
  return HTTP.get("/api/v1/token/");
}

export default {
  login,
  fetchToken,
};
