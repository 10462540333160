import React, { useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";

import _uniqueId from "lodash/uniqueId";
import { Input } from "@mui/material";

export default function OurFileUploadButton(props) {
  const { label, handleUploadedFile, setLoaderOpen } = props;
  const {
    //setValue,
    clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const [error, setError] = useState(null);
  const [id] = useState(_uniqueId("restoredatabase-"));

  const handleChange = (e) => {
    const fileReader = new FileReader();
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const inputEl = e.target;
    console.log("File size:", fileSize); // in bytes

    if (inputEl.value != null) {
      setLoaderOpen(true);
    }

    const maxFileSizeMB = 10;
    if (fileSize > maxFileSizeMB * 1024 * 1024) {
      setError(`Please upload a file smaller than ${maxFileSizeMB}MB.`);
      inputEl.value = null;
      setLoaderOpen(false);
      return;
    }
    // read as binary string
    fileReader.readAsArrayBuffer(e.target.files[0]);
    // fileReader.onerror = (e) => {
    //   setError(e.toString());
    //   setLoaderOpen(false);
    //   inputEl.value = null;
    // };
    fileReader.onload = (e) => {
      const fileContents = e.target.result;
      setError(null);
      try {
        handleUploadedFile(fileName, fileContents);
      } catch (e) {
        console.log(e);
        setLoaderOpen(false);
        setError(e.toString());
      } finally {
        // clear the input to allow for the same file to be tried again
        inputEl.value = null;
      }
    };
  };

  return (
    <>
      <label htmlFor={id + "contained-button-file"}>
        <Input
          style={{ display: "none" }}
          id={id + "contained-button-file"}
          type="file"
          onChange={handleChange}
        />
        <Button variant="contained" component="span">
          {label}
        </Button>
      </label>
      {error !== null && (
        <Alert severity="error">File upload processing failed: {error}</Alert>
      )}
    </>
  );
}
