import * as React from "react";

import { useSelector } from "react-redux";
import Moment from "moment";

import VerticalTimelineItem from "./VerticalTimelineItem";
//import { Virtuoso } from "react-virtuoso";
import { GroupedVirtuoso } from "react-virtuoso";

import { useRef, useEffect, useState, useMemo } from "react";

import { styled } from "@mui/material/styles";
import * as _ from "lodash";

import { genDatabaseGroups } from "../../utils/data/events";
import {
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP,
} from "../../layouts/dashboard/index.js";

import { getEventData } from "../../utils/data/selectors";
import { FILTER_DESKTOP } from "./TimelineFilters";
//import { ConstructionOutlined } from "@mui/icons-material";

const VerticalTimelineStyledContainer = styled("div")(({ theme }) => ({
  height: `calc(100vh - ${APP_BAR_MOBILE + FILTER_DESKTOP + 2}px)`,
  [theme.breakpoints.up("lg")]: {
    height: `calc(100vh - ${APP_BAR_DESKTOP + FILTER_DESKTOP + 2}px)`,
  },
}));

const THROTTLE_DELAY = 500;

export default function VerticalTimeline(props) {
  const {
    currentDate,
    setCurrentDate,
    verticalTimelineScrollToIndexRef,
    showAddOrEditEventDialogue,
    //eventData,
  } = props;
  const virtuoso = useRef(null);

  const eventData = useSelector(getEventData);

  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  const scrollToIndex = (index) => {
    console.log("Scrolling to ", index);

    console.log(eventData[index]);

    virtuoso.current.scrollToIndex({
      index: index,
      align: "top",
      // behavior: "smooth", // smooth cannot be used as that "renders" elements before our visible range
    });
  };

  // https://dilshankelsen.com/call-child-function-from-parent-component-in-react/
  useEffect(() => {
    // we got a useRef as prop, let's assign to it our scrollToIndex function
    verticalTimelineScrollToIndexRef.current = scrollToIndex;
  }, []);

  const [groupData, setGroupData] = useState({
    groups: [],
    groupCounts: [],
  });

  useEffect(() => {
    console.log("Events changed, re-calculating groups");

    const { groupCounts, groups } = genDatabaseGroups(eventData);

    setGroupData({
      groups: groups,
      groupCounts: groupCounts,
    });
  }, [eventData]); // <-- dependency array

  // we need to memoise this
  const debouncedUpdateCurrentDateHandler = useMemo(
    () =>
      _.throttle((visibleRange) => {
        console.log("Current range changed - throttled");
        console.log(visibleRange);

        // TODO - find "real" event that is not month/year separator inside of the visible
        // range. If there is nothing - do we need a different highlight colour?

        const centerEvent =
          eventData[
            //Math.floor((visibleRange.startIndex + visibleRange.endIndex) / 2)
            visibleRange.startIndex
          ];
        console.log(centerEvent);

        // set datetime
        setCurrentDate(Moment(centerEvent.datetime));

        // TODO scroll nav into view?
      }, THROTTLE_DELAY),
    [eventData] // when eventData changes, this becomes obsolete
  );
  // Stop the invocation of the debounced function after unmounting

  useEffect(() => {
    return () => {
      debouncedUpdateCurrentDateHandler.cancel();
    };
  }, [debouncedUpdateCurrentDateHandler]);

  useEffect(() => {
    // run something every time name changes
    console.log("Current range changed - immediate");
    debouncedUpdateCurrentDateHandler(visibleRange);
  }, [visibleRange]); // <-- dependency array

  return (
    <VerticalTimelineStyledContainer>
      {eventData && groupData.groups && groupData.groupCounts && (
        <GroupedVirtuoso
          // data={eventData}
          ref={virtuoso}
          groupCounts={groupData.groupCounts}
          groupContent={(index) => {
            return (
              <div
                style={{
                  backgroundColor: "white",
                  paddingTop: "31px",
                  marginTop: "2px",
                  borderBottom: "1px solid #ccc",
                  width: "50px",
                  marginLeft: "auto",
                  height: "1px",
                  visibility: "hidden",
                }}
              >
                {groupData.groups[index]}
              </div>
            );
          }}
          //overscan={{ main: 1000, reverse: 1000 }}
          rangeChanged={setVisibleRange}
          increaseViewportBy={{ top: 1000, bottom: 2000 }}
          itemContent={(index) => {
            return (
              <VerticalTimelineItem
                key={eventData[index].id}
                event={eventData[index]}
                scrollToIndex={scrollToIndex}
                showAddOrEditEventDialogue={showAddOrEditEventDialogue}
              />
            );
          }}
        />
      )}
    </VerticalTimelineStyledContainer>

    // <List sx={{ width: "100%" }}>
    //   {eventData.map((event) => (
    //     <VerticalTimelineItem key={event.id} event={event} />
    //   ))}
    // </List>
  );
}
