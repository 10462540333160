import * as React from "react";
import {
  DataGrid,
  //GridToolbarContainer,
  //GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";

import { editTag, deleteTag } from "../../features/personalDatabaseSlice";
import { setTagFilter } from "../../features/uiStateSlice";

import DeleteIcon from "@mui/icons-material/Delete";
import AddTagDialogue from "./AddTagDialogue";
import CustomDialogue from "../CustomDialogue";

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function TagTable() {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.personalDatabase.contents.tags);
  const myDialogue = React.useRef(null);

  const processRowUpdate = (newRow) => {
    console.log("Storing...", newRow);
    dispatch(editTag(newRow));
    return newRow;
  };

  const deleteCategoryById = (id) => () => {
    myDialogue.current
      .confirm(`Are you sure you want to delete tag ${id}?`, [
        "It will be removed from all parts of the app.",
      ])
      .then(() => {
        console.log("Deleting ", id);
        dispatch(deleteTag(id));
        dispatch(setTagFilter([])); // reset tag filter
        // now reset filters in case tag was selected
      })
      .catch((response) => {
        console.log("Cancelled - not deleting", id);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },
    {
      field: "name",
      headerName: "Tag name",
      editable: true,
      minWidth: 200,
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={deleteCategoryById(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const handleProcessRowUpdateError = React.useCallback((error) => {
    alert(error.message);
  }, []);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={tags}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        // checkboxSelection
        // components={{ Toolbar: CustomToolbar }}
        disableSelectionOnClick
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
      <AddTagDialogue />
      <CustomDialogue ref={myDialogue} />
    </div>
  );
}
