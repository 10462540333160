import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  csrftoken: "",
  loading: true,
  user: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    records: [],
  },
  sessionActive: false,
};

export const loginStateSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    storeCSRFToken: (state, action) => {
      state.csrftoken = action.payload;
    },

    storeUser: (state, action) => {
      const userObject = {
        id: action.payload.id,
        email: action.payload.email,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        records: action.payload.records,
      };

      state.user = userObject;
      state.sessionActive = true; // automatically mark the session as active
      state.loading = false;
    },
    setSessionAsInactive: (state, action) => {
      state.sessionActive = false;
      state.loading = false;
    },
    setLoginStateAsLoading: (state, action) => {
      state.loading = true;
    },
  },
});

export const {
  storeCSRFToken,
  storeUser,
  setSessionAsInactive,
  setLoginStateAsLoading,
} = loginStateSlice.actions;

export default loginStateSlice.reducer;
