import Moment from "moment";

import { getAllDbNames } from "../utils/data/indexes";

export const indexedDbMarkerTask = function (store) {
  const state = store.getState();

  if (!state.personalDatabase.contents) {
    //console.log("Database not ready");
    // database is ready - let's proceed
    return;
  }

  const editLock = state.personalDatabase.editLock;
  console.log("indexedDbMarkers", editLock);

  // mark up current indexes
  const currentDbNames = getAllDbNames(editLock);
  console.log(currentDbNames);

  // level-js uses specific prefix, apply it now

  const fullDbNames = currentDbNames.map((dbName) => `level-js-${dbName}`);

  // now refresh
  const isoTimestamp = Moment().toISOString();

  fullDbNames.map((fullDBName) => {
    console.log(fullDBName, isoTimestamp);
    localStorage.setItem(`state-keepdb-${fullDBName}`, isoTimestamp);
  });
};

export const indexedDbCleanTask = function () {
  window.check_and_clean_indexeddb();
};
