// @mui
import {
  Box,
  Table,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from "@mui/material";
// routes
// hooks
import useTable, { getComparator, emptyRows } from "../../../hooks/useTable";
import { default as lodashOrderBy } from "lodash/orderBy";

// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../../../components/table";
// sections
import AttachmentTableRow from "./AttachmentTableRow";
import { useSnackbar } from "notistack";

import { useSelector, useStore } from "react-redux";

import { downloadAsset } from "../../../utils/data/attachments";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "File name", align: "left" },
  { id: "type", label: "File type", align: "left" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function AttachmentList(props) {
  const { event } = props;
  const { enqueueSnackbar } = useSnackbar();
  const store = useStore();
  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultDense: true });

  const tableData = useSelector((state) => {
    return lodashOrderBy(
      event.attachments
        .map((attachmentId) => {
          const attachmentInfo =
            state.personalDatabase.contents.fileStorage[attachmentId];
          return {
            id: attachmentId,
            name: attachmentInfo.fileName,
            type: attachmentInfo.fileType,
            icon: null, //"eva:attach-2-fill",
            isThumbnail: attachmentInfo.isThumbnail,
            mime: attachmentInfo.mime,
          };
        })
        .filter((attachment) => attachment.isThumbnail === false),
      [orderBy],
      [order]
    );
  });

  const handleDownloadRow = (id) => {
    const state = store.getState();
    console.log("Download", id);

    const attachment = state.personalDatabase.contents.fileStorage[id];

    if (attachment === undefined) {
      enqueueSnackbar(`Unable to download ${id}`, {
        variant: "error",
      });
      return;
    }

    // get our attachment with all its data
    const attachmentExpanded = {
      ...state.personalDatabase.contents.fileStorage[id],
      id: id,
    };

    console.log("Download", attachmentExpanded);

    downloadAsset(currentRecord, attachmentExpanded, enqueueSnackbar);
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData ? true : !tableData.length;

  return (
    <Box sx={{ mt: "-35px", mb: "-35px" }}>
      {/* <Divider /> */}

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          {selected.length > 0 && (
            <TableSelectedActions
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData ? tableData.length : 0}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              actions={
                <Tooltip title="Delete">
                  <IconButton
                    color="primary"
                    onClick={() => alert("Not implemented")}
                  >
                    <Iconify icon={"eva:trash-2-outline"} />
                  </IconButton>
                </Tooltip>
              }
            />
          )}

          <Table size={dense ? "small" : "medium"}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData ? tableData.length : 0}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
            />

            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <AttachmentTableRow
                    key={row.id}
                    row={row}
                    selected={selected.includes(row.id)}
                    onDownloadRow={() => handleDownloadRow(row.id)}
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(
                  page,
                  rowsPerPage,
                  tableData ? tableData.length : 0
                )}
              />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData ? tableData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
