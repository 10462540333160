import { styled } from "@mui/material/styles";

// styling for the LHS box
const LHSInfoBoxStyle = styled("div")(({ theme, ownerState }) => {
  return {
    width: "auto",
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 2000,
    backgroundColor: "lightgrey",
  };
});

// ----------------------------------------------------------------------

export default function Debug() {
  return (
    <>
      <LHSInfoBoxStyle>
        <pre>Backend URL: {process.env.REACT_APP_STATE_BACKEND_API_URL}</pre>
        <pre>Env: {process.env.REACT_APP_VERCEL_ENV}</pre>
      </LHSInfoBoxStyle>
    </>
  );
}
