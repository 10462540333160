import React, { useEffect, useState } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";

import { Controller } from "react-hook-form";

import _uniqueId from "lodash/uniqueId";

export default function OurSelect(props) {
  const {
    fieldName,
    control,
    errors,
    label,
    options,
    required = true,
    defaultValue = "",
    multiple = false,
    nonErrorHelpText = "",
    autoFocus = false,
    formControlProps = {},
    selectProps = {},
  } = props;

  const [id] = useState(_uniqueId("custom-select-"));

  const getLabel = () => (required ? `${label} *` : label);

  const getValNice = (value) => {
    for (var i = 0; i < options.length; i++) {
      let el = options[i];
      if (el.value === value) {
        return el.label;
      }
    }
    // not found, return the value back at least
    return value;
  };

  const multipleProps = multiple
    ? {
        multiple: true,
        renderValue: (selected) => selected.map(getValNice).join(", "),
      }
    : {};

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: required }}
      defaultValue={multiple && !defaultValue ? [] : defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControl
          variant="standard"
          fullWidth
          margin="normal"
          {...formControlProps}
        >
          <InputLabel
            error={errors[fieldName] ? true : false}
            id={`${id}-inputlabel`}
          >
            {getLabel()}
          </InputLabel>
          <Select
            autoFocus={autoFocus}
            labelId={`${id}-inputlabel`}
            // margin="dense"
            label={getLabel()}
            type="text"
            variant="standard"
            onChange={onChange}
            required={required}
            {...multipleProps}
            {...selectProps}
            value={value}
            error={errors[fieldName] ? true : false}
          >
            {/* <MenuItem>Please select</MenuItem> */}
            {options.map((option) => {
              if (option.type && option.type === "separator") {
                return (
                  <ListSubheader key={option.value}>
                    {option.label}
                  </ListSubheader>
                );
              } else {
                return (
                  <MenuItem value={option.value} key={option.value}>
                    {multiple && (
                      <>
                        <Checkbox checked={value.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </>
                    )}
                    {!multiple && <>{option.label}</>}
                  </MenuItem>
                );
              }
            })}
          </Select>
          <FormHelperText error={errors[fieldName] ? true : false}>
            {errors[fieldName] ? "This field is required" : nonErrorHelpText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
