// List of currently supported unit types
// `allMeasures` includes all the measures packaged with this library
import configureMeasurements, { allMeasures } from "convert-units";
import { genLookupObjAndFun } from "./data/generic";

// custom measures
import massPerVolume from "./measures/massPerVolume";

const convert = configureMeasurements({ ...allMeasures, massPerVolume });
//
export const unitTypes = [
  { name: "no-unit", description: "No type (unit-less)" },
  { name: "weight", description: "Weight" },
  { name: "length", description: "Length" },
  { name: "massPerVolume", description: "Mass per volume" },
];

export function getAvailableUnits(unitType) {
  if (unitType === "no-unit") {
    return ["UNIT"];
  } else if (unitType === "weight") {
    return convert().possibilities("mass");
  } else if (unitType === "length") {
    return convert().possibilities("length");
  } else if (unitType === "massPerVolume") {
    return convert().possibilities("massPerVolume");
  } else {
    throw new Error(`Invalid unit type ${unitType}`);
  }
}

// const unitByName = unitTypes.reduce(function (acc, curr) {
//   acc[curr.name] = curr;
//   return acc;
// }, {});

// export function getUnitTypeByName(name) {
//   /* Lookup unit type by its name */
//   if (unitByName.hasOwnProperty(name)) {
//     return unitByName[name];
//   } else {
//     throw new Error(`${name} is not a valid measurement type name.`);
//   }
// }

export const [unitByName, getUnitTypeByName] = genLookupObjAndFun(unitTypes);

export function convertFromToUnit(unitFrom, unitTo, value) {
  return convert(value).from(unitFrom).to(unitTo);
}
