import React from "react";
import LocationWithAutocompleteField from "../../../forms/LocationWithAutocompleteField";
import OurSelect from "../../../forms/OurSelect";
import OurTextField from "../../../forms/OurTextField";

import { eventRecreationActivity } from "../../../../utils/data/events";

export default function EventRecreationExerciseForm(props) {
  const { control, errors, event, setValue } = props;

  return (
    <>
      <LocationWithAutocompleteField
        fieldName="locationInformation"
        control={control}
        errors={errors}
        label="Location"
        required={true}
        defaultValue={event ? event.locationId : ""}
        setValue={setValue}
      />

      <OurSelect
        fieldName="activity"
        control={control}
        errors={errors}
        label="Type"
        required={true}
        options={eventRecreationActivity.map((activity) => ({
          value: activity.name,
          label: activity.description,
        }))}
        defaultValue={event ? event.activity : ""}
      />
    </>
  );
}
