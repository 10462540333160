import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import userService from "../../../services/auth/user.service";
import authService from "../../../services/auth/auth.service";
import { storeCSRFToken } from "../../../features/loginStateSlice";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const csrfToken = useSelector((state) => state.loginState.csrftoken);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      userService
        .create({ ...values, csrftoken: csrfToken })
        .then((data) => {
          console.log("Registration success", data);
          // user created, go to login

          // update CSRF token
          authService.fetchToken().then(({ data }) => {
            dispatch(storeCSRFToken(data));
          });

          enqueueSnackbar("User created - you can now log in.", {
            variant: "success",
          });

          navigate("/login", { replace: true });
        })
        .catch((error) => {
          setSubmitting(false);
          console.log("User registration ", error);
          const status = error.response ? error.response.status : null;

          if (status === 422) {
            console.log(error.response.data.errors);
            //setStatus(error.response.data.errors);
            setErrors(error.response.data.errors);
          }
          // Update auth token if csrf token is invalid
          else if (status === 403) {
            authService.fetchToken().then(({ data }) => {
              dispatch(storeCSRFToken(data));
            });
          } else {
            //alert(error.response);
          }
        });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setSubmitting,
    setErrors,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("first_name")}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps("last_name")}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password confirmation"
            {...getFieldProps("password_confirmation")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              touched.password_confirmation && errors.password_confirmation
            )}
            helperText={
              touched.password_confirmation && errors.password_confirmation
            }
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
