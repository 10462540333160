import { useState, useEffect, createContext } from "react";
import SearchIndexContext from "../contexts/SearchIndexContext";
import si from "search-index";
import { useSelector } from "react-redux";

export default function SearchIndexProvider({ children }) {
  // init?
  const [eventsSearchIndex, setEventsSearchIndex] = useState(null);
  const editLock = useSelector((state) => state.personalDatabase.editLock);
  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );

  useEffect(() => {
    // initialise the SI
    setEventsSearchIndex({ hello: "world" });

    // testing only
    // window.si = si;

    const stopWords = [];
    const x = si({
      name: `state-events-${editLock}`,
      stopWords,
    }).then((newEventSearchIndex) => {
      console.log("SI init", newEventSearchIndex);
      setEventsSearchIndex(newEventSearchIndex);

      //window.DEBUGPersonalDatabase = personalDatabaseContents;
      // TODO remove this
      //window.eventsSearchIndex = newEventSearchIndex;

      //events =  DEBUGPersonalDatabase.events
      const startTime = Date.now();
      newEventSearchIndex
        .FLUSH()
        .then(() => {
          newEventSearchIndex.PUT(
            personalDatabaseContents.events
              .filter((event) => event.categoryId !== 0)
              .map((event) => ({
                //id: event.id,
                _id: event.id,
                name: event.name,
                description: event.description,
              })),
            {
              skipField: [""],
              storeRawDocs: false,
            }
          );
        })
        .then((res) => {
          // search index imported
          console.log(res);
          console.log("elapsed", (Date.now() - startTime) / 1000.0);
        });

      /*
          events =  DEBUGPersonalDatabase.events      
          startTime = Date.now()
          eventSearchIndex.FLUSH().then(() => {
            eventSearchIndex.PUT(        
              events.filter(
                (event) => event.categoryId !==0
              ).map(
                (event) => ({
                  //id: event.id,
                  _id: event.id,
                  name: event.name,
                  description: event.description,
                })
              ),
              {
                skipField: [''],
                storeRawDocs: false,
              }
            ) 
          }).then( (res) => {
            // search index imported
            console.log(res)
            console.log("elapsed", (Date.now() - startTime)/1000.0)
            
          })
    
          eventSearchIndex.SEARCH(['bruised'])
    
          eventSearchIndex.SEARCH(['bruased'])
    
           */
    });

    return () => {
      // Flush the index here
      // perhaps this is enough to avoid the page reload,
      // as this would remove all of the DB contents?
      if (eventsSearchIndex) {
        console.log("Flushing event database...");
        eventsSearchIndex.FLUSH();
      }
      window.eventsSearchIndex = null;
    };
  }, []);

  return (
    <SearchIndexContext.Provider
      value={{ eventsSearchIndex: eventsSearchIndex }}
    >
      {children}
    </SearchIndexContext.Provider>
  );
}
