import Moment from "moment";
import { styled } from "@mui/material/styles";

const MonthMarkerStyled = styled("div")(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.grey[800],
  fontSize: "24px",
  fontWeight: 700,
  ".timeline__year": {
    fontSize: "32px",
  },
}));

export default function TimelineDividerMonth(props) {
  const { event } = props;
  const eventDatetime = Moment(event.datetime);

  return (
    <MonthMarkerStyled>
      {eventDatetime.month() === 0 && (
        <div className="timeline__year">{eventDatetime.year()}</div>
      )}

      {eventDatetime.month() !== 0 && (
        <>
          {eventDatetime.format("MMMM")} {eventDatetime.year()}
        </>
      )}
    </MonthMarkerStyled>
  );
}
