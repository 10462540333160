import { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

import { getAvatarSVGSrc } from "../utils/images";
import assetService from "../services/auth/asset.service";

export default function RecordAvatar(props) {
  const {
    variant = "rounded",
    sx = { backgroundColor: "rgba(145, 158, 171, 0.12)" },
    record = null,
  } = props;
  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSource, setImageSource] = useState("");

  const recordOfInterest = record ? record : currentRecord;

  const loadImage = () => {
    assetService
      .get(recordOfInterest.id, recordOfInterest.custom_photo)
      .then((response) => {
        //setLoaderOpen(false);
        console.log("File downloaded");
        console.log(response);
        setImageSource(response.data.url);
        setImageLoaded(true);
      })
      .catch((response) => {
        //setLoaderOpen(false);
        console.log(response);
      });
  };

  useEffect(() => {
    if (recordOfInterest.custom_photo) {
      loadImage();

      const updateImage = setInterval(loadImage, 3 * 60 * 1000); // refresh every 3m

      return () => {
        clearInterval(updateImage);
      };
    }
  }, [currentRecord, record]);

  return (
    <>
      {imageLoaded && recordOfInterest.custom_photo && (
        <Avatar
          src={imageSource}
          alt={`${recordOfInterest.name} avatar`}
          sx={sx}
          variant={variant}
        />
      )}
      {(!imageLoaded || !recordOfInterest.custom_photo) && (
        <Avatar
          src={getAvatarSVGSrc(recordOfInterest.id)}
          alt={`${recordOfInterest.name} avatar`}
          sx={sx}
          variant={variant}
        />
      )}
    </>
  );
}
