import React from "react";
import OurSelect from "../../../forms/OurSelect";
import OurTextField from "../../../forms/OurTextField";
import { useSelector } from "react-redux";

import {
  eventHospitalDepartment,
  eventHospitalPurpose,
} from "../../../../utils/data/events";

export default function EventAppointmentHospitalOutpatientForm(props) {
  const { control, errors, event } = props;

  const entities = useSelector((state) =>
    state.personalDatabase.contents.entities.filter(
      (entity) => entity.type === "hospital"
    )
  );

  return (
    <>
      <OurSelect
        fieldName="entityId"
        control={control}
        errors={errors}
        label="Hospital"
        required={true}
        options={entities.map((entity) => ({
          value: entity.id,
          label: entity.name,
        }))}
        defaultValue={event ? event.entityId : ""}
      />
      <OurSelect
        fieldName="purposes"
        control={control}
        errors={errors}
        label="Purpose"
        required={true}
        multiple={true}
        options={eventHospitalPurpose.map((purpose) => ({
          value: purpose.name,
          label: purpose.description,
        }))}
        defaultValue={event ? event.purposes : []}
      />
      <OurSelect
        fieldName="departments"
        control={control}
        errors={errors}
        label="Department"
        required={true}
        multiple={true}
        options={eventHospitalDepartment.map((department) => ({
          value: department.name,
          label: department.description,
        }))}
        defaultValue={event ? event.departments : []}
      />
    </>
  );
}
