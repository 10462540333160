import React, { useState } from "react";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useForm } from "react-hook-form";

// import { unitTypes } from "../../utils/units";

import _uniqueId from "lodash/uniqueId";

import { useDispatch } from "react-redux";
import { recordMeasurement } from "../../features/personalDatabaseSlice";

import { getAvailableUnits } from "../../utils/units";
import OurTextField from "../forms/OurTextField";
import OurDateTimeField from "../forms/OurDateTimeField";
import OurSelect from "../forms/OurSelect";
import { useSnackbar } from "notistack";

export default function AddMeasurementFormDialogue(props) {
  const { currentMeasurementCategory } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const {
    //setValue,
    clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      measurementDate: "",
      measurementValue: "",
      measurementUnit: "",
      measurementNote: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [id] = useState(_uniqueId("addmeasurementdialogue-"));

  const handleClickOpen = () => {
    clearErrors();
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    clearErrors();
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);

    console.log(data.measurementDate.toISOString());

    dispatch(
      recordMeasurement({
        categoryId: currentMeasurementCategory.id,
        item: {
          date: data.measurementDate.toISOString(),
          value: data.measurementValue,
          unit: data.measurementUnit,
          note: data.measurementNote,
        },
      })
    );
    enqueueSnackbar("Measurement recorded.");

    handleClose();
  };

  return (
    <>
      <Button
        sx={{ my: 3 }}
        variant="contained"
        component="div"
        onClick={handleClickOpen}
      >
        Add Measurement
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Measurement</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OurDateTimeField
              fieldName="measurementDate"
              control={control}
              errors={errors}
              label="Measurement Date"
            />

            <OurTextField
              fieldName="measurementValue"
              control={control}
              errors={errors}
              label="Value"
            />

            <OurSelect
              fieldName="measurementUnit"
              control={control}
              errors={errors}
              label="Measurement Unit"
              options={getAvailableUnits(currentMeasurementCategory.type).map(
                (key) => ({ label: key, value: key })
              )}
            />

            <OurTextField
              fieldName="measurementNote"
              control={control}
              errors={errors}
              label="Note"
              required={false}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)}>Add Measurement</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
