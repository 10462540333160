import Chart from "react-apexcharts";

import FullWidthTabs from "./TimelineItemTabs";
import TimelineCard from "../VerticalTimelineItemCard.js";

import PedalBikeIcon from "@mui/icons-material/PedalBike";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

import { styled } from "@mui/material/styles";

const RecreationTimelineItemStyled = styled("div")(({ theme }) => ({
  "& .recreation__container": {
    marginTop: "30px",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  "& .recreation__item": {
    padding: "24px 22px",
    color: theme.palette.grey[800],
    width: "48%",
    border: "solid 1px rgba(145, 158, 171, 0.24)",
    borderRadius: "16px",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    "&__title": {
      fontSize: "14px",
      fontWeight: 600,
      marginBottom: "17px",
    },
    "&__val": {
      fontSize: "32px",
      fontWeight: 700,
      marginBottom: "4px",
    },
    "&__footer": {
      color: theme.palette.grey[600],
      fontSize: "14px",
      "&__icon": {
        content: '""',
        display: "inline-flex",
        position: "relative",
        top: "4px",
        marginRight: "8px",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        alignItems: "center",
        justifyContent: "center",
        "&--up": {
          backgroundColor: "rgba(84, 214, 44, 0.16)",
          ".MuiSvgIcon-root": {
            color: theme.palette.success.main,
            height: "70%",
          },
        },
        "&--down": {
          backgroundColor: "rgba(255, 72, 66, 0.16)",
          ".MuiSvgIcon-root": {
            color: theme.palette.error.main,
            height: "70%",
          },
        },
      },
      "&__val": {
        color: theme.palette.grey[800],
        fontWeight: 600,
      },
    },
    "&__graph": {
      width: "200px",
    },
  },
}));

export default function RecreationTimelineItem(props) {
  const { event, scrollToIndex, showAddOrEditEventDialogue } = props;
  const chartOptions1 = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      grid: { show: false },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: { labels: { show: false } },
      colors: ["#00ab55"],
    },
    series: [
      {
        name: "series-1",
        data: [80, 20, 70, 0, 90],
        dataLabels: {
          show: false,
        },
      },
    ],
  };
  const chartOptions2 = {
    ...chartOptions1,
    options: { ...chartOptions1.options, colors: ["#2cd9c5"] },
  };

  return (
    <TimelineCard
      cardIcon={<PedalBikeIcon sx={{ fontSize: "27px" }} />}
      cardTitle="Recreation"
      cardSubheader="Exercise (Cycling)"
      event={event}
      scrollToIndex={scrollToIndex}
      showAddOrEditEventDialogue={showAddOrEditEventDialogue}
    >
      <RecreationTimelineItemStyled>
        <div className="recreation__container">
          <div className="recreation__item recreation__item--left">
            <span>
              <div className="recreation__item__title">Active KCal</div>
              <div className="recreation__item__val">765</div>
              <div className="recreation__item__footer">
                <span className="recreation__item__footer__icon recreation__item__footer__icon--up">
                  <TrendingUpIcon />
                </span>
                <span className="recreation__item__footer__val">2.6%</span>
                <span> than last session</span>
              </div>
            </span>
            <span className="recreation__item__graph">
              <Chart
                options={chartOptions1.options}
                series={chartOptions1.series}
                type="line"
                width="100%"
              />
            </span>
          </div>

          <div className="recreation__item recreation__item--right">
            <span>
              <div className="recreation__item__title">Total distance</div>
              <div className="recreation__item__val">23.4km</div>
              <div className="recreation__item__footer">
                <span className="recreation__item__footer__icon recreation__item__footer__icon--down">
                  <TrendingDownIcon />
                </span>
                <span className="recreation__item__footer__val">-0.06%</span>
                <span> than last session</span>
              </div>
            </span>
            <span className="recreation__item__graph">
              <Chart
                options={chartOptions2.options}
                series={chartOptions2.series}
                type="line"
                width="100%"
              />
            </span>
          </div>
        </div>
      </RecreationTimelineItemStyled>

      <FullWidthTabs event={event} scrollToIndex={scrollToIndex} />
    </TimelineCard>
  );
}
