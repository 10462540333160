import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { Box, Grid, Card, Button, Avatar, Typography } from "@mui/material";
// components
import Iconify from "../Iconify";
import { createSvgIcon } from "@mui/material/utils";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import { findCurrentEventIndexById } from "../../utils/data/events";
import { getEventData } from "../../utils/data/selectors";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

LinksTab.propTypes = {
  linkedEvents: PropTypes.array,
};

export default function LinksTab({ linkedEvents, scrollToIndex }) {
  return (
    <Grid container spacing={3}>
      {linkedEvents.map((event) => (
        <Grid key={event.id} item xs={12} md={6}>
          <LinkCard event={event} scrollToIndex={scrollToIndex} />
        </Grid>
      ))}
    </Grid>
  );
}

// ----------------------------------------------------------------------

const InjuryIcon = createSvgIcon(
  <path d="M8 6C8 3.79 9.79 2 12 2S16 3.79 16 6 14.21 10 12 10 8 8.21 8 6M17 22H18C19.1 22 20 21.1 20 20V15.22C20 14.1 19.39 13.07 18.39 12.56C17.96 12.34 17.5 12.13 17 11.94V22M12.34 17L15 11.33C14.07 11.12 13.07 11 12 11C9.47 11 7.29 11.7 5.61 12.56C4.61 13.07 4 14.1 4 15.22V22H6.34C6.12 21.55 6 21.04 6 20.5C6 18.57 7.57 17 9.5 17H12.34M10 22L11.41 19H9.5C8.67 19 8 19.67 8 20.5S8.67 22 9.5 22H10Z" />,
  "Injury"
);

const HospitalIcon = createSvgIcon(
  <path fill="currentColor" d="M18 14H14V18H10V14H6V10H10V6H14V10H18" />,
  "Appointment"
);

LinkCard.propTypes = {
  event: PropTypes.object,
};

function LinkCard({ event, scrollToIndex }) {
  const { name, datetime, location, eventType, isLinked } = event;

  const [toggle, setToogle] = useState(isLinked);
  const eventData = useSelector(getEventData);

  const scrollToEvent = () => {
    const compEvent = { ...event };
    // event has datetime translated to Moment as it's passed here,
    // it's not a "raw" event object
    compEvent.datetime = compEvent.datetime.toISOString();
    console.log(eventData, compEvent);
    const eventIndex = findCurrentEventIndexById(eventData, compEvent);
    if (eventIndex > 0) {
      console.log("Scrolling to index", eventIndex);
      scrollToIndex(eventIndex);
    } else {
      alert(
        "This event is not visible in this filter view. \nPlease remove filters to jump to this event."
      );
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        p: 3,
        boxShadow:
          "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
      }}
    >
      {/* <Avatar alt={name} src={avatarUrl} sx={{ width: 48, height: 48 }} /> */}
      <Box
        sx={{
          width: "48px",
          height: "48px",
          // marginRight: "18px",
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          backgroundColor: "#00AB55",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}
      >
        {eventType === "EventMilestoneInjury" && (
          <InjuryIcon sx={{ fontSize: "29px", color: "white" }} />
        )}
        {eventType === "EventRecreationExercise" && (
          <PedalBikeIcon sx={{ fontSize: "27px", color: "white" }} />
        )}
        {eventType === "EventAppointmentHospitalOutpatient" && (
          <HospitalIcon sx={{ fontSize: "37px", color: "white" }} />
        )}
      </Box>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ cursor: "pointer" }}
          onClick={scrollToEvent}
        >
          {name}
          {/* ({datetime.format("DD/MM/YYYY")}) */}
        </Typography>
        {location && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Iconify
              icon={"eva:pin-fill"}
              sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }}
            />
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {location}
            </Typography>
          </Box>
        )}
      </Box>
      <Button
        size="small"
        onClick={() => setToogle(!toggle)}
        sx={{ pointerEvents: "none" }}
        variant={toggle ? "text" : "outlined"}
        color={toggle ? "primary" : "inherit"}
        startIcon={toggle && <Iconify icon={"eva:checkmark-fill"} />}
      >
        {toggle ? "Linked" : "Link"}
      </Button>
    </Card>
  );
}
