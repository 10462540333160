import HTTP from "../http";
import { get as _get } from "lodash";

/**
 * Create a user in the system via the API.
 * @param recordsId
 * @param assetId
 */

function download(recordsId, assetId) {
  const url = `/api/v1/assets/${recordsId}/${assetId}/`;
  //console.log(url);

  return new Promise((resolve, reject) => {
    HTTP.get(url)
      .then((assetData) => {
        //console.log("asset data", assetData.data);
        // return promise with the asset download
        resolve(HTTP.get(assetData.data.url));
      })
      .catch((response) => {
        if (response.status !== 200) {
          reject(response);
        }
        reject(response);
      });
  });
}

function get(recordsId, assetId) {
  const url = `/api/v1/assets/${recordsId}/${assetId}/`;
  //console.log(url);
  return HTTP.get(url);
}

function update(recordsId, assetId, data) {
  const url = `/api/v1/assets/${recordsId}/${assetId}/`;
  //console.log(url);
  const fd = new FormData();
  for (var key in data) {
    if (key == "asset") {
      //console.log(data[key]);
      fd.append(key, new File([data[key]], "asset"), "asset");
    } else {
      fd.append(key, data[key]);
    }
  }

  return HTTP.post(url, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function create(recordsId, data) {
  const url = `/api/v1/assets/${recordsId}/`;
  const fd = new FormData();
  for (var key in data) {
    if (key == "asset") {
      //console.log(data[key]);
      fd.append(key, new File([data[key]], "asset"), "asset");
    } else {
      fd.append(key, data[key]);
    }
  }

  return HTTP.post(url, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * Destroy the asset with the provided id via the API.
 * @param {string|number} id
 */
function destroy(recordsId, assetId) {
  const url = `/api/v1/assets/${recordsId}/${assetId}/`;
  return HTTP.delete(url);
}

export default {
  download,
  get,
  update,
  create,
  destroy,
};
