export const genHelpText = (error) => {
  if (!error) {
    return null;
  }
  if (error.type === "required") {
    return "This field is required";
  } else if (error.type === "date") {
    return "The date is invalid";
  } else if (error.type === "maxLength") {
    return "The input is too long";
  } else if (error.type === "dateRange") {
    return "Date is out of range";
  } else {
    return error.message;
  }
};
