import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reloading: false,
  timeline: {
    filters: {
      tag: [],
      category: [],
      expanded: false,
      search_string: "",
      search_ids: [],
    },
  },
};

export const uiStateSlice = createSlice({
  name: "uiState",
  initialState,
  reducers: {
    setTagFilter: (state, action) => {
      state.timeline.filters.tag = action.payload;
    },

    setCategoryFilter: (state, action) => {
      state.timeline.filters.category = action.payload;
    },
    setFilterExpanded: (state, action) => {
      state.timeline.filters.expanded = action.payload;
    },

    setSearchParams: (state, action) => {
      state.timeline.filters.search_string = action.payload.search_string;
      state.timeline.filters.search_ids = action.payload.search_ids;
    },

    setReloading: (state, action) => {
      state.reloading = true;
    },
  },
});

export const {
  setTagFilter,
  setCategoryFilter,
  setFilterExpanded,
  setSearchParams,
  setReloading,
} = uiStateSlice.actions;

export default uiStateSlice.reducer;
