import React, { useContext, useState } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";

// import _uniqueId from "lodash/uniqueId";
// import * as _ from "lodash";
import { useForm } from "react-hook-form";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import OurDateTimeField from "../../forms/OurDateTimeField";
import OurSelect from "../../forms/OurSelect";
import OurTextField from "../../forms/OurTextField";
import OurFileUploadButton from "../../forms/OurFileUploadButton";

import EventMilestoneInjuryForm from "./includes/EventMilestoneInjuryForm";
import EventAppointmentHospitalOutpatientForm from "./includes/EventAppointmentHospitalOutpatientForm";
import EventRecreationExerciseForm from "./includes/EventRecreationExerciseForm";

// for getInitDate
import { findCurrentEventIndex } from "../../../utils/data/events";
import Moment from "moment";
import { validators } from "../../../schemas/schemas";
import { generateEventId } from "../../../utils/data/events";
import assetService from "../../../services/auth/asset.service";
import SearchIndexContext from "../../../contexts/SearchIndexContext";

import {
  encryptToBase64,
  encryptBinaryStringToBase64,
  genRandomPassword,
} from "../../../utils/data/crypto";

import {
  addEvent,
  editEvent,
  addFile,
} from "../../../features/personalDatabaseSlice";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";

// attachment card
import _uniqueId from "lodash/uniqueId";
import { Box, Card, Typography } from "@mui/material";
import Iconify from "../../Iconify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { fileTypeFromBuffer } from "file-type";

import { downloadAsset } from "../../../utils/data/attachments";
import Jimp from "jimp/es";

export default function Step2AddEventForm(props) {
  const { handleClose, eventType, eventData, insertAfterEvent, event } = props;

  const dispatch = useDispatch();
  const store = useStore();
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { eventsSearchIndex } = useContext(SearchIndexContext);

  const editModeActive = event !== null;

  const categories = useSelector((state) =>
    state.personalDatabase.contents.categories.filter(
      (category) => category.id !== 0
    )
  );
  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );
  const tags = useSelector((state) =>
    state.personalDatabase.contents.tags
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
  );

  const populateInitialAttachments = (event) => {
    if (!event) {
      return [];
    }

    // populate attachment stuff
    const state = store.getState();

    return event.attachments.map((attachmentId) => {
      const attachmentInfo =
        state.personalDatabase.contents.fileStorage[attachmentId];

      return {
        id: attachmentId,
        fileName: attachmentInfo.fileName,
        perFilePassword: attachmentInfo.perFilePassword,
        fileType: attachmentInfo.fileType,
        isThumbnail: attachmentInfo.isThumbnail,
        thumbnailId: attachmentInfo.thumbnailId,
        mime: attachmentInfo.mime,
      };
    });
  };

  const [attachments, setAttachments] = useState(
    populateInitialAttachments(event)
  );

  const {
    //setValue,
    //clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm(); //{ resolver: ajvResolver(testSchema) });

  const onSubmit = (data) => {
    console.log("FORM SUBMIT");
    console.log(data);

    const eventData = {
      ...data,
      datetime: Moment(data.datetime).toISOString(),
    };

    if (eventData.datetimeEnd) {
      eventData["datetimeEnd"] = Moment(data.datetimeEnd).toISOString();
    } else {
      eventData["datetimeEnd"] = "";
    }

    // Generic processing
    eventData.id = generateEventId(eventData);
    eventData.displayTimezone = "";
    eventData.attachments = [];
    if (editModeActive) {
      eventData.linkGroup = event.linkGroup;
    } else {
      eventData.linkGroup = 0;
    }
    if (
      eventType === "EventMilestoneInjury" ||
      eventType === "EventRecreationExercise"
    ) {
      delete eventData["locationInformation"];
      eventData.locationId = {
        g_place_id: data.locationInformation.place_id,
        cached_label: data.locationInformation.description,
        typed_info: data.locationInformation,
      };
    }

    if (eventType === "EventAppointmentHospitalOutpatient") {
      // hospital/outpatient additional processing
    }

    // validate using our custom validator
    console.log("Event data:", eventData);
    const validator = validators[eventType];
    const valid = validator({ ...eventData, eventType: eventType });
    console.log(valid, validator.errors);

    if (!valid) {
      alert("The form did not pass validation: " + validator.errors);
      return;
    }

    // process and attach attachments
    eventData.attachments = attachments.map((attachment) => {
      // store the file
      dispatch(addFile(attachment));
      return attachment.id;
    });

    if (editModeActive) {
      // edit existing event
      eventData.eventType = event.eventType;
      const previousId = event.id;
      dispatch(
        editEvent({
          item: eventData,
          previousId: event.id,
        })
      );

      // update index - delete prev entry, no longer relevant
      eventsSearchIndex.DELETE([previousId]).then((res) => {
        // search index updated
        console.log("Previous item deleted", res);
      });
      // update index - add the new updated entry
      eventsSearchIndex
        .PUT(
          [eventData].map((event) => ({
            //id: event.id,
            _id: event.id,
            name: event.name,
            description: event.description,
          }))
        )
        .then((res) => {
          // search index updated
          console.log("Search index updated", res);
        });

      enqueueSnackbar("Event has been edited.");
    } else {
      // create new event
      dispatch(
        addEvent({
          eventType: eventType,
          item: eventData,
        })
      );

      // update index
      eventsSearchIndex
        .PUT(
          [eventData].map((event) => ({
            //id: event.id,
            _id: event.id,
            name: event.name,
            description: event.description,
          }))
        )
        .then((res) => {
          // search index updated
          console.log("Search index updated", res);
        });

      enqueueSnackbar("Event has been added.");
    }

    setAttachments([]);
    handleClose();
  };

  const getInitDate = (insertAfterEvent, event) => {
    if (event) {
      // editing, get existing event datetime
      return Moment(event.datetime);
    }

    const dateFirstEvent = Moment(insertAfterEvent.datetime);
    const currentEventIndex = findCurrentEventIndex(
      eventData,
      insertAfterEvent
    );
    console.log("NEXT event inedex: ", currentEventIndex + 1);

    if (currentEventIndex == eventData.length - 1) {
      // last event - we just add 12h and return
      return dateFirstEvent.add(12, "h");
    }

    // get next event
    const nextEvent = eventData[currentEventIndex + 1];

    const dateNextEvent = Moment(nextEvent.datetime);

    const halfDuration = Moment.duration(
      dateNextEvent.diff(dateFirstEvent) / 2.0
    );

    console.log("Cur event", dateFirstEvent.toISOString());
    console.log("Next event", dateNextEvent.toISOString());
    console.log("Duration:", halfDuration.toISOString(), halfDuration);
    console.log("Duration (days):", halfDuration.asDays());

    const dateInsert = dateFirstEvent.add(halfDuration.asDays(), "d");
    console.log(dateInsert.toISOString());
    // if (dateFirstEvent.hours() > 12) {
    //   // bump the day up
    //   dateFirstEvent.add(1, "d");
    // }

    // dateFirstEvent.hours(12);
    // dateFirstEvent.minutes(0);

    return dateInsert.toISOString();
  };

  const eventTypesNice = {
    EventMilestoneInjury: "Milestone - Injury",
    EventAppointmentHospitalOutpatient: "Appointment - Hospital (Outpatient)",
    EventRecreationExercise: "Recreation - Exercise",
  };

  const fileUploaded = async (fileName, fileContentsBuffer) => {
    setOpen(true);

    try {
      const detectedFileType = await fileTypeFromBuffer(fileContentsBuffer);
      console.log("Detected file type", detectedFileType);

      console.log("File upload handler");
      console.log(fileName);
      //console.log(fileContentsBuffer);
      const arrayToString = (arr) =>
        arr.reduce((str, code) => str + String.fromCharCode(code), "");

      const original = new Uint8Array(fileContentsBuffer);
      const originalString = arrayToString(original);
      //console.log(originalString);

      // TODO move this to utils
      const ALLOWED_IMAGE_MIME = [
        "image/jpeg",
        "image/png",
        "image/tiff",
        "image/gif",
      ];

      const isMedia =
        detectedFileType && ALLOWED_IMAGE_MIME.includes(detectedFileType.mime);

      const perFilePassword = genRandomPassword(32); // 32 char random password

      let thumbnailId = null;

      // now encrypt and push to the asset storage
      assetService
        .create(currentRecord.id, {
          asset: encryptBinaryStringToBase64(originalString, perFilePassword),
        })
        .then(async ({ data }) => {
          setOpen(false);
          const THUMBMIME = "image/jpeg";

          if (isMedia) {
            try {
              console.log("Handling thumbnail");
              // upload the thumbnail
              const image = await Jimp.read(fileContentsBuffer);
              const thumbnailImageBuffer = await image
                .cover(520, 520)
                .getBufferAsync(THUMBMIME);

              const thumbnailAssetResponse = await assetService.create(
                currentRecord.id,
                {
                  asset: encryptBinaryStringToBase64(
                    arrayToString(thumbnailImageBuffer),
                    perFilePassword
                  ),
                }
              );

              console.log("Thumbnail response", thumbnailAssetResponse);

              thumbnailId = thumbnailAssetResponse.data.id;
            } catch (error) {
              console.log("ERROR: Unable to process thumbnail", error);
            }
          }

          setAttachments((oldArray) => {
            const ret = [
              ...oldArray,
              {
                id: data.id,
                fileName: fileName,
                perFilePassword: perFilePassword,
                fileType: isMedia ? "media" : "attachment",
                thumbnailId: thumbnailId,
                isThumbnail: false,
                mime: detectedFileType ? detectedFileType.mime : null,
              },
            ];

            if (thumbnailId) {
              ret.push({
                id: thumbnailId,
                fileName: "thumbnail_" + fileName + ".jpg",
                perFilePassword: perFilePassword,
                fileType: isMedia ? "media" : "attachment",
                thumbnailId: null,
                isThumbnail: true,
                mime: THUMBMIME,
              });
            }

            return ret;
          });
        })
        .catch((error) => {
          setOpen(false);

          console.log(error);
          enqueueSnackbar("Unable to upload a file, please try again later.", {
            variant: "error",
          });
        });
    } catch (e) {
      setOpen(false);

      console.log(e);
      enqueueSnackbar("Unable to detect file type, please try again later.", {
        variant: "error",
      });
    }
    // storeFile(fileName, base64String);
  };

  const deleteAttachment = (attachment) => {
    console.log("Deleting", attachment);

    if (event) {
      // for existing events, ONLY remove from the event
      // cleanup will be done asynchronously
      setAttachments((oldArray) =>
        oldArray.filter((attch) => attch.id !== attachment.id)
      );
    } else {
      assetService
        .destroy(currentRecord.id, attachment.id)
        .then(({ data }) => {
          setAttachments((oldArray) =>
            oldArray.filter((attch) => attch.id !== attachment.id)
          );
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Unable to delete attachment", {
            variant: "error",
          });
        });
    }
  };

  // const storeFile = (fileName, fileContents) => {
  //   const id = uuidv4();
  //   dispatch(
  //     addFile({
  //       id: id,
  //       fileName: fileName,
  //       fileContents: fileContents,
  //       fileType: "generic",
  //     })
  //   );
  //   return id;
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>
        {editModeActive && <>Edit </>}
        {!editModeActive && <>Add </>}
        {eventTypesNice[eventType]}
      </DialogTitle>

      <DialogContent>
        <OurDateTimeField
          fieldName="datetime"
          control={control}
          errors={errors}
          label="Date"
          required={true}
          time={true}
          defaultValue={getInitDate(insertAfterEvent, event)}
        />
        <OurDateTimeField
          fieldName="datetimeEnd"
          control={control}
          errors={errors}
          label="End date"
          required={false}
          time={true}
          defaultValue={
            event && event.datetimeEnd ? Moment(event.datetimeEnd) : ""
          }
        />
        {eventType === "EventMilestoneInjury" && (
          <EventMilestoneInjuryForm
            control={control}
            errors={errors}
            event={event}
            setValue={setValue}
          />
        )}
        {eventType === "EventAppointmentHospitalOutpatient" && (
          <EventAppointmentHospitalOutpatientForm
            control={control}
            errors={errors}
            event={event}
            setValue={setValue}
          />
        )}
        {eventType === "EventRecreationExercise" && (
          <EventRecreationExerciseForm
            control={control}
            errors={errors}
            event={event}
            setValue={setValue}
          />
        )}

        <OurTextField
          fieldName="name"
          control={control}
          errors={errors}
          label="Short description"
          required={true}
          maxLength={50}
          defaultValue={event ? event.name : ""}
        />
        <OurTextField
          fieldName="description"
          control={control}
          errors={errors}
          label="Details"
          required={false}
          multiline={true}
          defaultValue={event ? event.description : ""}
        />
        <OurSelect
          fieldName="categoryId"
          control={control}
          errors={errors}
          label="Category"
          required={true}
          options={categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))}
          defaultValue={event ? event.categoryId : ""}
        />

        <OurSelect
          fieldName="tags"
          control={control}
          errors={errors}
          label="Tags"
          required={false}
          multiple={true}
          options={tags.map((category) => ({
            label: category.name,
            value: category.id,
          }))}
          defaultValue={event ? event.tags : []}
        />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box sx={{ mt: 2 }}>
          {attachments
            .filter((attachment) => attachment.isThumbnail === false)
            .map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={attachment}
                currentRecord={currentRecord}
                deleteAttachment={deleteAttachment}
              ></AttachmentCard>
            ))}
        </Box>

        {/* attachments handling */}
        <OurFileUploadButton
          label="Attach file"
          handleUploadedFile={fileUploaded}
          setLoaderOpen={setOpen}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {editModeActive && <>Save changes </>}
          {!editModeActive && <>Create Event </>}
        </Button>
      </DialogActions>
    </form>
  );
}

function AttachmentCard({ attachment, currentRecord, deleteAttachment }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [toggle, setToogle] = useState(isFollowed);

  const [elementId] = useState(_uniqueId("attachment-card-"));

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1.5,
        mb: 2,
        boxShadow:
          "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 1, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {attachment.fileName} (type: {attachment.fileType}){" "}
          {attachment.isThumbnail}
        </Typography>
        {/* {dob && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Iconify
              icon={"eva:calendar-fill"}
              sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }}
            />
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {Moment(dob).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        )} */}
      </Box>
      <Button
        size="small"
        onClick={() => deleteAttachment(attachment)}
        variant="contained"
        color="error"
      >
        Remove
      </Button>

      <Button
        id={elementId + "-button"}
        aria-controls={open ? elementId + "-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          minWidth: 8,
        }}
      >
        <Iconify
          icon={"eva:more-vertical-fill"}
          sx={{ width: 16, height: 16, flexShrink: 0 }}
        />
      </Button>
      <Menu
        id={elementId + "-menu"}
        aria-labelledby={elementId + "-button"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            downloadAsset(currentRecord, attachment);
          }}
          // sx={{ color: "red" }}
        >
          {"Download "}
          <Iconify
            icon={"eva:download-fill"}
            sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
          />
        </MenuItem>
      </Menu>
    </Card>
  );
}
