import { createSlice } from "@reduxjs/toolkit";
import { migrateToLatest } from "../utils/migrations";

import { entityTypes } from "../utils/data/entities";
import { unitTypes } from "../utils/units";

import { getMeasurementIdentifier } from "../utils/data/measurements";
import { generateEventId } from "../utils/data/events";
import { DataValidationError } from "../utils/data/errors";

import { validators } from "../schemas/schemas";
import Moment from "moment";

const initialState = {
  name: "", // TODO - this should be fetched from currentDecords
  encryptionPassword: null,
  currentRecord: null,
  editLock: null,
  contents: null,
  dirty: false,
};

const insertSorted = function (arr, item, comparator) {
  if (comparator == null) {
    // emulate the default Array.sort() comparator
    comparator = function (a, b) {
      if (typeof a !== "string") a = String(a);
      if (typeof b !== "string") b = String(b);
      return a > b ? 1 : a < b ? -1 : 0;
    };
  }

  // get the index we need to insert the item at
  var min = 0;
  var max = arr.length;
  var index = Math.floor((min + max) / 2);
  while (max > min) {
    if (comparator(item, arr[index]) < 0) {
      max = index;
    } else {
      min = index + 1;
    }
    index = Math.floor((min + max) / 2);
  }

  // insert the item
  arr.splice(index, 0, item);
};

export const personalDatabaseSlice = createSlice({
  name: "personalDatabase",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
      state.dirty = true; // mark as dirty
    },
    setEncryptionPassword: (state, action) => {
      state.encryptionPassword = action.payload;
    },
    setCurrentRecord: (state, action) => {
      state.currentRecord = action.payload;
    },
    setEditLock: (state, action) => {
      state.editLock = action.payload;
    },
    setDirty: (state, action) => {
      state.dirty = action.payload;
    },
    setDatabaseContents: (state, action) => {
      // any migrations in order?
      const migratedContents = migrateToLatest(action.payload);

      state.contents = migratedContents;

      // populate system category if needed
      if (state.contents && state.contents.categories) {
        const all_category_ids = state.contents.categories.map(
          (category) => category.id
        );

        if (!all_category_ids.includes(0)) {
          state.contents.categories.push({
            id: 0,
            name: "System-generated events",
          });
        }
      }

      state.dirty = false; // mark as not dirty, just restored/set
    },
    addCategory: (state, action) => {
      // get all category IDs
      const all_category_ids = state.contents.categories.map(
        (category) => category.id
      );
      console.log(all_category_ids);
      if (all_category_ids.includes(action.payload.id)) {
        throw new Error("ID is already present");
      }

      // no duplicates - safe to proceed and add a category
      state.contents.categories.push({
        name: action.payload.name,
        id: action.payload.id,
      });
      state.dirty = true;
    },

    editCategory: (state, action) => {
      // Updates category by ID
      // TODO - throw an error if not found?
      const updatedCategory = action.payload;
      state.contents.categories = state.contents.categories.map((category) => {
        if (category.id === updatedCategory.id) {
          return updatedCategory;
        }
        return category;
      });
      state.dirty = true;
    },

    deleteCategory: (state, action) => {
      const deleteId = action.payload;

      if (deleteId === 0) {
        throw new DataValidationError("Cannot delete system category");
      }

      let inUse = false;
      for (let i = 0; i < state.contents.events.length; i++) {
        const event = state.contents.events[i];
        if (event.categoryId === deleteId) {
          inUse = true;
          break;
        }
      }

      if (inUse) {
        throw new DataValidationError("Category is currently in use.");
      }

      state.contents.categories = state.contents.categories.filter(
        (category) => category.id !== deleteId
      );

      state.dirty = true;
      // TODO - un-assign from all relevant content
    },

    addMeasurementCategory: (state, action) => {
      // get all category IDs
      const all_category_ids = state.contents.measurementCategories.map(
        (category) => category.id
      );
      console.log(all_category_ids);
      if (all_category_ids.includes(action.payload.id)) {
        throw new Error("ID is already present");
      }

      const validTypes = unitTypes.map((x) => x.name);

      if (!validTypes.includes(action.payload.type)) {
        throw new Error(`Invalid type category type: ${action.payload.type}`);
      }

      // no duplicates - safe to proceed and add a category
      state.contents.measurementCategories.push({
        name: action.payload.name,
        id: action.payload.id,
        type: action.payload.type,
      });
      state.dirty = true;
    },

    editMeasurementCategory: (state, action) => {
      // Updates category by ID
      // TODO - throw an error if not found?
      const updatedCategory = action.payload;
      state.contents.measurementCategories =
        state.contents.measurementCategories.map((category) => {
          if (category.id === updatedCategory.id) {
            return updatedCategory;
          }
          return category;
        });
      state.dirty = true;
    },

    deleteMeasurementCategory: (state, action) => {
      const deleteId = action.payload;
      state.contents.measurementCategories =
        state.contents.measurementCategories.filter(
          (category) => category.id !== deleteId
        );

      // delete the measurements shard
      delete state.contents.measurements[deleteId];
      state.dirty = true;
    },

    addTag: (state, action) => {
      // get all category IDs
      const all_tag_ids = state.contents.tags.map((tag) => tag.id);
      console.log(all_tag_ids);
      if (all_tag_ids.includes(action.payload.id)) {
        throw new Error("ID is already present");
      }

      // no duplicates - safe to proceed and add a category
      state.contents.tags.push({
        name: action.payload.name,
        id: action.payload.id,
      });
      state.dirty = true;
    },

    editTag: (state, action) => {
      // Updates category by ID
      // TODO - throw an error if not found?
      const updatedTag = action.payload;
      state.contents.tags = state.contents.tags.map((tag) => {
        if (tag.id === updatedTag.id) {
          return updatedTag;
        }
        return tag;
      });
      state.dirty = true;
    },

    deleteTag: (state, action) => {
      const deleteId = action.payload;
      state.contents.tags = state.contents.tags.filter(
        (tag) => tag.id !== deleteId
      );

      // Un-assign from all events
      state.contents.events.forEach((event) => {
        event.tags = event.tags.filter((tagId) => tagId !== deleteId);
      });

      state.dirty = true;
    },

    recordMeasurements: (state, action) => {
      action.payload.measurementsAndCategories.forEach((payload) => {
        //console.log(payload);
        const genAction = {
          ...action,
          payload: payload,
        };
        //console.log(action);
        personalDatabaseSlice.caseReducers.recordMeasurement(state, genAction);
      });
    },

    recordMeasurement: (state, action) => {
      // look up category
      const currentCategory = state.contents.measurementCategories.find(
        (obj) => obj.id === action.payload.categoryId
      );

      if (!currentCategory) {
        throw new Error(`${action.payload.categoryId} is not a valid category`);
      }

      // TODO - check, make sure all fields are present

      // create measurements data silo if needed
      if (
        !state.contents.measurements.hasOwnProperty(action.payload.categoryId)
      ) {
        state.contents.measurements[action.payload.categoryId] = [];
      }

      // we have a verified category - let's record the measurement
      insertSorted(
        state.contents.measurements[action.payload.categoryId],
        action.payload.item,
        (a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0)
      );
      state.dirty = true;
    },

    editMeasurement: (state, action) => {
      // Updates category by ID
      // TODO - throw an error if not found?
      const updatedMeasurement = action.payload.item;
      state.contents.measurements[action.payload.categoryId] =
        state.contents.measurements[action.payload.categoryId].map(
          (measurement) => {
            // generate the same itemRepr
            const itemRepr = getMeasurementIdentifier(measurement);
            if (itemRepr === updatedMeasurement.id) {
              return {
                date: updatedMeasurement.date,
                unit: updatedMeasurement.unit,
                value: updatedMeasurement.value,
                note: updatedMeasurement.note,
              };
            }
            return measurement;
          }
        );
      state.dirty = true;
    },

    deleteMeasurement: (state, action) => {
      const deleteId = action.payload.id;
      state.contents.measurements[action.payload.categoryId] =
        state.contents.measurements[action.payload.categoryId].filter(
          (measurement) => {
            const itemRepr = getMeasurementIdentifier(measurement);
            return itemRepr !== deleteId;
          }
        );

      state.dirty = true;
    },

    addEvent: (state, action) => {
      // we have a verified category - let's record the measurement

      const event = action.payload.item;
      //console.log("Event edited:", event);
      event["eventType"] = action.payload.eventType;
      event["linkGroup"] = event.linkGroup || 0;
      //console.log(event);

      // check if category is present
      const currentCategory = state.contents.categories.find(
        (obj) => obj.id === action.payload.item.categoryId
      );

      if (!currentCategory) {
        throw new Error(
          `${action.payload.item.categoryId} is not a valid event category`
        );
      }

      // TODO - validate schema based on event type
      if (
        !action.payload.eventType ||
        !validators.hasOwnProperty(action.payload.eventType)
      ) {
        throw new Error(
          `Unable to locate validator for ${action.payload.eventType} is not a valid event category`
        );
      }

      // make sure we always have correct date format
      event.datetime = Moment(event.datetime).toISOString();

      const validator = validators[action.payload.eventType];

      const valid = validator(event);
      if (!valid) {
        console.log(event);
        console.log(validator.errors);
        throw new Error(
          `Event of type ${action.payload.eventType} is not a valid. (${validator.errors})`
        );
      }

      // set the millis to 500 to make sure that the EventGeneralMonthMarkers always come first
      if (event["eventType"] !== "EventGeneralMonthMarker") {
        const eventDatetime = Moment(event.datetime);
        if (eventDatetime.milliseconds() !== 0) {
          eventDatetime.milliseconds(500);
          event.datetime = eventDatetime.toISOString();
        }
      }

      // insert into events repository sorted
      insertSorted(state.contents.events, event, (a, b) =>
        a.datetime > b.datetime ? 1 : a.datetime < b.datetime ? -1 : 0
      );

      state.dirty = true;
    },

    addEvents: (state, action) => {
      action.payload.itemsAndTypes.forEach((payload) => {
        //console.log(payload);
        const genAction = {
          ...action,
          payload: payload,
        };
        //console.log(action);
        personalDatabaseSlice.caseReducers.addEvent(state, genAction);
      });
    },

    clearEventLink: (state, action) => {
      const event = action.payload.event;

      // set event's linkGroup to 0
      state.contents.events = state.contents.events.map((cur_event) => {
        if (cur_event.id === event.id) {
          cur_event.linkGroup = 0;
          return cur_event;
        }
        return cur_event;
      });

      // remove corresponding entry from the event_link_groups
      const eventIndex = state.contents.event_link_groups[
        event.linkGroup
      ].indexOf(event.id);
      if (eventIndex > -1) {
        state.contents.event_link_groups[event.linkGroup].splice(eventIndex, 1);
      }

      // is the group of a size 1? If so, clear up altogether
      // and delete the event_link_group entry and set the other event's
      // linkGroup to 0 as well, no need to keep around groups of size 1
      if (state.contents.event_link_groups[event.linkGroup].length === 1) {
        // remove from other event
        state.contents.events = state.contents.events.map((cur_event) => {
          if (cur_event.linkGroup === event.linkGroup) {
            cur_event.linkGroup = 0;
            return cur_event;
          }
          return cur_event;
        });

        // remove as a group
        delete state.contents.event_link_groups[event.linkGroup];
      }

      state.dirty = true;
    },

    linkEventToAnother: (state, action) => {
      // determine next ID
      const eventLinkOrigin = action.payload.eventLinkOrigin;
      const eventLinkTarget = action.payload.eventLinkTarget;

      // clear target's association if exists
      if (eventLinkTarget.linkGroup !== 0) {
        personalDatabaseSlice.caseReducers.clearEventLink(state, {
          ...action,
          payload: {
            event: eventLinkTarget,
          },
        });
      }

      const assignLinkGroupToEvent = (event, link_group_id) => {
        state.contents.events = state.contents.events.map((cur_event) => {
          if (cur_event.id === event.id) {
            cur_event.linkGroup = link_group_id; // modify
            return cur_event;
          }
          return cur_event;
        });

        state.contents.event_link_groups[link_group_id].push(event.id);
      };

      let linkGroupIdToAssign = eventLinkOrigin.linkGroup;

      // is there already a group set up?
      if (eventLinkOrigin.linkGroup === 0) {
        // no link group yet, we need to create one
        // get next highest ID from the links
        // assign it to the event
        const curLargestGroupId = Math.max(
          ...Object.keys(state.contents.event_link_groups)
        );
        const newLargestLinkGroupId = curLargestGroupId + 1;
        state.contents.event_link_groups[newLargestLinkGroupId] = [];

        // manipulate state
        assignLinkGroupToEvent(eventLinkOrigin, newLargestLinkGroupId);

        // local change
        linkGroupIdToAssign = newLargestLinkGroupId;
      }

      assignLinkGroupToEvent(eventLinkTarget, linkGroupIdToAssign);

      state.dirty = true;
    },

    editEvent: (state, action) => {
      const updatedEvent = action.payload.item;
      const previousId = action.payload.previousId;

      console.log("Editing event", updatedEvent, previousId);

      personalDatabaseSlice.caseReducers.addEvent(state, {
        ...action,
        payload: {
          item: updatedEvent,
          eventType: updatedEvent.eventType,
        },
      });

      personalDatabaseSlice.caseReducers.deleteEvent(state, {
        ...action,
        payload: {
          id: previousId,
        },
      });

      state.dirty = true;
    },

    deleteEvent: (state, action) => {
      const event = action.payload;
      const deleteId = event.id;
      state.contents.events = state.contents.events.filter((event) => {
        return event.id !== deleteId;
      });

      /*

      we cannot do the below, as we rely on copying event during event editing.
      The cleanup can be done periodically, by going through all events and seeing what
      attachments are "orphaned". This needs to be a separate process.

      // clean up any attachments from local file storage
      event.attachments.forEach((attachmentId) => {
        delete state.contents.fileStorage[attachmentId];
      });
      */

      // TODO - clean up any no longer needed links
      state.dirty = true;
    },

    addEntity: (state, action) => {
      // get all category IDs
      const all_entity_ids = state.contents.entities.map((entity) => entity.id);
      console.log(all_entity_ids);
      if (all_entity_ids.includes(action.payload.id)) {
        throw new Error("ID is already present");
      }

      const validTypes = entityTypes.map((x) => x.name);

      if (!validTypes.includes(action.payload.type)) {
        throw new Error(`Invalid type Entity type: ${action.payload.type}`);
      }

      // no duplicates - safe to proceed and add a category
      state.contents.entities.push({
        name: action.payload.name,
        id: action.payload.id,
        type: action.payload.type,
      });
      state.dirty = true;
    },

    editEntity: (state, action) => {
      // Updates category by ID
      // TODO - throw an error if not found?
      const updatedEntity = action.payload;
      state.contents.entities = state.contents.entities.map((entity) => {
        if (entity.id === updatedEntity.id) {
          return updatedEntity;
        }
        return entity;
      });
      state.dirty = true;
    },

    deleteEntity: (state, action) => {
      const deleteId = action.payload;
      state.contents.entities = state.contents.entities.filter(
        (entity) => entity.id !== deleteId
      );

      // TODO - un-assign from all relevant content
      state.dirty = true;
    },

    addFile: (state, action) => {
      // get all category IDs
      const fileStorage = state.contents.fileStorage;

      // if (Object.keys(fileStorage).length > 100) {
      //   throw new Error("Maximum number of files reached.");
      // }

      if (Object.keys(fileStorage).includes(action.payload.id)) {
        throw new Error("File with the same ID is already present.");
      }
      // const base64String = Buffer.from(action.payload.fileContents).toString(
      //   "base64"
      // );
      fileStorage[action.payload.id] = {
        fileName: action.payload.fileName,
        fileType: action.payload.fileType,
        perFilePassword: action.payload.perFilePassword,
        isThumbnail: action.payload.isThumbnail ? true : false,
        thumbnailId: action.payload.thumbnailId,
        mime: action.payload.mime,
      };
      state.dirty = true;
    },
  },
});

export const {
  setName,
  setEncryptionPassword,
  setDatabaseContents,
  setCurrentRecord,
  setEditLock,
  addCategory,
  editCategory,
  deleteCategory,
  addMeasurementCategory,
  editMeasurementCategory,
  deleteMeasurementCategory,
  addTag,
  editTag,
  deleteTag,
  recordMeasurement,
  recordMeasurements,
  editMeasurement,
  deleteMeasurement,
  addEvent,
  addEvents,
  editEvent,
  deleteEvent,
  addEntity,
  editEntity,
  deleteEntity,
  addFile,
  setDirty,
  linkEventToAnother,
  clearEventLink,
} = personalDatabaseSlice.actions;

export default personalDatabaseSlice.reducer;
