// import all generated validation functions
import * as validate_funs from "./genschemas";
import Moment from "moment";

// import Ajv from "ajv";
// const ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}
// link latest versions of schemas from generated schemas
//const EventBaseValidator = validate_funs.EventBase_ver_1;
const EventMilestoneInjuryValidator = validate_funs.EventMilestoneInjury_ver_1;
const EventGeneralBirthdayValidator = validate_funs.EventGeneralBirthday_ver_1;
const EventGeneralMonthMarkerValidator =
  validate_funs.EventGeneralMonthMarker_ver_1;
const EventRecreationExerciseValidator =
  validate_funs.EventRecreationExercise_ver_1;

const EventAppointmentHospitalOutpatientValidator =
  validate_funs.EventAppointmentHospitalOutpatient_ver_1;

export const validators = {
  EventMilestoneInjury: EventMilestoneInjuryValidator,
  EventGeneralBirthday: EventGeneralBirthdayValidator,
  EventGeneralMonthMarker: EventGeneralMonthMarkerValidator,
  EventAppointmentHospitalOutpatient:
    EventAppointmentHospitalOutpatientValidator,
  EventRecreationExercise: EventRecreationExerciseValidator,
};

export const testValidate = function () {
  console.log("------");
  console.log(validate_funs);
  console.log(validate_funs.person);
  const testData = {
    firstName: "John",
    lastName: "Doe",
    age: 21,
  };

  let valid = validate_funs.person(testData);
  console.log(valid);
  console.log(validate_funs.person.errors);

  const testData2 = {
    firstName: "John",
    lastName: "Doe",
    age: -1,
  };

  valid = validate_funs.person(testData2);
  console.log(valid);
  console.log(validate_funs.person.errors);

  valid = validate_funs.person(testData);
  console.log(valid);
  console.log(validate_funs.person.errors);

  const testEventInjury = {
    id: "12",
    name: "test",
    description: "",
    displayTimezone: "",
    categoryId: "123",
    datetime: Moment().toISOString(),
    datetimeEnd: "",
    attachments: [],
    locationId: "",
    type: "mental",
  };
  console.log(testEventInjury);
  valid = EventMilestoneInjuryValidator(testEventInjury);
  console.log(valid);
  console.log(EventMilestoneInjuryValidator.errors);
};
