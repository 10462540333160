import { useState, useRef, useEffect } from "react";

import Box from "@mui/material/Box";
import TimelineNav from "./TimelineNav";
import VerticalTimeline from "./VerticalTimeline";
import TimelineFilters from "./TimelineFilters";

import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import { findFirstEventIndexWithMonth } from "../../utils/data/events";
import {
  setTagFilter,
  setCategoryFilter,
  setFilterExpanded,
} from "../../features/uiStateSlice";
import AddOrEditEventDialogue from "./addevent/AddOrEditEventDialogue";

import {
  getTagFilters,
  getCategoryFilters,
  getEventData,
} from "../../utils/data/selectors";

export default function TimelinePanel() {
  // This component is the source of truth when it comes to filters, date, etc.

  const dispatch = useDispatch();
  const tagNames = useSelector(getTagFilters);
  const catNames = useSelector(getCategoryFilters);
  const setTagNames = (tagIds) => {
    dispatch(setTagFilter(tagIds));
  };
  const setCatNames = (tagIds) => {
    dispatch(setCategoryFilter(tagIds));
  };

  const eventData = useSelector(getEventData);

  // useEffect(() => {
  //   return () => {
  //     // close the filter panel if empty on unload
  //     if (tagNames.length == 0 && catNames.length == 0) {
  //       console.log("Empty filters - closing filter menu");
  //       dispatch(setFilterExpanded(false));
  //     } else {
  //       console.log("Non-empty filters - opening filter menu");
  //       dispatch(setFilterExpanded(true));
  //     }

  //     // setTagNames([]);
  //     // setCatNames([]);
  //   };
  // }, []);

  const currentDateInitial = useSelector((state) => {
    const events = getEventData(state);
    if (!events) {
      // no events to show
      return null;
    } else {
      return Moment(events[events.length - 1].datetime);
    }
  });

  const [currentDate, setCurrentDate] = useState(currentDateInitial);
  const verticalTimelineScrollToIndexRef = useRef(null);

  const requestScrollToView = (year, month) => {
    console.log("Looking for", year, month);

    const eventIndex = findFirstEventIndexWithMonth(eventData, year, month);
    if (eventIndex > -1) {
      console.log("Requesting scroll to", eventIndex);
      verticalTimelineScrollToIndexRef.current(eventIndex);
    } else {
      console.log("Event not found!");
    }
  };

  // AddEvent machinery
  const showAddOrEditEventDialogue = (event, editEvent = false) => {
    console.log("Hello new event world", insertAfterEvent);
    setInsertAfterEvent(event);
    if (editEvent) {
      setEditedEvent(event);
    } else {
      setEditedEvent(null);
    }
    AddOrEditEventDialogueSetOpen(true);
  };

  const [AddOrEditEventDialogueOpen, AddOrEditEventDialogueSetOpen] =
    useState(false);
  const [insertAfterEvent, setInsertAfterEvent] = useState(null);
  const [editedEvent, setEditedEvent] = useState(null);

  return (
    <>
      <TimelineFilters
        tagNames={tagNames}
        setTagNames={setTagNames}
        catNames={catNames}
        setCatNames={setCatNames}
      />
      <Box sx={{ width: 1 }}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
          <Box gridColumn="span 2">
            <TimelineNav
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              requestScrollToView={requestScrollToView}
            />
          </Box>
          <Box gridColumn="span 10">
            <VerticalTimeline
              verticalTimelineScrollToIndexRef={
                verticalTimelineScrollToIndexRef
              }
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              showAddOrEditEventDialogue={showAddOrEditEventDialogue}
            />
          </Box>
        </Box>
        <AddOrEditEventDialogue
          open={AddOrEditEventDialogueOpen}
          setOpen={AddOrEditEventDialogueSetOpen}
          insertAfterEvent={insertAfterEvent}
          setInsertAfterEvent={setInsertAfterEvent}
          eventData={eventData}
          event={editedEvent}
          setEditedEvent={setEditedEvent}
        />
      </Box>
    </>
  );
}
