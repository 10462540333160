import { v4 as uuidv4 } from "uuid";

import Moment from "moment";
import { groupBy, sortedIndexOf, sortedIndexBy } from "lodash";

import { genLookupObjAndFun } from "./generic";

// export const initialiseEvents = function (state) {
//   state["events"] = [];
//   const dob = Moment(state.metadata.dateOfBirth);
//   console.log(dob);
//   for (var year = dob.year(); year <= 2500; year++) {
//     state["events"][year] = [];
//   }
//   return state;
// };

export const generateEventId = (event) => {
  let date = Moment(event.datetime);
  // console.log("type of date", typeof event.datetime);
  // if (typeof event.datetime === "string") {
  //   date = Moment(event.datetime);
  // }
  return `${date.year()}-${uuidv4()}`;
};

export const findCurrentEventIndex = (events, event) => {
  return sortedIndexBy(events, event, (ev) => ev.datetime);
};
export const findCurrentEventIndexById = (events, event) => {
  const searchStartIndex = findCurrentEventIndex(events, event);

  // now we have the start of our search - matching start date.
  // next step is to look for a matchind ID
  const ITER_LIMIT = 10000;
  let foundIndex = 0;
  for (var i = 0; i < ITER_LIMIT; i++) {
    const curIndex = searchStartIndex + i;
    const cEvent = events[curIndex];

    if (!cEvent) {
      // should always be set
      break;
    }

    // do we have an ID match?
    if (cEvent.id === event.id) {
      foundIndex = curIndex;
      break;
    }

    // are we getting too far off in our search?
    if (cEvent.datetime > event.datetime) {
      // dates are off
      break;
    }
  }
  return foundIndex;
};

export const findFirstEventIndexWithMonth = (events, year, month) => {
  console.log("Looking for ", year, month);

  // PROBLEM - we need to also linearly scan for the first element in a given
  // month

  const iterativeFunction = function (arr, year, month) {
    let start = 0,
      end = arr.length - 1;

    // Iterate while start not meets end
    while (start <= end) {
      // Find the mid index
      let mid = Math.floor((start + end) / 2);

      // If element is present at mid, return True
      const eventDate = Moment(arr[mid].datetime);
      console.log(eventDate, eventDate.year(), eventDate.month());
      if (eventDate.month() == month && eventDate.year() == year) {
        // now we have a match for event day and month
        // however - we want the first event, not just any event in the month,
        // so let's linearly scan backwards from the item before mid

        let befMid = mid; //initialise as our mid
        while (befMid >= 0) {
          // we do not care if it's 0, nothing to run there
          const eventDatePrev = Moment(arr[befMid].datetime);

          if (
            eventDatePrev.month() !== month ||
            eventDatePrev.year() !== year
          ) {
            // current is a different year/month, let's return the previous (one later) element
            return [true, befMid + 1];
          }

          // still same year/month, iterate
          befMid--;
        }

        // went all the way to -1 for befMid, let's return 0 as the start of the array
        return [true, 0];
      } else if (
        year > eventDate.year() ||
        (year === eventDate.year() && month > eventDate.month())
      ) {
        // year we are looking for is larger than mid
        // OR same year but month we are looking for is larger
        // therefore - we need to explore the half above the mid
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    return [false, -1];
  };

  const [found, index] = iterativeFunction(events, year, month);
  console.log(found, index);
  if (found) {
    return index;
  } else {
    return -1;
  }
};

export const genDatabaseGroups = (events) => {
  const groupedEvents = groupBy(events, (event) =>
    Moment(event.datetime).format("YYYY-MM")
  );
  const groupCounts = Object.values(groupedEvents).map(
    (events) => events.length
  );
  const groups = Object.keys(groupedEvents);

  console.log(events, groupCounts, groups);
  return { groupCounts, groups };
};

// TODO this is mirrored in schemas/events/appointments/event_appointment_hospital_ver_1.json
// should be populated from an external JSON file to stay consistent
export const eventHospitalPurpose = [
  { name: "minor-surgery", description: "Minor surgery" },
  { name: "examination", description: "Examination" },
  { name: "tests", description: "Tests" },
];

export const [eventHospitalPurposeByName, getEventHospitalPurposeByName] =
  genLookupObjAndFun(eventHospitalPurpose);

export const eventHospitalDepartment = [
  { name: "blood-clinic", description: "Blood Clinic" },
  { name: "x-ray", description: "X-Ray" },
  { name: "day-surgery", description: "Day surgery" },
  { name: "dermatology", description: "Dermatology" },
];

export const [eventHospitalDepartmentByName, getEventHospitalDepartmentByName] =
  genLookupObjAndFun(eventHospitalDepartment);

export const eventRecreationActivity = [
  { name: "swimming", description: "Swimming" },
  { name: "running", description: "Running" },
  { name: "rowing", description: "Rowing" },
  { name: "cycling", description: "Cycling" },
  { name: "walking", description: "Walking" },
  { name: "hiking", description: "Hiking" },
  { name: "circuit-training", description: "Circuit training" },
  { name: "core-training", description: "Core training" },
  { name: "weight-training", description: "Weight training" },
  { name: "yoga", description: "Yoga" },
  { name: "pilates", description: "Pilates" },
  { name: "tai-chi", description: "Tai Chi" },
];

export const [eventRecreationActivityByName, getEventRecreationActivityByName] =
  genLookupObjAndFun(eventRecreationActivity);

export const eventInjuryType = [
  { name: "mental", description: "Mental" },
  { name: "physical", description: "Physical" },
];

export const [eventInjuryTypeByName, geteventInjuryTypeByName] =
  genLookupObjAndFun(eventInjuryType);
