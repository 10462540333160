import { identicon } from "minidenticons";

export const getAvatarSVGSrc = (user_str) => {
  const avatarSvg = identicon("" + user_str, 50, 50);
  //console.log(avatarSvg);
  const base64SVGString = btoa(avatarSvg);
  const avatarUrl = `data:image/svg+xml;base64,${base64SVGString}`;
  //console.log(avatarUrl);
  return avatarUrl;
};
