import * as React from "react";
import {
  DataGrid,
  //GridToolbarContainer,
  //GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";

import {
  editMeasurementCategory,
  deleteMeasurementCategory,
} from "../../features/personalDatabaseSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";

//import Button from "@mui/material/Button";
//import AddIcon from "@mui/icons-material/Add";

import AddMeasurementCategoryDialogue from "./AddMeasurementCategoryDialogue";

import { getUnitTypeByName } from "../../utils/units";

import CustomDialogue from "../CustomDialogue";

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function MeasurementCategoryTable() {
  const dispatch = useDispatch();
  const measurementCategories = useSelector(
    (state) => state.personalDatabase.contents.measurementCategories
  );
  const { enqueueSnackbar } = useSnackbar();
  const myDialogue = React.useRef(null);

  // const handleAddMeasurementCategory = function () {
  //   console.log("TICK TOCK");
  // };

  // // UNCOMMENT for custom toolbar
  // const MeasurementCategoryTableToolbar = function () {
  //   return (
  //     <GridToolbarContainer>
  //       <Button
  //         onClick={handleAddMeasurementCategory}
  //         variant="text"
  //         size="small"
  //         startIcon={<AddIcon />}
  //       >
  //         Add
  //       </Button>
  //     </GridToolbarContainer>
  //   );
  // };

  const processRowUpdate = (newRow) => {
    console.log("Storing...", newRow);
    dispatch(editMeasurementCategory(newRow));
    return newRow;
  };

  const deleteCategoryById = (id) => () => {
    myDialogue.current
      .prompt(
        `Are you sure you want to delete category ${id}?`,
        [
          "It will be removed from all parts of the app.",
          "ALL ASSOCIATED MEASUREMENTS WILL ALSO BE DELETED.",
        ],
        "Type DELETE to proceed"
      )
      .then((answer) => {
        if (answer === "DELETE") {
          console.log("Deleting ", id);
          dispatch(deleteMeasurementCategory(id));
        } else {
          enqueueSnackbar("Your answer did not match DELETE", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        console.log("Cancelled - not deleting", id);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },
    {
      field: "name",
      headerName: "Measurement Category name",
      editable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      minWidth: 150,
      valueGetter: (params) => getUnitTypeByName(params.row.type).description,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={deleteCategoryById(params.id)}
          showInMenu
        />,
      ],
    },

    // { field: 'lastName', headerName: 'Last name', width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const handleProcessRowUpdateError = React.useCallback((error) => {
    alert(error.message);
  }, []);

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={measurementCategories}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          // checkboxSelection
          // components={{ Toolbar: CustomToolbar }}
          disableSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          // components={{
          //   Toolbar: MeasurementCategoryTableToolbar,
          // }}
        />

        <AddMeasurementCategoryDialogue />
      </div>
      <CustomDialogue ref={myDialogue} />
    </>
  );
}
