import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
//import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

import { Controller, useForm } from "react-hook-form";

import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import _uniqueId from "lodash/uniqueId";
import FormHelperText from "@mui/material/FormHelperText";

import Select from "@mui/material/Select";

import { getAvailableUnits, convertFromToUnit } from "../../utils/units";
import { labeledStatement } from "@babel/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: [-100, 200, 300, 123, 0.02, -500, -69],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Dataset 2",
//       data: [200, 300, 123, 0.02, -500, -69, -100],
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };

export default function ChartJSExample(props) {
  const { currentMeasurementCategory } = props;
  const [id] = useState(_uniqueId("addmeasurementcategorygialogue-"));
  //const [selectedUnit, setSelectedUnit] = useState(null);
  const measurements = useSelector(
    (state) => state.personalDatabase.contents.measurements
  );

  const [chartData, setChartData] = useState(null);

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: `Progression of: ${currentMeasurementCategory.name}`,
      },
    },
  };

  const {
    //setValue,
    clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const processRows = (toUnit, rows) => {
    console.log(rows);
    if (!rows) {
      return [[], rows];
    }

    const labels = rows.map((row) => row.date);
    const dataRows = rows.map((row) => {
      //return { x: row.date, y: parseFloat(row.value) };
      console.log(row.unit);
      // TODO - create custom measurement for unitless?
      if (row.unit === "UNIT") {
        return parseFloat(row.value);
      } else {
        return convertFromToUnit(row.unit, toUnit, parseFloat(row.value));
      }
    });
    return [labels, dataRows];
  };

  const onSubmit = (formData) => {
    console.log(formData);

    const [labels, data] = processRows(
      formData.measurementUnit,
      measurements[currentMeasurementCategory.id]
    );

    labels.forEach((element, index) => {
      labels[index] = Moment(labels[index]).format("DD/MM/YYYY");
    });

    const chartDataGen = {
      labels,
      datasets: [
        {
          label: formData.measurementUnit,
          data: data,
          borderColor: "rgb(0, 171, 85)",
          backgroundColor: "rgba(0, 171, 85, 0.5)",
        },
      ],
      options: {
        scales: {
          x: {
            type: "timeseries",
          },
        },
      },
    };
    console.log(chartDataGen);
    setChartData(chartDataGen);
  };

  return (
    <>
      {/* show dropdown  */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"measurementUnit"}
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <FormControl variant="standard" fullWidth margin="normal">
              <InputLabel
                error={errors.measurementUnit ? true : false}
                id={`${id}-inputlabel`}
              >
                Measurement Unit
              </InputLabel>
              <Select
                labelId={`${id}-inputlabel`}
                // margin="dense"
                label="Unit"
                type="text"
                variant="standard"
                onChange={onChange}
                required
                value={value}
                error={errors.measurementUnit ? true : false}
              >
                {getAvailableUnits(currentMeasurementCategory.type).map(
                  (unit) => (
                    <MenuItem value={unit} key={unit}>
                      {unit}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText error={errors.measurementUnit ? true : false}>
                {errors.measurementUnit ? "This field is required" : ""}
              </FormHelperText>
            </FormControl>
          )}
        />

        <Button
          variant="contained"
          component="div"
          onClick={handleSubmit(onSubmit)}
        >
          Apply changes
        </Button>
      </form>
      {chartData && (
        <Box sx={{ pb: 5 }}>
          <Line options={options} data={chartData} />
        </Box>
      )}
    </>
  );
}
