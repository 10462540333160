import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";

import Moment from "moment";
import { styled } from "@mui/material/styles";

const EventNavYear = styled(ListItemButton)(({ theme }) => ({
  padding: "6px 0 6px 40px",
  "&:hover, &.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiListItemText-root > span": {
    fontSize: "14px",
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-selected": {
    backgroundColor: "transparent",
    "& .MuiListItemText-root > span": {
      color: theme.palette.grey[800],
      fontSize: "16px",
    },
  },
}));

const EventNavMonth = styled(ListItemButton)(({ theme }) => ({
  padding: "4px 0 4px 40px",
  "&:hover, &.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiListItemText-root > span": {
    fontSize: "14px",
    color: theme.palette.grey[800],
    borderLeft: "2px solid transparent",
    marginLeft: "-2px",
  },
  "&.Mui-selected": {
    background: "transparent",
    //"&:not(.Mui-disabled) .MuiListItemText-root > span": {
    "& .MuiListItemText-root > span": {
      borderLeftColor: theme.palette.primary.main,
      paddingLeft: "7px",
    },
    "&.Mui-disabled .MuiListItemText-root > span": {
      borderLeftColor: theme.palette.grey[500],
    },
  },
  "&.Mui-disabled": {
    opacity: 1,
    "& .MuiListItemText-root > span": {
      color: theme.palette.grey[500],
    },
  },
}));

export default function TimelineNavYear(props) {
  const { currentDate, setCurrentDate, yearItem, requestScrollToView } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleYearClick = () => {
    setExpanded(!expanded);
  };

  const handleMonthClick = (month) => {
    console.log(month);
    setExpanded(false); // otherwise every manually navigated to year will be expanded
    //setCurrentDate(Moment([yearItem.year, month, 1]));

    // call passed-through method
    requestScrollToView(yearItem.year, month);
  };

  return (
    <div>
      <EventNavYear
        onClick={handleYearClick}
        selected={currentDate.year() == yearItem.year} // only highlighting "active" year
      >
        <ListItemText primary={yearItem.year} />
      </EventNavYear>
      <Collapse
        in={expanded || currentDate.year() == yearItem.year}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {yearItem.months.map((x, monthIndex) => (
            <div key={x.month + "-" + yearItem.year}>
              <EventNavMonth
                disabled={x.hasEvents === true ? false : true}
                onClick={() => {
                  handleMonthClick(monthIndex);
                }}
                selected={
                  currentDate.year() == yearItem.year &&
                  currentDate.month() == monthIndex
                }
              >
                <ListItemText primary={x.month} />
              </EventNavMonth>
            </div>
          ))}
        </List>
      </Collapse>
    </div>
  );
}
