import React, { useState } from "react";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useForm } from "react-hook-form";

import _uniqueId from "lodash/uniqueId";

import { useSelector, useDispatch } from "react-redux";
import { addEntity } from "../../features/personalDatabaseSlice";
import { entityTypes } from "../../utils/data/entities";
import OurTextField from "../forms/OurTextField";
import OurSelect from "../forms/OurSelect";

export default function AddEntityDialogue(props) {
  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  //const [dbPassword, setDbPassword] = useState('');

  const {
    //setValue,
    clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [id] = useState(_uniqueId("add-entity-dialogue-"));
  /*
  React.useEffect(() => {
    triggerRef.current = handleClickOpen
  }, [])
  // PREP to create link-back to the dialogue open function
  // https://dilshankelsen.com/call-child-function-from-parent-component-in-react/
  */

  const handleClose = () => {
    clearErrors();
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);

    // generate unique ID - largest?
    let largestIdSofar = 0;

    // iterate through all - no guarantees re. ordering
    personalDatabaseContents.entities.forEach(function (entity, index) {
      console.log(index);
      largestIdSofar = Math.max(largestIdSofar, entity.id);
    });

    dispatch(
      addEntity({
        id: largestIdSofar + 1,
        name: data.entityName,
        type: data.entityType,
      })
    );

    handleClose();
  };

  return (
    <>
      <Button variant="contained" component="div" onClick={handleClickOpen}>
        Add Entity
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add Entity</DialogTitle>
          <DialogContent>
            <DialogContentText>Please specify Entity name</DialogContentText>
            <OurTextField
              autoFocus={true}
              fieldName="entityName"
              control={control}
              errors={errors}
              label="Name"
            />

            <OurSelect
              fieldName="entityType"
              control={control}
              errors={errors}
              label="Entity Type"
              options={entityTypes.map((key) => ({
                label: key.description,
                value: key.name,
              }))}
              nonErrorHelpText="The Entity Type cannot be changed later."
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit(onSubmit)}>Add Entity</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
