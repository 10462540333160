import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Moment from "moment";
import { Box } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import DateRangeIcon from "@mui/icons-material/DateRange";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import _uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";

import { styled } from "@mui/material/styles";

import { deleteEvent } from "../../features/personalDatabaseSlice";
import { setFilterExpanded, setTagFilter } from "../../features/uiStateSlice";
import Iconify from "../Iconify";
import assetService from "../../services/auth/asset.service";

import CustomDialogue from "../CustomDialogue";

const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  padding: 0,
  "& .MuiCardHeader-avatar": {
    marginRight: "18px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
    },
  },
  "& .MuiCardHeader-title, & .MuiCardHeader-subheader": {
    display: "inline-block",
    fontSize: "16px",
    fontWeight: "600",
  },
  "& .MuiCardHeader-title": {
    color: theme.palette.grey[600],
  },
  "& .MuiCardHeader-subheader": {
    color: theme.palette.grey[800],
    borderLeft: `1px solid ${theme.palette.grey[600]}`,
    paddingLeft: "5px",
    marginLeft: "5px",
  },
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: 0,
  "& > .appointment__main-title": {
    marginTop: "21px",
    fontSize: "24px",
    color: theme.palette.grey[800],
  },
}));

const CardFooterItem = styled(Typography)(({ theme }) => ({
  display: "inline-flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  marginRight: "24px",
  "& .MuiSvgIcon-root": {
    color: theme.palette.common.black,
    marginRight: "10px",
  },
}));

const TagButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.grey[300],
  borderRadius: "4px",
  boxShadow: "none",
  padding: 0,
  paddingLeft: "5px",
  paddingRight: "5px",
  minWidth: "38px",
  margin: "0 5px",
  "&:last-of-type": {
    marginRight: 0,
  },
}));

export default function VerticalTimelineItemCard(props) {
  const myDialogue = useRef(null);
  const {
    event,
    children,
    cardIcon,
    cardTitle,
    cardSubheader,
    scrollToIndex,
    showAddOrEditEventDialogue,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const tags = useSelector((state) =>
    state.personalDatabase.contents.tags
      .filter((tag) => tag.id !== 0)
      .sort((a, b) => a.name.localeCompare(b.name))
  );
  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );

  const getFullTagById = (tagId) => {
    for (var i = 0; i < tags.length; i++) {
      let el = tags[i];
      if (el.id === tagId) {
        return el;
      }
    }
    // not found, return the value back at least
    return tagId;
  };

  // related to the
  const [elementId] = useState(_uniqueId("eventcard-"));
  const [anchorEl, setAnchorEl] = useState(null);
  const dottMenuOpen = Boolean(anchorEl);
  const handleMenuOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleMenuClose();

    myDialogue.current
      .confirm(`Are you sure you want to delete the event ${event.name}?`, [
        "This action cannot be undone.",
      ])
      .then(() => {
        console.log("Deleting ", event.id, event.name);

        dispatch(deleteEvent(event));

        event.attachments.forEach((attachmentId) => {
          console.log("Deleting attachment from backend", attachmentId);
          assetService
            .destroy(currentRecord.id, attachmentId)
            .then(() => {
              console.log("Deleted attachment", attachmentId);
            })
            .catch((error) => {
              console.log("Unable to delete attachment", attachmentId, error);
            });
        });

        enqueueSnackbar("Event has been deleted", {
          variant: "success",
        });
      })
      .catch((response) => {
        console.log("Cancelled - not deleting", event.id, event.name);
        // enqueueSnackbar(`Action cancelled.`, {
        //   variant: "info",
        // });
      });
  };

  const handleTagClick = (tag) => {
    // set filters when tag is clicked
    dispatch(setTagFilter([tag]));
    dispatch(setFilterExpanded(true));
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: "30px 40px",
          boxShadow:
            "0 12px 24px -4px rgba(145, 158, 171, 0.12), 0 0 2px 0 rgba(145, 158, 171, 0.2)",
        }}
      >
        <CardHeaderStyled
          avatar={cardIcon}
          title={cardTitle}
          subheader={cardSubheader}
          action={
            <>
              <IconButton
                aria-label="Event actions"
                id={elementId + "-button"}
                aria-controls={dottMenuOpen ? elementId + "-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={dottMenuOpen ? "true" : undefined}
                onClick={handleMenuOpenClick}
              >
                <MoreHoriz />
              </IconButton>
              <Menu
                id={elementId + "-menu"}
                aria-labelledby={elementId + "-button"}
                anchorEl={anchorEl}
                open={dottMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    showAddOrEditEventDialogue(event, true);
                  }}
                >
                  <Box sx={{ mr: 2 }}>Edit</Box>
                  <Iconify
                    icon={"eva:edit-fill"}
                    sx={{
                      width: 16,
                      height: 16,
                      position: "absolute",
                      mr: 1,
                      right: 0,
                      top: "8px",
                      flexShrink: 0,
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={handleDelete} sx={{ color: "red" }}>
                  <Box sx={{ mr: 2 }}>Delete</Box>
                  <Iconify
                    icon={"eva:trash-2-fill"}
                    sx={{
                      width: 16,
                      height: 16,
                      position: "absolute",
                      mr: 1,
                      right: 0,
                      top: "8px",
                      flexShrink: 0,
                    }}
                  />
                </MenuItem>
              </Menu>
            </>
          }
        />
        <CardContentStyled>{children}</CardContentStyled>
        <CardActions
          sx={{
            borderTop: " solid 1px rgba(145, 158, 171, 0.24)",
            display: "flex",
            justifyContent: "space-between",
            padding: "34px 0 0",
            marginTop: "37px",
          }}
        >
          <div>
            <div>
              <CardFooterItem variant="body2" color="text.secondary">
                <DateRangeIcon />
                {Moment(event.datetime).format("DD/MM/YYYY")}
                {event.datetimeEnd !== "" &&
                  ` to ${Moment(event.datetimeEnd).format("DD/MM/YYYY")}`}
              </CardFooterItem>
              <CardFooterItem variant="body2" color="text.secondary">
                <WatchLaterIcon />
                {`${Moment(event.datetime).format("HH.MM")} ${
                  event.displayTimezone
                }`}
                {event.datetimeEnd !== "" &&
                  ` to ${Moment(event.datetimeEnd).format("HH.MM")} ${
                    event.displayTimezone
                  }`}
              </CardFooterItem>
              {event.locationId && event.locationId.cached_label && (
                <CardFooterItem variant="body2" color="text.secondary">
                  <FmdGoodIcon />
                  {event.locationId.cached_label}
                </CardFooterItem>
              )}
            </div>
          </div>
          <div>
            {event.tags.map((tagId) => (
              <TagButton
                onClick={() => handleTagClick(getFullTagById(tagId))}
                key={tagId}
              >
                {getFullTagById(tagId).name}
              </TagButton>
            ))}
          </div>
        </CardActions>
      </Card>
      <CustomDialogue ref={myDialogue} />
    </>
  );
}
