import CryptoJS from "crypto-js";

export const encryptBinaryStringToBase64 = (data, password) => {
  //console.log("Encrypting", data);
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Latin1.parse(data),
    password
  ).toString();
  const encString = encrypted.toString(CryptoJS.enc.Base64);
  return encString;
};

export const encryptToBase64 = (data, password) => {
  /**
   * Encrypt base64 string
   */
  //console.log("Encrypting", data);
  const encrypted = CryptoJS.AES.encrypt(data, password).toString();
  const encString = encrypted.toString(CryptoJS.enc.Base64);
  //   const hash = CryptoJS.SHA256(toEncrypt);

  //   const fileContents = {
  //     // version: objectContents.version, // TODO do we still want this?
  //     data: encString,
  //     hash: hash.toString(CryptoJS.enc.Base64),
  //   };

  return encString;
};

export const encryptObject = (objectContents, password) => {
  /**
   * Serializes object
   */
  const toEncrypt = JSON.stringify(objectContents);
  return encryptToBase64(toEncrypt, password);
};

export const genRandomPassword = (string_length) => {
  // https://stackoverflow.com/questions/9719570/generate-random-password-string-with-requirements-in-javascript
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  let randomstring = "";
  let charCount = 0;
  let numCount = 0;

  for (var i = 0; i < string_length; i++) {
    // // If random bit is 0, there are less than 3 digits already saved, and there are not already 5 characters saved, generate a numeric value.
    // if (
    //   (Math.floor(Math.random() * 2) == 0 && numCount < 3) ||
    //   charCount >= 5
    // ) {
    //   var rnum = Math.floor(Math.random() * 10);
    //   randomstring += rnum;
    //   numCount += 1;
    // } else {
    // If any of the above criteria fail, go ahead and generate an alpha character from the chars string
    var rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
    charCount += 1;
    // }
  }
  return randomstring;
};
