// material
import { Box, Container } from "@mui/material";
// components
import Page from "../components/Page";
import React, { useState } from "react";

import EntityTable from "../components/entities/EntityTable";
import _uniqueId from "lodash/uniqueId";

export default function Entities() {
  const pageStyle = {};

  const [value, setValue] = useState(0);

  return (
    <Page
      title="Manage Organisations &amp; Providers | Holistory"
      style={pageStyle}
    >
      <Container maxWidth="xl">
        <Box sx={{ width: "100%" }}>
          <EntityTable />
        </Box>
      </Container>
    </Page>
  );
}
