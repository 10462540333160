import { useRef, useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import MenuPopover from "../../components/MenuPopover";
import RecordAvatar from "../../components/RecordAvatar";

//
import recordsService from "../../services/auth/records.service";

import { useSelector, useDispatch } from "react-redux";
import {
  setName,
  setDatabaseContents,
  setEncryptionPassword,
  setCurrentRecord,
  setEditLock,
} from "../../features/personalDatabaseSlice";
import { setReloading } from "../../features/uiStateSlice";

import { getAllDbNames } from "../../utils/data/indexes";

import { getAvatarSVGSrc } from "../../utils/images";
import SearchIndexContext from "../../contexts/SearchIndexContext";

import { useSnackbar } from "notistack";

import CustomDialogue from "src/components/CustomDialogue";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: "Home",
  //   icon: "eva:home-fill",
  //   linkTo: "/",
  // },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  //   linkTo: '#'
  // },

  {
    label: "Record Settings",
    icon: "eva:settings-2-fill",
    linkTo: "/profile/database",
  },

  {
    label: "Categories & Tags",
    icon: "eva:inbox-fill",
    linkTo: "/profile/categories-tags",
  },
  {
    label: "Organisations & Providers",
    icon: "eva:briefcase-outline",
    linkTo: "/profile/entities",
  },

  // {
  //   label: 'Timeline',
  //   icon: 'mdi:timeline-clock-outline',
  //   linkTo: '/profile/timeline'
  // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const myDialogue = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );
  const editLock = useSelector((state) => state.personalDatabase.editLock);
  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );

  const { eventsSearchIndex } = useContext(SearchIndexContext);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const clearDatabase = () => {
    myDialogue.current
      .confirm(`Are you sure you want to close the record?`, [
        "Any unsaved changes will be lost.",
      ])
      .then(() => {
        recordsService
          .releaseEditLock(currentRecord.id, editLock)
          .then(({ data }) => {
            //
            console.log("Lock released.");
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar("Lock release failed.", {
              variant: "error",
            });
          });

        const prevEditLock = editLock;

        eventsSearchIndex
          .FLUSH()
          .then((r) => {
            console.log("DB Flush complete", r);
          })
          .catch((e) => {
            console.log("DB Flush failed", e);
          });

        dispatch(setEditLock(null));
        dispatch(setDatabaseContents(null));
        dispatch(setName(null));
        dispatch(setCurrentRecord(null));
        dispatch(setEncryptionPassword(null));
        // redirect should happen automatically as the dashboard layout detects
        // that database contents is set to empty

        // delete record-keeping key

        const currentDbNames = getAllDbNames(prevEditLock);

        // level-js uses specific prefix, apply it now
        const fullDbNames = currentDbNames.map(
          (dbName) => `level-js-${dbName}`
        );
        fullDbNames.map((fullDBName) => {
          localStorage.removeItem(`state-keepdb-${fullDBName}`);
          window.si = null;
        });
        window.check_and_clean_indexeddb();

        // START
        // TODO remove this after proper indexdb cleanup is done
        // workaround for indexdb deletion - start fresh
        // and reload, thus forcing all open connections to close
        // TODO - show spinner until the reload happens?
        dispatch(setReloading()); // put a loader up
        setTimeout(function () {
          window.check_and_clean_indexeddb();
          setTimeout(function () {
            window.location.reload();
          }, 100);
        }, 100);
        // END
      })
      .catch((response) => {
        console.log("Cancelled");
      });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <RecordAvatar variant="rounded" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 260 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {personalDatabaseContents.metadata.name &&
              personalDatabaseContents.metadata.name}
            {!personalDatabaseContents.metadata.name && <>Local Database</>}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography> */}
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={clearDatabase}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            Close record
          </Button>
        </Box>
      </MenuPopover>
      <CustomDialogue ref={myDialogue} />
    </>
  );
}
