import Page from "../components/Page";
import TimelinePanel from "../components/timeline/TimelinePanel";

export default function CustomTimeline() {
  return (
    <Page title="Personal timeline | Holistory">
      <TimelinePanel />
    </Page>
  );
}
