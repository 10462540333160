import * as React from "react";
import {
  DataGrid,
  //GridToolbarContainer,
  //GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";

import { editEntity, deleteEntity } from "../../features/personalDatabaseSlice";
import DeleteIcon from "@mui/icons-material/Delete";

import AddEntityDialogue from "./AddEntityDialogue";

import { getEntityTypeByName } from "../../utils/data/entities";

import CustomDialogue from "../CustomDialogue";

// Export toolbar example
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function EntityTable() {
  const myDialogue = React.useRef(null);
  const dispatch = useDispatch();
  const entities = useSelector(
    (state) => state.personalDatabase.contents.entities
  );

  const processRowUpdate = (newRow) => {
    console.log("Storing...", newRow);
    dispatch(editEntity(newRow));
    return newRow;
  };

  const deleteEntityById = (id) => () => {
    myDialogue.current
      .confirm(`Are you sure you want to delete Entity ${id}?`, [
        "It will be removed from all parts of the app.",
      ])
      .then(() => {
        console.log("Deleting ", id);
        dispatch(deleteEntity(id));
      })
      .catch((response) => {
        console.log("Cancelled - not deleting", id);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },
    {
      field: "name",
      headerName: "Entity name",
      editable: true,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      minWidth: 150,
      valueGetter: (params) => getEntityTypeByName(params.row.type).description,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={deleteEntityById(params.id)}
          showInMenu
        />,
      ],
    },

    // { field: 'lastName', headerName: 'Last name', width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const handleProcessRowUpdateError = React.useCallback((error) => {
    alert(error.message);
  }, []);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={entities}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        // checkboxSelection
        // components={{ Toolbar: CustomToolbar }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
      <AddEntityDialogue />
      <CustomDialogue ref={myDialogue} />
    </div>
  );
}
