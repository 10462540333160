import HTTP from "../http";
import { get as _get } from "lodash";

/**
 * Create a user in the system via the API.
 * @param {Object} data
 */
function create(data) {
  return HTTP.post(`/api/v1/users/register/`, data, {
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  });
}

/**
 * Destroy the user with the provided id via the API.
 * @param {string|number} id
 */
function destroy(id) {
  throw "Not implemented";
}

/**
 * Find and return a user with the specified id via the API
 * @param {string|number} id
 */
function find(id) {
  throw "Not implemented";
}

// /**
//  * Return whether the provided user is currently active.
//  * @param {Object} user
//  */
// function isActiveNow(user) {
//     if (!user.last_active_at) {
//         return false;
//     }

//     return moment() <= moment(user.last_active_at).add(5, 'minutes');
// }

/**
 * Log the currently authenticated user out of the system.
 */
function logout() {
  HTTP.get("/auth/logout/").then(() => {
    window.location.replace("/login");
  });
}

/**
 * Get the currently authenticated user.
 */
function me() {
  return HTTP.get("/api/v1/me/");
}

/**
 * Attempt to update the user with the given id using the provided data.
 * @param {string|number} id
 * @param {Object} data
 */
function update(id, data) {
  return HTTP.post(`/api/v2/users/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * Verify the currently authenticated user's password.
 * @param {String} password
 */
function verifyPassword(password) {
  return HTTP.post("/api/v2/me/password/verify/", {
    password,
  });
}

/**
 * Change the user's current password.
 * @param {String} current The user's current password.
 * @param {String} password The password to change to.
 * @param {String} confirmation Confirmation of the password to change to.
 */
function changePassword(current, password, confirmation) {
  return HTTP.put("/api/v2/me/password/change/", {
    current_password: current,
    password,
    password_confirmation: confirmation,
  });
}

export default {
  create,
  //   destroy,
  //   find,
  logout,
  me,
  //   update,
  //   verifyPassword,
  //   changePassword,
};
