import * as React from "react";
import List from "@mui/material/List";

import { useSelector } from "react-redux";
import Moment from "moment";
import TimelineNavYear from "./TimelineNavYear";

import { styled } from "@mui/material/styles";
import {
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP,
} from "../../layouts/dashboard/index.js";
import { FILTER_DESKTOP } from "./TimelineFilters";

import { getEventData } from "../../utils/data/selectors";

/* 
TODO:
- find a way to match fixed child's width with that of the parents'
 */
const EventNav = styled(List)(({ theme }) => ({
  height: `calc(100vh - ${APP_BAR_MOBILE + FILTER_DESKTOP + 2}px)`,
  [theme.breakpoints.up("lg")]: {
    height: `calc(100vh - ${APP_BAR_DESKTOP + FILTER_DESKTOP + 2}px)`,
  },
  position: "fixed",
  overflowY: "auto",
  paddingTop: "17px",
}));

export default function TimelineNav(props) {
  const { currentDate, setCurrentDate, requestScrollToView } = props;

  const navData = useSelector((state) => {
    const events = getEventData(state);
    if (!events) {
      // no events to show
      return [];
    }

    // generate data - from DoB till current date
    const dobYear = Moment(
      state.personalDatabase.contents.metadata.dateOfBirth
    ).year();
    const startYear =
      events.length > 1
        ? Math.min(dobYear, Moment(events[0].datetime).year())
        : dobYear;

    // TODO - inject DoB event?

    const endYear = Moment(events[events.length - 1].datetime).year();
    const yearsRet = [];

    if (startYear > endYear) {
      throw new Error("Invalid start/end year combination");
    }

    const yearMapping = {};
    for (var year = startYear; year <= endYear; year++) {
      const yearData = {
        year: year,
        months: [
          { month: "January", hasEvents: false },
          { month: "February", hasEvents: false },
          { month: "March", hasEvents: false },
          { month: "April", hasEvents: false },
          { month: "May", hasEvents: false },
          { month: "June", hasEvents: false },
          { month: "July", hasEvents: false },
          { month: "August", hasEvents: false },
          { month: "September", hasEvents: false },
          { month: "October", hasEvents: false },
          { month: "November", hasEvents: false },
          { month: "December", hasEvents: false },
        ],
      };
      yearsRet.push(yearData);
      yearMapping[year] = yearData;
    }

    // iterate over months and assign events
    events.forEach((event) => {
      const eventDate = Moment(event.datetime);
      const monthObj = yearMapping[eventDate.year()].months[eventDate.month()];
      if (event.eventType !== "EventGeneralMonthMarker") {
        // do not count markers
        monthObj.hasEvents = monthObj.hasEvents || true;
      }
    });

    //console.log(yearsRet);
    return yearsRet;
  });

  return (
    <EventNav component="nav" className="hideScroll">
      {navData.map((item) => (
        <TimelineNavYear
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          requestScrollToView={requestScrollToView}
          yearItem={item}
          key={item.year}
        />
      ))}
    </EventNav>
  );
}
