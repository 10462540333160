export const getTagFilters = (state) => {
  return state.uiState.timeline.filters.tag;
};
export const getCategoryFilters = (state) => {
  return state.uiState.timeline.filters.category;
};
export const getEventData = (state) => {
  const debug = false;
  let eventData = state.personalDatabase.contents.events;
  if (debug) console.log("Event data length initial", eventData.length);

  // TODO fulltext search here
  //

  const categoryFilters = getCategoryFilters(state);
  const tagFilters = getTagFilters(state);
  const searchString = state.uiState.timeline.filters.search_string;

  if (searchString.length > 0) {
    // search string filtering
    // we want to retain system-generated data
    eventData = eventData.filter(
      (event) =>
        event.categoryId === 0 ||
        state.uiState.timeline.filters.search_ids.includes(event.id)
    );
  }

  if (categoryFilters.length > 0) {
    const includeCategoryIds = categoryFilters.map((category) => category.id);
    if (debug) console.log("Category IDs", includeCategoryIds);

    // we want to retain system-generated data
    eventData = eventData.filter(
      (event) =>
        event.categoryId === 0 || includeCategoryIds.includes(event.categoryId)
    );
  }
  if (debug)
    console.log("Event data length after category filter", eventData.length);

  if (tagFilters.length > 0) {
    const includeTagIds = tagFilters.map((tag) => tag.id);
    if (debug) console.log("Tag IDs", includeTagIds);

    // again, we are retaining system-generated events
    eventData = eventData.filter(
      (event) =>
        event.categoryId === 0 ||
        event.tags.some((tagId) => includeTagIds.includes(tagId))
    );
  }
  if (debug)
    console.log("Event data length after tag filter", eventData.length);

  return eventData;
};
