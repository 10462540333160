import FullWidthTabs from "./TimelineItemTabs";
import TimelineCard from "../VerticalTimelineItemCard.js";

import { createSvgIcon } from "@mui/material/utils";

import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import {
  getEventHospitalDepartmentByName,
  getEventHospitalPurposeByName,
} from "../../../utils/data/events";

import { getEntityTypeByName } from "../../../utils/data/entities";

const HospitalIcon = createSvgIcon(
  <path fill="currentColor" d="M18 14H14V18H10V14H6V10H10V6H14V10H18" />,
  "Appointment"
);

const AppointmentDetailsStyled = styled("div")(({ theme }) => ({
  padding: "31px 31px 33px",
  margin: "31px 0 29px",
  backgroundColor: "rgba(249, 250, 251, 0.5)",
  border: "solid 1px rgba(145, 158, 171, 0.24)",
  borderRadius: "16px",
  "& .appointment__title": {
    marginBottom: "30px",
    display: "flex",
    flexFlow: "row nowarp",
    "& > div": {
      textAlign: "center",
      width: "50%",
      "&:first-of-type": {
        borderRight: "1px solid rgba(145, 158, 171, 0.24)",
      },
      "& h2": {
        color: theme.palette.grey[800],
        fontSize: "24px",
        fontWeight: 700,
      },
      "& span": {
        display: "block",
        fontSize: "14px",
        color: theme.palette.grey[600],
      },
    },
  },
  "& .appointment__content": {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    "& > span": {
      color: theme.palette.grey[800],
      fontSize: "14px",
      borderRadius: "24px",
      border: "solid 1px rgba(145, 158, 171, 0.32)",
      minWidth: "145px",
      margin: "10px",
      padding: "5px 12px",
      textAlign: "center",
    },
  },
}));

export default function AppointmentTimelineItem(props) {
  const { event, scrollToIndex, showAddOrEditEventDialogue } = props;

  const currentEntity = useSelector((state) => {
    for (var entity of state.personalDatabase.contents.entities) {
      if (entity.id === event.entityId) {
        return entity;
      }
    }
    return null;
  });

  return (
    <TimelineCard
      cardIcon={<HospitalIcon sx={{ fontSize: "37px" }} />}
      cardTitle="Appointment"
      cardSubheader="Hospital (Outpatient)"
      event={event}
      scrollToIndex={scrollToIndex}
      showAddOrEditEventDialogue={showAddOrEditEventDialogue}
    >
      <AppointmentDetailsStyled>
        <div className="appointment__title">
          <div>
            <h2>{currentEntity && <>{currentEntity.name}</>}</h2>
            <span>
              {currentEntity && (
                <>{getEntityTypeByName(currentEntity.type).description}</>
              )}
            </span>
          </div>
          {event.departments && event.departments.length > 0 && (
            <>
              <div>
                <h2>
                  {
                    getEventHospitalDepartmentByName(event.departments[0])
                      .description
                  }
                  {/* {event.departments[0]} */}
                </h2>

                <span>Department</span>
              </div>
            </>
          )}
        </div>
        <div className="appointment__content">
          {event.purposes &&
            event.purposes.map((purpose) => (
              <span key={purpose}>
                {getEventHospitalPurposeByName(purpose).description}
              </span>
            ))}
        </div>
      </AppointmentDetailsStyled>

      <FullWidthTabs event={event} scrollToIndex={scrollToIndex} />
    </TimelineCard>
  );
}
