import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import authService from "../../../services/auth/auth.service";
import { useSelector, useDispatch } from "react-redux";

import {
  storeCSRFToken,
  setLoginStateAsLoading,
} from "../../../features/loginStateSlice";
import { trigger } from "../../../utils/events";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const csrfToken = useSelector((state) => state.loginState.csrftoken);
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "petr.klus@framewurk.io",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      authService
        .login(values.username, values.password, csrfToken)
        .then((data) => {
          console.log("Login success", data);
          // logged in, go to init

          // update CSRF token
          authService.fetchToken().then(({ data }) => {
            dispatch(storeCSRFToken(data));
          });

          dispatch(setLoginStateAsLoading());
          trigger("me:fetchMeNow");

          setTimeout(function () {
            navigate("/init", { replace: true });
          }, 500);
        })
        .catch((error) => {
          setSubmitting(false);
          console.log("Login error", error);
          const status = error.response ? error.response.status : null;

          if (status === 422) {
            console.log(error.response.data.errors);
            //setStatus(error.response.data.errors);
            setErrors(error.response.data.errors);
          }
          // Update auth token if csrf token is invalid
          else if (status === 403) {
            authService.fetchToken().then(({ data }) => {
              dispatch(storeCSRFToken(data));
            });
          } else {
            //alert(error.response);
          }
        });
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setStatus,
    handleSubmit,
    getFieldProps,
    setErrors,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("username")}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to="#"
            underline="hover"
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
