import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import _uniqueId from "lodash/uniqueId";
import { genHelpText } from "../../utils/formsUtils.js";
import Iconify from "../../components/Iconify";
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";

export default function OurTextField(props) {
  const {
    fieldName,
    control,
    errors,
    label,
    required = true,
    defaultValue = "",
    multiline = false,
    inputType = "text",
    maxLength = false,
    autoFocus = false,
    passwordAdornment = false,
    variant = "standard",
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  // const [id] = useState(_uniqueId("custom-textfield-"));

  const getMultilineProps = (multiline) => {
    if (multiline) {
      return {
        multiline: true,
        maxRows: 4,
      };
    }
    return {};
  };

  const getMaxLenRuleProps = (maxLength) => {
    if (maxLength) {
      return {
        maxLength: maxLength,
      };
    }
    return {};
  };

  const getInputProps = () => {
    if (passwordAdornment) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => setShowPassword(!showPassword)}
            >
              <Iconify
                icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
              />
            </IconButton>
          </InputAdornment>
        ),
      };
    } else return {};
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: required, ...getMaxLenRuleProps(maxLength) }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControl variant={variant} fullWidth margin="normal">
          <TextField
            autoFocus={autoFocus}
            label={label}
            type={showPassword && inputType == "password" ? "text" : inputType}
            variant={variant}
            onChange={onChange}
            required={required}
            value={value}
            {...getMultilineProps(multiline)}
            error={errors[fieldName] ? true : false}
            helperText={errors[fieldName] ? genHelpText(errors[fieldName]) : ""}
            InputProps={getInputProps()}
          />
        </FormControl>
      )}
    />
  );
}
