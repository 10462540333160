const massPerVolumeMeasure = {
  systems: {
    metric: {
      "g/L": {
        name: {
          singular: "gram per litre",
          plural: "grams per litre",
        },
        to_anchor: 1,
      },
      "ng/mL": {
        name: {
          singular: "nanogram per litre",
          plural: "nanograms per litre",
        },
        to_anchor: 1 / 1e6, // 1e3/1e9
      },
      "pg/mL": {
        name: {
          singular: "picogram per litre",
          plural: "picograms per litre",
        },
        to_anchor: 1 / 1e9, // 1e3/1e12
      },
    },
  },
};

export default massPerVolumeMeasure;
