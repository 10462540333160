// material
import { Box, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";

import React, { useState } from "react";

import Button from "@mui/material/Button";

import { useSelector, useDispatch } from "react-redux";
// import { addEvent } from "../features/personalDatabaseSlice";
// import RestoreDatabase from "../components/RestoreDatabase";
// import DownloadDatabasePasswordDialogue from "../components/DownloadDatabasePasswordDialogue";
// import Moment from "moment";
// import UserList from "../minimal/pages/dashboard/UserList_mod";

import { testValidate } from "../schemas/schemas";

import { useSnackbar } from "notistack";

import UpdateProfilePhoto from "../components/UpdateProfilePhoto";

import { useEffect } from "react";

// import { _userFollowers } from "../minimal/_mock";
// import ProfileFollowers from "../minimal/sections/@dashboard/user/profile/ProfileFollowers";
// import LinkEventTable from "../components/timeline/LinkEventTable";

const PrettyPrintJson = ({ data }) => {
  // (destructured) data could be a prop for example
  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default function PersonalDatabase() {
  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );
  const dispatch = useDispatch();

  const [showDebug, setShowDebug] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const pageStyle = {};
  const [open, setOpen] = useState(false);

  // testing only
  useEffect(() => {}, []);

  const toggleDatabaseOutput = () => {
    setShowDebug(!showDebug);
  };

  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );

  return (
    <Page title="Select your personal database | Holistory" style={pageStyle}>
      <Container maxWidth="xl">
        {/*   
        <Box sx={{ pb: 2 }}>
          <Typography variant="h4">Database</Typography>
        </Box>

        {personalDatabaseContents === null && (
          <div>
            No database loaded. <br />
            <br />
            <br />
          </div>
        )}
        
        {personalDatabaseContents && (
          <>
            <DownloadDatabasePasswordDialogue />
          </>
        )} */}

        <>
          <Box sx={{ pb: 2, pt: 7 }}>
            <Typography variant="h4">Custom records image</Typography>
            <UpdateProfilePhoto />
          </Box>
          {/* Debug actions below */}
          {/* <LinkEventTable
            event={
              personalDatabaseContents.events.filter(
                (event) => event.categoryId !== 0
              )[0]
            }
          /> */}
          <Box sx={{ pb: 2, pt: 7 }}>
            <Typography variant="h4">DEBUG: Misc. debug actions</Typography>
          </Box>
          <Button variant="contained" onClick={testValidate} component="div">
            Test Validate (see console)
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="outlined"
            onClick={() => {
              enqueueSnackbar("Hello world!");
              enqueueSnackbar("anyone there", { variant: "info" });
            }}
            component="div"
          >
            Snackbar
          </Button>
          <br />
          <br />
          <br />
          {/* <UserList /> */}
        </>

        {personalDatabaseContents && (
          <>
            <Box sx={{ pb: 2, pt: 7 }}>
              <Typography variant="h4">DEBUG: Database contents</Typography>
            </Box>
            <Button
              variant="contained"
              onClick={toggleDatabaseOutput}
              component="div"
            >
              {!showDebug && <>Show DB contents</>}
              {showDebug && <>Hide DB contents</>}
            </Button>
            {showDebug && <PrettyPrintJson data={personalDatabaseContents} />}
          </>
        )}
      </Container>
    </Page>
  );
}
