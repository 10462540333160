import React, { useEffect, useRef, useMemo, useState } from "react";

import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import _uniqueId from "lodash/uniqueId";
import { genHelpText } from "../../utils/formsUtils.js";

// autocomplete for location testing
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = "AIzaSyAN7R7W1sAsGA-DR7duEoRmXELWo_yGWDg";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function LocationWithAutocompleteField(props) {
  // const { handleClose, eventType, eventData, insertAfterEvent } = props;

  const {
    fieldName,
    control,
    errors,
    label,
    required = true,
    defaultValue = "",
    setValue,
  } = props;

  const [localValue, setLocalValue] = useState(null);
  const [inputValue, setInputValue] = useState(
    defaultValue ? defaultValue.cached_label : ""
  );

  const [options, setOptions] = React.useState([]);
  const loaded = useRef(false);
  const [id] = useState(_uniqueId("custom-location-autocomplete-"));

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue.typed_info);
      setLocalValue(defaultValue.typed_info);
    }

    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(localValue ? [localValue] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (localValue) {
          newOptions = [localValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);

        // // are we editing?
        // if (defaultValue == inputValue && newOptions.length > 0) {
        //   // Editing
        //   console.log("Setting up initial value for editing purposes");
        //   console.log(newOptions);
        //   setLocalValue(newOptions[0]);
        //   setValue(fieldName, newOptions[0]);
        // }
      }
    });

    return () => {
      active = false;
    };
  }, [localValue, inputValue, fetch]);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: required }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControl variant="standard" fullWidth margin="normal">
          <Autocomplete
            id={id}
            // sx={{ width: 300 }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={localValue}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setLocalValue(newValue);

              onChange(newValue);
              console.log(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="standard"
                onChange={onChange}
                required={required}
                value={localValue}
                error={errors[fieldName] ? true : false}
                helperText={
                  errors[fieldName] ? genHelpText(errors[fieldName]) : ""
                }
              />
            )}
            renderOption={(props, option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings;
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );

              return (
                <li {...props} key={option.place_id}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box
                        component={LocationOnIcon}
                        sx={{ color: "text.secondary", mr: 2 }}
                      />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        </FormControl>
      )}
    />
  );
}
