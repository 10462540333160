import React, { useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { alpha, styled } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";

import { Controller, useForm } from "react-hook-form";

import _uniqueId from "lodash/uniqueId";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { encryptObject, genRandomPassword } from "../utils/data/crypto";
import { generateFreshDatabase } from "../utils/data/generic";
import { migrateToLatest } from "../utils/migrations";
import { trigger } from "../utils/events";

import OurDateTimeField from "./forms/OurDateTimeField";
import OurTextField from "./forms/OurTextField";

import recordsService from "../services/auth/records.service";
import { useSnackbar } from "notistack";

import Moment from "moment";

const BigButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export default function StartDatabase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    //setValue,
    clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      dateOfBirth: "",
      name: "",
    },
  });

  const [id] = useState(_uniqueId("startdatabase-"));

  const onSubmit = (data) => {
    console.log(data);
    console.log(data.dateOfBirth.toISOString());

    const name = data.firstName + " " + data.lastName;
    const newDatabaseContents = migrateToLatest(
      generateFreshDatabase(name, data.dateOfBirth)
    );

    //

    // encrypt and submit to the API

    //recordsService now
    const password_salt = genRandomPassword(16);

    recordsService
      .create({
        name: name,
        dob: data.dateOfBirth.toISOString(),
        asset: encryptObject(
          newDatabaseContents,
          data.dbPassword + password_salt
        ),
        password_salt: password_salt,
      })
      .then(({ data }) => {
        // force-refresh ME?
        console.log(data);

        enqueueSnackbar("Record has been created", {
          variant: "success",
        });

        trigger("me:fetchMeNow");
        reset(); // TODO seems to not affect the datepicker now?
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Unable to create records", {
          variant: "error",
        });
      });

    // submit to the API?
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OurTextField
          // autoFocus={true}
          fieldName="firstName"
          control={control}
          errors={errors}
          label="First name"
          variant="outlined"
        />

        <OurTextField
          fieldName="lastName"
          control={control}
          errors={errors}
          label="Last name"
          variant="outlined"
        />

        <OurDateTimeField
          fieldName="dateOfBirth"
          control={control}
          errors={errors}
          label="Birthday"
          variant="outlined"
        />

        <OurTextField
          fieldName="dbPassword"
          control={control}
          errors={errors}
          label="Password"
          inputType="password"
          passwordAdornment={true}
          variant="outlined"
        />

        <BigButton
          sx={{}}
          fullWidth
          variant="contained"
          component="span"
          size="large"
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </BigButton>
      </form>
    </>
  );
}
