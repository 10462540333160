import Box from "@mui/material/Box";

import BirthdayTimelineItem from "./events/BirthdayTimelineItem";
import MilestoneTimelineItem from "./events/MilestoneTimelineItem";
import AppointmentTimelineItem from "./events/AppointmentTimelineItem";
import RecreationTimelineItem from "./events/RecreationTimelineItem";
import TimelineDivider from "./events/TimelineDivider";
import TimelineDividerMonth from "./events/TimelineDividerMonth";

export default function VerticalTimelineItem(props) {
  const { event, scrollToIndex, showAddOrEditEventDialogue } = props;

  // Box below is for iterim spacing of the cards
  return (
    <Box
      sx={{
        padding: "0 20px 10px",
      }}
    >
      {event.eventType == "EventGeneralBirthday" && (
        <BirthdayTimelineItem
          event={event}
          scrollToIndex={scrollToIndex}
          showAddOrEditEventDialogue={showAddOrEditEventDialogue}
        />
      )}
      {event.eventType == "EventMilestoneInjury" && (
        <MilestoneTimelineItem
          event={event}
          scrollToIndex={scrollToIndex}
          showAddOrEditEventDialogue={showAddOrEditEventDialogue}
        />
      )}

      {event.eventType == "EventAppointmentHospitalOutpatient" && (
        <AppointmentTimelineItem
          event={event}
          scrollToIndex={scrollToIndex}
          showAddOrEditEventDialogue={showAddOrEditEventDialogue}
        />
      )}

      {event.eventType == "EventRecreationExercise" && (
        <RecreationTimelineItem
          event={event}
          scrollToIndex={scrollToIndex}
          showAddOrEditEventDialogue={showAddOrEditEventDialogue}
        />
      )}

      {event.eventType == "EventGeneralMonthMarker" && (
        <TimelineDividerMonth
          event={event}
          scrollToIndex={scrollToIndex}
          showAddOrEditEventDialogue={showAddOrEditEventDialogue}
        />
      )}
      <TimelineDivider
        event={event}
        scrollToIndex={scrollToIndex}
        showAddOrEditEventDialogue={showAddOrEditEventDialogue}
      />
    </Box>
  );
}
