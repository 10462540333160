import { birthdayEventTask } from "./birthdays";
import { monthMarkerEventsTask } from "./monthmarkers";
import { indexedDbMarkerTask, indexedDbCleanTask } from "./indexeddb";

let store = null;
const tasks = [
  {
    callable: birthdayEventTask,
    interval: 5 * 1000,
    name: "birthdayEventTask",
  },
  {
    callable: monthMarkerEventsTask,
    interval: 5 * 1000,
    name: "monthMarkerEventsTask",
  },
  {
    callable: indexedDbMarkerTask,
    interval: 10 * 1000,
    name: "indexedDbMarkerTask",
  },
  {
    callable: indexedDbCleanTask,
    interval: 120 * 1000,
    name: "indexedDbCleanTask",
  },
];

const taskTimers = {};

export function initTaskStore(initStore) {
  store = initStore;
}

export function startAllTasks() {
  if (!store) {
    throw new Error(
      "Store is required before tasks can be started (run initTaskStore)"
    );
  }

  const taskByName = {};

  tasks.forEach((task) => {
    if (task.name in taskTimers) {
      // task already running
      console.log("Task " + task.name + " is already running");
      return;
    } else {
      console.log("Starting " + task.name);
      taskTimers[task.name] = setInterval(() => {
        task.callable(store);
      }, task.interval);
      taskByName[task.name] = task;
    }
  });

  console.log("Tasks activated");

  document.addEventListener("run-our-task", function (event) {
    console.log("Running task via event:", event.detail.taskName);
    taskByName[event.detail.taskName].callable(store);
  });
}

export function stopAllTasks() {
  const toDelete = [];
  for (const [key, value] of Object.entries(taskTimers)) {
    clearTimeout(value);
    delete taskTimers[key];
  }
  console.log("Tasks deactivated");
}
