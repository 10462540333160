import { useSelector } from "react-redux";

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Icon } from "@iconify/react";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { default as lodashOrderBy } from "lodash/orderBy";

import { styled } from "@mui/material/styles";

import Moment from "moment";

import MultimediaGallery from "./MultimediaGallery";

import AttachmentList from "./AttachmentList";
import LinksTab from "../LinksTab";
import LinkEventTable from "../LinkEventTable";

const TabContainerStyled = styled("div")(({ theme }) => ({
  marginTop: "22px",
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiTabs-flexContainer": {
    // maxWidth: "500px",
    "& .MuiButtonBase-root": {
      color: theme.palette.grey[600],
      opacity: 1,
      fontWeight: "600",
      padding: 0,
      minWidth: "64px",
      minHeight: "48px",
      margin: "0 20px",
      "&:first-of-type": {
        marginLeft: 0,
      },
      "&.Mui-selected": {
        color: theme.palette.grey[800],
      },
      "& .MuiSvgIcon-root, .MuiTab-iconWrapper": {
        fontSize: "20px",
      },
    },
  },
  ".customTabpanel": {
    borderTop: "solid 1px rgba(145, 158, 171, 0.24);",
    marginTop: "-1px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="customTabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: "35px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(props) {
  const { event, scrollToIndex } = props;

  const getFirstValidIndex = () => {
    if (event.description) {
      return 1;
    }
    if (eventGalleryContent.length > 0) {
      return 2;
    }
    if (event.attachments.length > 0) {
      return 3;
    }
    return 4;
  };

  const events = useSelector((state) => state.personalDatabase.contents.events);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabIndex = (index) => (event.description ? index + 1 : index);

  // const _userGallery = [...Array(3)].map((_, index) => ({
  //   id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b" + (index + 1),
  //   title: "Test image",
  //   postAt: Moment(),
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1604537466608-109fa2f16c3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80",
  // }));

  const eventGalleryContent = useSelector((state) => {
    return lodashOrderBy(
      event.attachments
        .map((attachmentId) => {
          const attachmentInfo =
            state.personalDatabase.contents.fileStorage[attachmentId];
          return {
            id: attachmentId,
            ...attachmentInfo,
          };
        })
        .filter((attachment) => attachment.fileType === "media"), // TODO improve?
      ["title"],
      ["asc"]
    );
  });

  // const _userGallery = [
  //   {
  //     id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b-1",
  //     title: "Before setting off",
  //     postAt: Moment("2022-06-07"),
  //     imageUrl: "/static/mock-images/exercise/thumbs/1.jpeg",
  //   },
  //   {
  //     id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b-5",
  //     title: "Beautiful trails",
  //     postAt: Moment("2022-06-07"),
  //     imageUrl: "/static/mock-images/exercise/thumbs/5.jpeg",
  //   },
  //   {
  //     id: "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b-7",
  //     title: "Almost home",
  //     postAt: Moment("2022-06-07"),
  //     imageUrl: "/static/mock-images/exercise/thumbs/4.jpeg",
  //   },
  // ];
  const [value, setValue] = React.useState(getFirstValidIndex());

  const [linksEditActive, setLinksEditActive] = React.useState(false);

  return (
    <TabContainerStyled>
      <Tabs value={value} onChange={handleChange} aria-label="tabs">
        {event.description && (
          <Tab
            icon={<Icon icon="bxs:book-alt" />}
            iconPosition="start"
            label="Notes"
            value={1}
            index={1}
            {...a11yProps(0)}
          />
        )}
        {eventGalleryContent.length > 0 && (
          <Tab
            icon={<PermMediaIcon />}
            iconPosition="start"
            label="Multimedia"
            value={2}
            {...a11yProps(1)}
          />
        )}

        {event.attachments.length > 0 && (
          <Tab
            icon={<AttachFileIcon />}
            iconPosition="start"
            label="Attachments"
            value={3}
            {...a11yProps(2)}
          />
        )}

        <Tab
          icon={<InsertLinkIcon />}
          iconPosition="start"
          label="Links"
          value={4}
          {...a11yProps(3)}
        />
      </Tabs>
      {event.description && (
        <TabPanel value={value} index={1}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            <Box sx={{ whiteSpace: "pre-wrap" }} component="span">
              {event.description}
            </Box>
          </Typography>
        </TabPanel>
      )}
      {eventGalleryContent.length > 0 && (
        <TabPanel value={value} index={2}>
          <MultimediaGallery gallery={eventGalleryContent} />
        </TabPanel>
      )}
      {event.attachments.length > 0 && (
        <TabPanel value={value} index={3}>
          <AttachmentList event={event} />
        </TabPanel>
      )}
      <TabPanel value={value} index={4}>
        <Button
          size="small"
          sx={{ mb: 2 }}
          onClick={() => {
            setLinksEditActive(!linksEditActive);
          }}
          variant="outlined"
          color="primary"
        >
          {linksEditActive ? "Back" : "Edit links"}
        </Button>

        {linksEditActive && <LinkEventTable event={event} />}

        {!linksEditActive && (
          <LinksTab
            scrollToIndex={scrollToIndex}
            linkedEvents={events
              .filter(
                (iterEvent) =>
                  [
                    "EventMilestoneInjury",
                    "EventRecreationExercise",
                    "EventAppointmentHospitalOutpatient",
                  ].includes(iterEvent.eventType) &&
                  iterEvent.id !== event.id &&
                  event.linkGroup === iterEvent.linkGroup &&
                  event.linkGroup !== 0
              )
              .map((iterEvent, index) => ({
                id: iterEvent.id,
                eventType: iterEvent.eventType,
                name: iterEvent.name,
                datetime: Moment(iterEvent.datetime),
                location:
                  iterEvent.locationId && iterEvent.locationId.cached_label
                    ? iterEvent.locationId.cached_label
                    : false,
                isLinked: true,
              }))}
          />
        )}
      </TabPanel>
    </TabContainerStyled>
  );
}
