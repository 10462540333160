import React from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import _uniqueId from "lodash/uniqueId";
import { genHelpText } from "../../utils/formsUtils.js";
import Moment from "moment";

export default function OurDateTimeField(props) {
  const {
    fieldName,
    control,
    errors,
    label,
    required = true,
    defaultValue = "",
    time = false,
    autoFocus = false,
    variant = "standard",
  } = props;

  // const [id] = useState(_uniqueId("custom-textfield-"));

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required,
        validate: {
          date: (val) => (!required && !val) || Moment(val).isValid(),
          dateRange: (val) =>
            (!required && !val) ||
            (Moment(val).year() >= 1920 &&
              Moment(val).year() <= Moment().year()),
        },
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl variant={variant} fullWidth margin="normal">
          {time ? (
            <DateTimePicker
              label={label}
              onChange={onChange}
              value={value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={autoFocus}
                  variant={variant}
                  required={required}
                  error={errors[fieldName] ? true : false}
                  helperText={
                    errors[fieldName] ? genHelpText(errors[fieldName]) : ""
                  }
                />
              )}
            />
          ) : (
            <DatePicker
              label={label}
              onChange={onChange}
              value={value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={autoFocus}
                  variant={variant}
                  required={required}
                  error={errors[fieldName] ? true : false}
                  helperText={
                    errors[fieldName] ? genHelpText(errors[fieldName]) : ""
                  }
                />
              )}
            />
          )}
        </FormControl>
      )}
    />
  );
}
