import React, { useState, useRef } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { Controller, useForm } from "react-hook-form";

import { findCurrentEventIndex } from "../../../utils/data/events";

import Step1SelectEventType from "./Step1SelectEventType";
import Step2AddEventForm from "./Step2AddEventForm";

import _uniqueId from "lodash/uniqueId";

import { useSelector, useDispatch } from "react-redux";
import { recordMeasurement } from "../../../features/personalDatabaseSlice";

// import { getAvailableUnits } from "../../utils/units";

export default function AddOrEditEventDialogue(props) {
  const {
    open,
    setOpen,
    insertAfterEvent,
    setInsertAfterEvent,
    eventData,
    event = null,
    setEditedEvent,
  } = props;

  const [eventType, setEventType] = useState(null);
  const dispatch = useDispatch();
  const {
    //setValue,
    clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [id] = useState(_uniqueId("addeventdialogue-"));

  const selectCategorySelectorSubmitRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      clearErrors();
      reset();
      setInsertAfterEvent(null);
      setEventType(null);
      setEditedEvent(null);
    } else {
      console.log("Ignoring backdrop click");
    }
  };

  const editModeActive = event === null;

  const onSubmit = (data) => {
    console.log(data);

    console.log(data.measurementDate.toISOString());

    handleClose();
  };

  return (
    <>
      <Dialog
        transitionDuration={{ exit: 0 }} // otherwise we see the form rejig itself on close
        open={open}
        onClose={handleClose}
        fullWidth
        disableEscapeKeyDown={true}
        maxWidth="sm"
      >
        {/* Add event */}
        {!event && !eventType && (
          // Event type selection dialog content + actions
          <Step1SelectEventType
            handleClose={handleClose}
            setEventType={setEventType}
          />
        )}
        {!event && eventType && insertAfterEvent && (
          <Step2AddEventForm
            handleClose={handleClose}
            eventData={eventData}
            eventType={eventType}
            insertAfterEvent={insertAfterEvent}
            event={event}
          />
        )}
        {/* Edit event */}
        {event && (
          <Step2AddEventForm
            handleClose={handleClose}
            eventData={eventData}
            eventType={event.eventType}
            insertAfterEvent={insertAfterEvent}
            event={event}
          />
        )}
      </Dialog>
    </>
  );
}
