// material
import { Stack, Button, Divider, Typography } from "@mui/material";
// component
import Iconify from "../../components/Iconify";
import { useState } from "react";

import { Link } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { trigger } from "../../utils/events";

// ----------------------------------------------------------------------

export default function AuthSocial() {
  const [myWindow, setMyWindow] = useState(null);
  const [open, setOpen] = useState(false);

  const link =
    process.env.REACT_APP_STATE_BACKEND_API_URL +
    "/soc/login/google-oauth2?next=/close_window";

  const activateGoogleAuth = function () {
    setMyWindow(window.open(link, "MsgWindow", "width=500,height=600"));

    // show an overlay to indicate that the user is being logged in?
    //
    setOpen(true);

    // next - increase polling for the /me?
    trigger("me:increasePollingInterval");

    // how can we listen to changes to user logged in state?
    // we do not need to - parent reacts to it if necessary
  };

  const handleClose = () => {
    // decrease the polling rate again

    setOpen(false);
    trigger("me:restorePollingInterval");
  };

  return (
    <>
      {/* Dialog opens once Google auth is being processed  */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>External authentication provider</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please follow the instructions provided in the popup window.
            <br />
            This dialogue will close automatically.
            <br />
            <br />
            If the pop up did not open, please{" "}
            <Link
              underline="none"
              // component={RouterLink}
              href={link}
              target="_blank"
            >
              click here
            </Link>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={2}>
        <Button
          onClick={activateGoogleAuth}
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
        >
          <Iconify icon="eva:google-fill" color="#DF3E30" height={24} />
        </Button>

        {/* 
        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" height={24} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" height={24} />
        </Button> */}
      </Stack>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
