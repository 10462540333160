import React from "react";
import LocationWithAutocompleteField from "../../../forms/LocationWithAutocompleteField";
import OurSelect from "../../../forms/OurSelect";
import OurTextField from "../../../forms/OurTextField";

import { eventInjuryType } from "../../../../utils/data/events";

export default function EventMilestoneInjuryForm(props) {
  const { control, errors, event, setValue } = props;

  return (
    <>
      <LocationWithAutocompleteField
        fieldName="locationInformation"
        control={control}
        errors={errors}
        label="Location"
        required={true}
        defaultValue={event ? event.locationId : ""}
        setValue={setValue}
      />

      <OurSelect
        fieldName="type"
        control={control}
        errors={errors}
        label="Type"
        required={true}
        options={eventInjuryType.map((injuryType) => ({
          value: injuryType.name,
          label: injuryType.description,
        }))}
        defaultValue={event ? event.type : ""}
      />
    </>
  );
}
