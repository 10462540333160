import { configureStore } from "@reduxjs/toolkit";
import personalDatabaseReducer from "../features/personalDatabaseSlice";
import loginStateReducer from "../features/loginStateSlice";
import uiStateReducer from "../features/uiStateSlice";

const store = configureStore({
  reducer: {
    personalDatabase: personalDatabaseReducer,
    loginState: loginStateReducer,
    uiState: uiStateReducer,
  },
});

export default store;
