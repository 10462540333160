import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import Iconify from "../../components/Iconify";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import { styled } from "@mui/material/styles";
import {
  setFilterExpanded,
  setSearchParams,
} from "../../features/uiStateSlice";
export const FILTER_DESKTOP = 62;

const FiltersStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  backgroundColor: theme.palette.common.white,
  padding: "0 33px",
  borderTop: "1px solid rgba(145, 158, 171, 0.24)",
  borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
  "& .MuiAccordionSummary-root": {
    minHeight: FILTER_DESKTOP,
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey[800],
    "&.Mui-expanded": {
      minHeight: FILTER_DESKTOP,
    },
    "& .MuiAccordionSummary-content": {
      justifyContent: "flex-end",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      fontSize: "20px",
      marginLeft: "8px",
      "&.Mui-expanded": {
        transform: "rotate(0deg)",
      },
    },
  },
  "& .MuiAccordionDetails-root": {
    display: "flex",
    flexFlow: "row nowarp",
    justifyContent: "space-between",
    alignItems: "center",
    "& > div": {
      flexGrow: 1,
    },
  },
}));

// ----------------------------------------------------------------------

export default function TimelineFilters(props) {
  const { tagNames, setTagNames, catNames, setCatNames } = props;

  const dispatch = useDispatch();
  const categories = useSelector(
    // we do not want to show system generated events category
    (state) =>
      state.personalDatabase.contents.categories
        .filter((category) => category.id !== 0)
        .sort((a, b) => a.name.localeCompare(b.name))
  );

  const tags = useSelector((state) =>
    state.personalDatabase.contents.tags
      .filter((tag) => tag.id !== 0)
      .sort((a, b) => a.name.localeCompare(b.name))
  );

  const defaultExpanded = useSelector(
    (state) => state.uiState.timeline.filters.expanded
  );

  const expanded = useSelector(
    (state) => state.uiState.timeline.filters.expanded
  );
  const searchString = useSelector(
    (state) => state.uiState.timeline.filters.search_string
  );

  useEffect(() => {
    // close the filter panel if empty on load
    if (tagNames.length == 0 && catNames.length == 0) {
      console.log("Empty filters - closing filter menu");
      dispatch(setFilterExpanded(false));
    } else {
      console.log("Non-empty filters - opening filter menu");
      dispatch(setFilterExpanded(true));
    }

    return () => {};
  }, []);

  const onExpanderChange = (event, expanded) => {
    console.log("Expansion change", expanded);
    dispatch(setFilterExpanded(expanded));
  };

  // Tags ------------------------------------------
  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setTagNames(value);

    if (value.length) {
      const dataToBeSent = value.map((val) => val.id);
      console.log("TAGS SENT");
      console.log(dataToBeSent);
    }
  };

  const getNiceTag = (value) => {
    for (var i = 0; i < tags.length; i++) {
      let el = tags[i];
      if (el.id === value.id) {
        return el.name;
      }
    }
    // not found, return the value back at least
    return value;
  };

  // Categories ---------------------------------------
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCatNames(value);

    if (value.length) {
      const dataToBeSent = value.map((val) => val.id);
      console.log("CATEGORIES SENT");
      console.log(dataToBeSent);
    }
  };

  const getNiceCat = (value) => {
    for (var i = 0; i < categories.length; i++) {
      let el = categories[i];
      if (el.id === value.id) {
        return el.name;
      }
    }
    // not found, return the value back at least
    return value;
  };

  const clearFields = () => {
    setTagNames([]);
    setCatNames([]);
    dispatch(
      setSearchParams({
        search_string: "",
        search_ids: [],
      })
    );
    dispatch(setFilterExpanded(true));
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingBottom: `${FILTER_DESKTOP + 2}px`,
      }}
    >
      <FiltersStyle>
        <Accordion expanded={expanded === true} onChange={onExpanderChange}>
          <AccordionSummary
            expandIcon={<Iconify icon="bi:filter" />}
            aria-controls="filter-content"
            id="filter-header"
          >
            Filters
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <FormControl
                sx={{
                  mr: 1,
                  width: "100%",
                  maxWidth: "300px",
                }}
                size="small"
              >
                <InputLabel id="multiple-tags-label">Tags</InputLabel>
                <Select
                  labelId="multiple-tags-label"
                  id="multiple-tags"
                  multiple
                  value={tagNames}
                  onChange={handleTagChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) =>
                    selected.map(getNiceTag).join(", ")
                  }
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag}>
                      <Checkbox checked={tagNames.indexOf(tag) > -1} />
                      <ListItemText primary={tag.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                sx={{ mr: 1, width: "100%", maxWidth: "300px" }}
                size="small"
              >
                <InputLabel id="multiple-categories-label">
                  Categories
                </InputLabel>
                <Select
                  labelId="multiple-categories-label"
                  id="multiple-categories"
                  multiple
                  value={catNames}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="Category" />}
                  renderValue={(selected) =>
                    selected.map(getNiceCat).join(", ")
                  }
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category}>
                      <Checkbox checked={catNames.indexOf(category) > -1} />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {searchString.length > 0 && (
                <Box sx={{ display: "inline-flex", padding: "10px 0px" }}>
                  Search term: "{searchString}"
                </Box>
              )}
            </div>

            <Button
              color="neutral"
              variant="text"
              sx={{ flexGrow: 0 }}
              onClick={() => clearFields()}
            >
              Clear
            </Button>
          </AccordionDetails>
        </Accordion>
      </FiltersStyle>
    </div>
  );
}

{
  /*  <Checkbox checked={catNames.indexOf(category) > -1}/>  */
}
