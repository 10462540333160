import * as React from "react";
import {
  DataGrid,
  //GridToolbarContainer,
  //GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";

import {
  editCategory,
  deleteCategory,
} from "../../features/personalDatabaseSlice";
import { setCategoryFilter } from "../../features/uiStateSlice";

import DeleteIcon from "@mui/icons-material/Delete";

//import AddCategoryDialogue from "./AddCategoryDialogue";
import { useSnackbar } from "notistack";

import { DataValidationError } from "../../utils/data/errors";

import CustomDialogue from "../CustomDialogue";
// Export toolbar example
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function ReportTable() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const myDialogue = React.useRef(null);

  const categories = useSelector(
    // we do not want to show system generated events category
    (state) =>
      state.personalDatabase.contents.categories.filter(
        (category) => category.id !== 0
      )
  );

  const processRowUpdate = (newRow) => {
    console.log("Storing...", newRow);
    dispatch(editCategory(newRow));
    return newRow;
  };

  const deleteCategoryById = (id) => () => {
    myDialogue.current
      .confirm("This action is not reversible", [
        "Are you sure you want to do this?",
      ])
      .then(() => {
        console.log("Deleting ", id);
        try {
          dispatch(deleteCategory(id));
          dispatch(setCategoryFilter([]));
          enqueueSnackbar(`Deleted successfully`, {
            variant: "success",
          });
        } catch (error) {
          if (error instanceof DataValidationError) {
            enqueueSnackbar(`Unable to delete: ${error.message}`, {
              variant: "error",
            });
          } else {
            console.log(error);
            enqueueSnackbar(`Action failed.`, {
              variant: "error",
            });
          }
        }
      })
      .catch((response) => {
        console.log(response);
        console.log("Not deleting", id);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, sortable: false },
    {
      field: "name",
      headerName: "Report name",
      editable: true,
      minWidth: 200,
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={deleteCategoryById(params.id)}
          showInMenu
        />,
      ],
    },

    // { field: 'lastName', headerName: 'Last name', width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const handleProcessRowUpdateError = React.useCallback((error) => {
    alert(error.message);
  }, []);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={categories}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        // checkboxSelection
        // components={{ Toolbar: CustomToolbar }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
      {/* <AddCategoryDialogue /> */}
      <CustomDialogue ref={myDialogue} />
    </div>
  );
}
