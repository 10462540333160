import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Checkbox, TableRow, TableCell, MenuItem } from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import { TableMoreMenu } from "../../../components/table";

// ----------------------------------------------------------------------

AttachmentTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onDownloadRow: PropTypes.func,
};

export default function AttachmentTableRow({ row, selected, onDownloadRow }) {
  const theme = useTheme();

  const { name, type, icon, mime } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}
      {icon && (
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <Iconify icon={icon} sx={{ mr: 2, width: "25px", height: "40px" }} />

          {name}
        </TableCell>
      )}
      {!icon && <TableCell>{name}</TableCell>}

      <TableCell align="left">
        {type} {mime && <>({mime})</>}
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: "error.main" }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
                Delete
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  onDownloadRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={"eva:download-fill"} />
                Download
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
