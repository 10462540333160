import React, { useEffect } from "react";

// import _uniqueId from "lodash/uniqueId";
// import * as _ from "lodash";
import { useForm } from "react-hook-form";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import OurSelect from "../../forms/OurSelect";

export default function SelectEventType(props) {
  const { handleClose, setEventType } = props;

  const {
    //setValue,
    //clearErrors,
    handleSubmit,
    //watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data, setEventType);
    setEventType(data.eventType);
  };
  const eventTypeOptions = [
    { type: "separator", label: "Milestone", value: "EventMilestone" },
    { label: "Injury", value: "EventMilestoneInjury" },
    { type: "separator", label: "Appointment", value: "EventAppointment" },
    {
      label: "Hospital - Outpatient",
      value: "EventAppointmentHospitalOutpatient",
    },
    { type: "separator", label: "Recreation", value: "EventRecreation" },
    { label: "Exercise", value: "EventRecreationExercise" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Add Event - Select Event Type</DialogTitle>
      <DialogContent>
        Please select Event Type
        <OurSelect
          fieldName="eventType"
          control={control}
          errors={errors}
          label="Event type"
          required={true}
          options={eventTypeOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSubmit)}>Continue</Button>
      </DialogActions>
    </form>
  );
}
