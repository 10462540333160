import { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useSelector, useDispatch, useStore } from "react-redux";
import { HeaderStyle } from "../LogoOnlyLayout";
import Logo from "../../components/Logo";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SearchIndexProvider from "../../providers/SearchIndexProvider";

// ----------------------------------------------------------------------

export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const store = useStore();

  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );

  const handleStoreUpdate = function () {
    console.log("Store update!");
    const state = store.getState();
    const personalDatabaseContentsInternal = state.personalDatabase.contents;
    console.log(personalDatabaseContentsInternal);
    if (personalDatabaseContentsInternal == null) {
      console.log("Redirecting to init, empty database...");
      navigate("/login");
    }
  };

  useEffect(() => {
    // Anything in here is fired on component mount.
    const unsubscribe = store.subscribe(handleStoreUpdate);

    // check just in case
    handleStoreUpdate();

    return () => {
      // Anything in here is fired on component unmount.
      unsubscribe();
    };
  }, []);

  return (
    <RootStyle>
      {personalDatabaseContents === null && (
        <>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>
          Initialised database is required.
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/init"
          >
            Go to init.
          </Link>
        </>
      )}

      {personalDatabaseContents !== null && (
        <SearchIndexProvider>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <MainStyle>
            <Outlet />
          </MainStyle>
        </SearchIndexProvider>
      )}
    </RootStyle>
  );
}
