/* Original from
 https://levelup.gitconnected.com/an-approach-to-javascript-object-schema-migration-ae1bd9f0ce78
*/

const noDownGrades = (schema) => {
  // no downgrades needed/supported
  throw new Error("Not supported");
};

const upOrDown = (fromVersion, toVersion) => {
  console.log(fromVersion, toVersion);
  const fromNumbers = fromVersion.split(".").map((el) => Number(el));
  const toNumbers = toVersion.split(".").map((el) => Number(el));
  for (let i = 0; i < fromNumbers.length; i++) {
    if (fromNumbers[i] < toNumbers[i]) {
      return "up";
    }
    if (fromNumbers[i] > toNumbers[i]) {
      return "down";
    }
  }
  return "same";
};

const migrations = [
  {
    from: "2.0",
    to: "3.0",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };
      newSchema["tags"] = {};
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.0",
    to: "3.1",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };
      newSchema["tags"] = [];
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.1",
    to: "3.2",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };
      newSchema["measurementCategories"] = [];
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.2",
    to: "3.3",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };
      newSchema["measurements"] = {}; // object, will be sharded by measurement category
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.3",
    to: "3.4",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise events array based on DoB
      newSchema["metadata"] = {
        dateOfBirth: "1960-01-01T00:00:00.000Z",
        name: "",
      };

      // // now initialise years
      newSchema["events"] = [];
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.4",
    to: "3.5",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise entities
      newSchema["entities"] = [];
      return newSchema;
    },
    down: noDownGrades,
  },

  {
    from: "3.5",
    to: "3.6",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise entities
      newSchema["events"] = newSchema["events"].map((event) => ({
        ...event,
        tags: [],
      }));
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.6",
    to: "3.7",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // translate event locations
      newSchema["events"] = newSchema["events"].map((event) => ({
        ...event,
        locationId: {},
      }));
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.7",
    to: "3.8",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise file storage
      newSchema["fileStorage"] = {};
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.8",
    to: "3.9",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise file storage
      newSchema["event_link_groups"] = {};
      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.9",
    to: "3.10",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise entities
      newSchema["events"] = newSchema["events"].map((event) => ({
        ...event,
        link_group: 0,
      }));

      return newSchema;
    },
    down: noDownGrades,
  },
  {
    from: "3.10",
    to: "3.11",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise entities
      newSchema["events"] = newSchema["events"].map((event) => {
        const newEvent = {
          ...event,
          linkGroup: event.link_group,
        };
        delete newEvent["link_group"];
        return newEvent;
      });

      return newSchema;
    },
    down: noDownGrades,
  },

  {
    from: "3.11",
    to: "3.12",
    up: (schema) => {
      // this is a shallow copy only - we expect
      // destruction/modification of the original
      const newSchema = { ...schema };

      // initialise entities
      if (Object.keys(newSchema["event_link_groups"]).length === 0) {
        newSchema["event_link_groups"] = { 10: [] };
      }

      return newSchema;
    },
    down: noDownGrades,
  },
];

export const migrate = (schema, toVersion) => {
  const fromVersion = schema.version;
  const direction = upOrDown(fromVersion, toVersion);
  if (direction === "same") {
    return schema;
  }
  const currentMigration = migrations.find(
    (migration) => migration[direction === "up" ? "from" : "to"] === fromVersion
  );
  const newSchema = currentMigration[direction](schema);
  newSchema["version"] = currentMigration["to"]; // update version post-migration
  return migrate(newSchema, toVersion);
};

export const migrateToLatest = (schema) => {
  /* Migrate to the latest known schema version */
  if (schema === null) {
    return schema;
  }

  return migrate(schema, migrations[migrations.length - 1].to);
};
