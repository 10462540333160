import * as React from "react";
import {
  DataGrid,
  //GridToolbarContainer,
  //GridToolbarExport,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";

import {
  editMeasurement,
  deleteMeasurement,
} from "../../features/personalDatabaseSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAvailableUnits } from "../../utils/units";
import { getMeasurementIdentifier } from "../../utils/data/measurements";
import CustomDialogue from "../CustomDialogue";

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function MeasurementBrowserTable(props) {
  const myDialogue = React.useRef(null);
  const { currentMeasurementCategory } = props;
  const dispatch = useDispatch();
  const measurements = useSelector(
    (state) => state.personalDatabase.contents.measurements
  );

  const processRowUpdate = (newRow) => {
    console.log("Storing...", newRow);
    if (!newRow.note) {
      newRow.note = "";
    }
    dispatch(
      editMeasurement({
        categoryId: currentMeasurementCategory.id,
        item: newRow,
      })
    );
    newRow.id = getMeasurementIdentifier(newRow);
    return newRow;
  };

  const deleteCategoryById = (id) => () => {
    myDialogue.current
      .confirm(`Are you sure you want to delete measurement ${id}?`, [
        "It will be removed from all parts of the app.",
      ])
      .then(() => {
        console.log("Deleting ", id);
        dispatch(
          deleteMeasurement({
            categoryId: currentMeasurementCategory.id,
            id: id,
          })
        );
      })
      .catch((response) => {
        console.log("Cancelled - not deleting", id);
      });
  };

  const columns = [
    {
      field: "date",
      // type: "dateTime",
      headerName: "Date",
      width: 140,
      sortable: true,
      valueGetter: ({ value }) => value && Moment(value).format("DD/MM/YYYY"),
      // valueSetter: setFullName,
    },
    {
      field: "value",
      headerName: "Value",
      editable: true,
      width: 150,
      type: "number",
      sortable: false,
    },

    {
      field: "unit",
      headerName: "Unit",
      editable: true,
      width: 80,
      sortable: false,
      type: "singleSelect",
      valueOptions: getAvailableUnits(currentMeasurementCategory.type),
    },

    {
      field: "note",
      headerName: "Note",
      editable: true,
      flex: 1,
      minWidth: 150,
      sortable: false,
    },

    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={deleteCategoryById(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error);
    console.log(error.message);
    alert(error.message);
  }, []);

  const processRows = (rows) => {
    const rowsDisplay = [];
    if (!rows) {
      return [];
    }
    for (var i = 0; i < rows.length; i++) {
      const itemRepr = getMeasurementIdentifier(rows[i]);
      // const hash = await sha256(itemRepr);
      // console.log(itemRepr);
      // console.log(hash);
      rowsDisplay.push({
        ...rows[i],
        id: itemRepr,
      });
    }
    return rowsDisplay;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={processRows(measurements[currentMeasurementCategory.id])}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        // checkboxSelection
        // components={{ Toolbar: CustomToolbar }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
      <CustomDialogue ref={myDialogue} />
    </div>
  );
}
