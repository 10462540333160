import React, { useEffect, useState } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import _uniqueId from "lodash/uniqueId";
import { useSelector } from "react-redux";
import * as _ from "lodash";

export default function MeasurementsCategorySelector(props) {
  const { currentMeasurement, setCurrentMeasurementCategory } = props;

  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );

  //   const measurementCategories = useSelector(
  //     (state) => state.personalDatabase.contents.measurementCategories
  //   );

  const [id] = useState(_uniqueId("measurement-category-select-"));

  // const loadElements = () => {
  //   setElements(dataExamples.slice(currentWindowStart, currentWindowEnd));
  // };

  // useEffect(() => {
  //   loadElements();
  // }, []);

  const pageStyle = {};

  const onChange = (e) => {
    console.log("Changed");
    setCurrentMeasurementCategory(e.target.value);
  };

  return (
    <>
      {personalDatabaseContents === null && <>No database loaded.</>}
      {personalDatabaseContents && (
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel id={`${id}-inputlabel`}>Measurement Category</InputLabel>
          <Select
            labelId={`${id}-inputlabel`}
            // margin="dense"
            label="Measurement Type"
            type="text"
            variant="standard"
            onChange={onChange}
            required
            value={currentMeasurement}
          >
            {/* <MenuItem value="">Please select</MenuItem> */}
            {_.sortBy(personalDatabaseContents.measurementCategories, [
              function (o) {
                return o.name.toLowerCase();
              },
            ]).map((measurementCategory) => (
              <MenuItem
                value={measurementCategory.id}
                key={measurementCategory.id}
              >
                {measurementCategory.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
