import { useRef, useState, useEffect } from "react";
// material
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Link,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import { trigger } from "../../utils/events";

import { Typography } from "@mui/material";
import { useSelector, useStore, useDispatch } from "react-redux";

import assetService from "../../services/auth/asset.service";
import recordsService from "../../services/auth/records.service";

import { setEditLock, setDirty } from "../../features/personalDatabaseSlice";
import { encryptObject } from "../../utils/data/crypto";
import { useSnackbar } from "notistack";
import Moment from "moment";
// import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomDialogue from "src/components/CustomDialogue";

export default function AutoSaveControl() {
  const myDialogue = useRef(null);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [lastSaveDate, setLastSaveDate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const store = useStore();
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [isActive, setIsActive] = useState(true); //auto-save active

  // cannot use useSelector as we need to access in the scope of the timer
  // const personalDatabaseContents = useSelector(
  //   (state) => state.personalDatabase.contents
  // );
  const dbPassword = useSelector(
    (state) => state.personalDatabase.encryptionPassword
  );
  const sessionActive = useSelector((state) => state.loginState.sessionActive);
  const dirtyData = useSelector((state) => state.personalDatabase.dirty);

  const record = useSelector((state) => state.personalDatabase.currentRecord);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        console.log("Auto-saving...");
        handleDBSave(true);
      }, 3 * 60 * 1000); // every 3 minutes
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive]);

  const handleDBSave = (automated) => {
    // store the DB
    setIsSaving(true);

    const verifyManualAction = (force) => {
      if (automated !== true && (!isActive || force)) {
        // manual saving handling
        myDialogue.current
          .confirm("Record may be edited from another browser/client.", [
            "Saving your changes will overwrite their changes and set you as an editor.",
            "Would you like to continue?",
          ])
          .then(() => {
            // obtain edit lock
            recordsService
              .obtainEditLock(record.id)
              .then(({ data }) => {
                dispatch(setEditLock(data));
                setIsActive(true); // re-enable auto save
              })
              .catch((error) => {
                console.log(error);
                enqueueSnackbar(
                  "Unable to obtain edit lock. Other editing client may overwrite your changes.",
                  {
                    variant: "error",
                  }
                );
              });
            return true;
          })
          .catch((response) => {
            setIsSaving(false);
            return false;
          });
      }

      return false;
    };

    const hasAlreadyVerified = verifyManualAction(false);

    const state = store.getState();
    const personalDatabaseContents = state.personalDatabase.contents;

    const saveRecords = () => {
      assetService
        .update(record.id, record.primary, {
          // dob: data.dateOfBirth.toISOString(),
          asset: encryptObject(
            personalDatabaseContents,
            dbPassword + record.password_salt
          ),
        })
        .then(({ data }) => {
          console.log(data);
          setLastSaveDate(Moment());

          setIsSaving(false);
          dispatch(setDirty(false));
          if (automated !== true) {
            enqueueSnackbar("Record has been saved", {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(
            "Unable to update record - your changes may not be saved.",
            {
              variant: "error",
            }
          );
          setIsSaving(false);

          // fetch me now, to see if the session is still active
          trigger("me:fetchMeNow");
        });
    };

    // now obtain edit lock and show record
    recordsService
      .refreshEditLock(record.id, state.personalDatabase.editLock)
      .then(({ data }) => {
        saveRecords();
      })
      .catch((error) => {
        console.log(error);

        if (automated === true) {
          enqueueSnackbar(
            "Disabling auto-save - edit lock lock expired/held by another user.",
            {
              variant: "error",
            }
          );
          setIsActive(false);
          setIsSaving(false);
        } else {
          console.log("Button click", isActive);
          // button click - save anyway, overriding and obtaining edit lock
          if (hasAlreadyVerified || (isActive && verifyManualAction(true))) {
            saveRecords();
            setIsActive(true);
          } else {
            setIsActive(false);
            setIsSaving(false);
          }
        }
      });
  };

  return (
    <>
      {!sessionActive && (
        <Typography
          variant="body2"
          sx={{ py: 1, px: 2.5, color: (theme) => theme.palette.grey[800] }}
        >
          Offline mode - please{" "}
          <Link href="/login" target="_blank">
            log in
          </Link>{" "}
          and then come back to this tab to resume saving.
        </Typography>
      )}
      {sessionActive && dirtyData && (
        <Typography
          variant="body2"
          sx={{ py: 1, px: 1, color: (theme) => theme.palette.grey[800] }}
        >
          Unsaved changes present
        </Typography>
      )}
      {sessionActive && !isActive && (
        <Typography
          variant="body2"
          sx={{ py: 1, px: 2.5, color: (theme) => theme.palette.grey[800] }}
        >
          Auto-save disabled
        </Typography>
      )}
      {isSaving && <CircularProgress color="primary" size={20} />}
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Iconify
          icon="eva:save-fill"
          sx={{
            mr: 1,
            ml: 1,
            width: 24,
            height: 24,
          }}
        />
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: "250px" }}
      >
        <Box sx={{ py: 1 }}>
          <Typography variant="body2" sx={{ py: 1, px: 2.5 }}>
            {!isActive && <>Concurrent editing detected</>}
            {isActive && (
              <>
                Auto-save enabled <br />
              </>
            )}
            {isActive && lastSaveDate && (
              <>Last saved: {Moment(lastSaveDate).fromNow()}</>
            )}
            {isActive && !lastSaveDate && <>Not saved yet.</>}
          </Typography>
          <MenuItem
            disabled={isSaving}
            onClick={handleDBSave}
            sx={{ py: 1, px: 2.5 }}
          >
            <ListItemIcon>
              <Iconify
                icon="eva:save-fill"
                sx={{
                  // mr: 1,
                  // ml: 1,
                  width: 24,
                  height: 24,
                }}
              />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: "body2" }}>
              Save now
            </ListItemText>
          </MenuItem>
        </Box>
      </MenuPopover>
      <CustomDialogue ref={myDialogue} />
    </>
  );
}
