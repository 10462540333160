import HTTP from "../http";

function create(data) {
  const fd = new FormData();
  for (var key in data) {
    if (key == "asset") {
      // console.log(data[key]);
      fd.append(key, new File([data[key]], "asset"), "asset");
    } else {
      fd.append(key, data[key]);
    }
  }

  return HTTP.post(`/api/v1/records/create/`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function update(recordsId, data) {
  const fd = new FormData();
  for (var key in data) {
    if (key == "asset") {
      throw new Error("This is now how records data are updated");
    } else {
      fd.append(key, data[key]);
    }
  }

  return HTTP.put(`/api/v1/records/${recordsId}/update/`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function destroy(recordsId) {
  return HTTP.delete(`/api/v1/records/${recordsId}/delete/`);
}

/**
 */
function obtainEditLock(recordsId) {
  return HTTP.post(`/api/v1/records/${recordsId}/lock/`);
}

/**
 */
function refreshEditLock(recordsId, editLock) {
  return HTTP.put(`/api/v1/records/${recordsId}/lock/${editLock}/`);
}

/**
 * Destroy the user with the provided id via the API.
 * @param {string|number} id
 */
function releaseEditLock(recordsId, editLock) {
  return HTTP.delete(`/api/v1/records/${recordsId}/lock/${editLock}/`);
}

export default {
  create,
  update,
  destroy,
  obtainEditLock,
  refreshEditLock,
  releaseEditLock,
  //   find,
  //   update,
  //   verifyPassword,
  //   changePassword,
};
