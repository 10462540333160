import Typography from "@mui/material/Typography";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";

import TimelineCard from "../VerticalTimelineItemCard.js";

import { styled } from "@mui/material/styles";

const RoundIcon = styled("div")(({ theme }) => ({
  width: 75,
  height: 75,
  margin: "-22px auto 15px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiSvgIcon-root": {
    fontSize: 47,
    color: theme.palette.common.white,
  },
}));

export default function BirthdayTimelineItem(props) {
  const { event, scrollToIndex, showAddOrEditEventDialogue } = props;

  return (
    <TimelineCard
      event={event}
      scrollToIndex={scrollToIndex}
      showAddOrEditEventDialogue={showAddOrEditEventDialogue}
    >
      <RoundIcon>
        <CakeRoundedIcon />
      </RoundIcon>

      <Typography
        sx={{ fontSize: "32px", fontWeight: "700", textAlign: "center" }}
      >
        {event.name}
      </Typography>
    </TimelineCard>
  );
}
