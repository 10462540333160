// material
import { Box, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import PropTypes from "prop-types";

import React, { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useSelector } from "react-redux";

import CategoryTable from "../components/categoriesAndTags/CategoryTable";
import TagTable from "../components/categoriesAndTags/TagTable";

import MeasurementCategoryTable from "../components/measurements/MeasurementCategoryTable";
import _uniqueId from "lodash/uniqueId";

export default function CategoriesAndTags() {
  //const dispatch = useDispatch();

  const personalDatabaseContents = useSelector(
    (state) => state.personalDatabase.contents
  );

  const pageStyle = {};

  const [value, setValue] = useState(0);
  const [id] = useState(_uniqueId("categoriesandtabs-"));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`${id}-simple-tabpanel-${index}`}
        aria-labelledby={`${id}-simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `${id}-simple-tab-${index}`,
      "aria-controls": `${id}-simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Manage Categories &amp; Tags | Holistory" style={pageStyle}>
      <Container maxWidth="xl">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Event Categories, Measurement Categories, Tags"
            >
              <Tab label="Event Categories" {...a11yProps(0)} />
              <Tab label="Measurement Categories" {...a11yProps(1)} />
              <Tab label="Tags" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Event Categories</Typography>
            </Box>

            {personalDatabaseContents === null && (
              <div>
                No database loaded. <br />
                <br />
                <br />
              </div>
            )}

            {personalDatabaseContents && (
              <>
                <CategoryTable />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Measurement Categories</Typography>
            </Box>

            {personalDatabaseContents === null && (
              <div>
                No database loaded. <br />
                <br />
                <br />
              </div>
            )}

            {personalDatabaseContents && (
              <>
                <MeasurementCategoryTable />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Tags</Typography>
            </Box>

            {personalDatabaseContents === null && (
              <div>
                No database loaded. <br />
                <br />
                <br />
              </div>
            )}

            {personalDatabaseContents && (
              <>
                <TagTable />
              </>
            )}
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}
