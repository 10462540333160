import CryptoJS from "crypto-js";

import { getFileDownload } from "../browser";
import assetService from "../../services/auth/asset.service";

export const downloadAssetAndCallFun = (
  currentRecord,
  attachment,
  enqueueSnackbar,
  callback,
  getThumbnail = false
) => {
  console.log("Trying to get attachment", attachment);

  // let's try downloading and decrypting the db!
  //setLoaderOpen(true);
  return new Promise(function (resolve, reject) {
    const idToFetch =
      attachment.thumbnailId && getThumbnail
        ? attachment.thumbnailId
        : attachment.id;
    assetService
      .download(currentRecord.id, idToFetch)
      .then((response) => {
        //setLoaderOpen(false);

        console.log("File downloaded");
        console.log(response);

        const data = CryptoJS.AES.decrypt(
          response.data,
          attachment.perFilePassword
        );
        //const dataString = data.toString(CryptoJS.enc.Utf8);
        const dataStringBase64 = data.toString(CryptoJS.enc.Base64);
        //   console.log(data);
        //console.log("Decrypted data (utf8)", dataString);
        //   console.log(
        //     "Decrypted data (base64)",
        //     typeof dataStringBase64,
        //     dataStringBase64
        //   );

        callback(dataStringBase64, attachment.fileName);
        resolve();
      })
      .catch((response) => {
        //setLoaderOpen(false);
        console.log(response);
        console.log(attachment);
        if (enqueueSnackbar) {
          enqueueSnackbar(`Unable to load ${attachment.fileName}`, {
            variant: "error",
          });
        }
        reject(response);
      });
  });
};

export const downloadAsset = (currentRecord, attachment, enqueueSnackbar) => {
  return downloadAssetAndCallFun(
    currentRecord,
    attachment,
    enqueueSnackbar,
    getFileDownload
  );
};
