import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import InitDatabase from "./pages/InitDatabase";

import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
// import TimelineApp from "./pages/TimelineApp";
import MeasurementsApp from "./pages/MeasurementsApp";
import ReportsApp from "./pages/ReportsApp";
import PersonalDatabase from "./pages/PersonalDatabase";
import CategoriesAndTags from "./pages/CategoriesAndTags";
import CustomTimeline from "./pages/Timeline";
import Entities from "./pages/Entities";

import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        // { path: "timeline", element: <TimelineApp /> },
        { path: "timeline", element: <CustomTimeline /> },
        { path: "measurements", element: <MeasurementsApp /> },
        { path: "reports", element: <ReportsApp /> },
        // { path: "user", element: <User /> },
        // { path: "products", element: <Products /> },
        // { path: "blog", element: <Blog /> },
      ],
    },

    {
      path: "/profile",
      element: <DashboardLayout />,
      children: [
        { path: "database", element: <PersonalDatabase /> },
        { path: "categories-tags", element: <CategoriesAndTags /> },
        { path: "entities", element: <Entities /> },
      ],
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard/timeline" /> },
        { path: "login", element: <Login /> },
        { path: "init", element: <InitDatabase /> },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
