import { useEffect, useMemo } from "react";
import { useStore } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { RegisterForm } from "../sections/authentication/register";
import AuthSocial from "../sections/authentication/AuthSocial";
import * as _ from "lodash";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const store = useStore();
  const navigate = useNavigate();

  const sessionActive = useSelector((state) => state.loginState.sessionActive);
  const loadingLoginState = useSelector((state) => state.loginState.loading);

  const handleStoreUpdate = function () {
    console.log("Store has updated (registration page)!");
    const state = store.getState();
    console.log(state);
    // do we have an active user?
    if (state && state.loginState && state.loginState.sessionActive) {
      console.log("Detected active user, redirecting...");
      navigate("/init");
    }
  };

  const THROTTLE_DELAY = 2000;
  const handleStoreUpdateThrottled = useMemo(
    () =>
      _.throttle(() => {
        handleStoreUpdate();
      }, THROTTLE_DELAY),
    [] //
  );

  useEffect(() => {
    // Anything in here is fired on component mount.
    const unsubscribe = store.subscribe(handleStoreUpdateThrottled);

    // check just in case
    handleStoreUpdateThrottled();

    return () => {
      // Anything in here is fired on component unmount.
      unsubscribe();
      try {
        handleStoreUpdateThrottled.cancel();
      } catch (e) {
        console.log("Unable to cancel: ", e);
      }
    };
  }, []);

  return (
    <RootStyle title="Register | Holistory">
      {!sessionActive && !loadingLoginState && (
        <>
          <AuthLayout>
            Already have an account? &nbsp;
            <Link
              underline="none"
              variant="subtitle2"
              component={RouterLink}
              to="/login"
            >
              Login
            </Link>
          </AuthLayout>

          {/* <SectionStyle sx={{ display: { xs: "none", md: "flex" } }}>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Create your account
        </Typography>
      </SectionStyle> */}

          <Container>
            <ContentStyle>
              <Box sx={{ mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                  Get started.
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Enter your details below or use a third-party provider.
                </Typography>
              </Box>

              <AuthSocial />

              <RegisterForm />

              <Typography
                variant="body2"
                align="center"
                sx={{ color: "text.secondary", mt: 3 }}
              >
                By registering, I agree to State&nbsp;
                <Link underline="always" color="textPrimary">
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" color="textPrimary">
                  Privacy Policy
                </Link>
                .
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{
                  mt: 3,
                  textAlign: "center",
                  display: { sm: "none" },
                }}
              >
                Already have an account?&nbsp;
                <Link underline="hover" to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            </ContentStyle>
          </Container>
        </>
      )}
    </RootStyle>
  );
}
