import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import { styled } from "@mui/material/styles";

const TimelineDividerStyled = styled("div")(({ theme }) => ({
  "& > .MuiButtonBase-root": {
    backgroundColor: "rgba(145, 158, 171, 0.12);",
    width: "36px",
    height: "36px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  "&::before, &::after": {
    content: '""',
    display: "block",
    width: "2px",
    height: "67px",
    backgroundColor: "rgba(145, 158, 171, 0.24);",
    margin: "8px auto 0",
  },
  "&::before": {
    marginBottom: "11px",
  },
}));

export default function TimelineDivider(props) {
  const { event, showAddOrEditEventDialogue } = props;
  return (
    <TimelineDividerStyled>
      <IconButton
        aria-label="add event"
        onClick={() => showAddOrEditEventDialogue(event)}
      >
        <AddIcon sx={{ fontSize: "28px", color: "#000" }} />
      </IconButton>
    </TimelineDividerStyled>
  );
}
