import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Card, IconButton, Typography, CardContent } from "@mui/material";
// utils
import { fDate } from "../../../utils/formatTime";
import cssStyles from "../../../utils/cssStyles";
// components
import Image from "../../../components/Image";
import Iconify from "../../../components/Iconify";
import LightboxModal from "../../../components/LightboxModal";
import {
  downloadAsset,
  downloadAssetAndCallFun,
} from "../../../utils/data/attachments";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
  bottom: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  justifyContent: "space-between",
  color: theme.palette.common.white,
}));

// ----------------------------------------------------------------------

MultimediaGallery.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default function MultimediaGallery({ gallery }) {
  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const imagesLightbox = gallery.map((img) => img.imageUrl);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };
  return (
    <Box sx={{ mt: 1 }}>
      {/* <Typography variant="h4" sx={{ mb: 3 }}>
        Gallery
      </Typography> */}

      <Box
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          },
        }}
      >
        {gallery
          .filter((attachment) => attachment.isThumbnail === false)
          .map((attachment) => (
            <GalleryItem
              key={attachment.id}
              attachment={attachment}
              onOpenLightbox={handleOpenLightbox}
            />
          ))}
      </Box>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

function GalleryItem({ attachment, onOpenLightbox }) {
  const { id, fileName } = attachment;
  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSource, setImageSource] = useState("");

  const loadImage = () => {
    downloadAssetAndCallFun(
      currentRecord,
      attachment,
      null,
      (dataStringBase64, fileName) => {
        let prefix = "";
        if (attachment.thumbnailId) {
          // thumbnails are always JPEGs
          prefix = "data:image/jpeg;base64,";
        } else if (fileName.endsWith(".jpg") || fileName.endsWith(".jpg")) {
          prefix = "data:image/jpeg;base64,";
        } else if (fileName.endsWith(".png")) {
          prefix = "data:image/png;base64,";
        } else {
          // thumbnails are always jpegs
          prefix = "data:image/jpeg;base64,";
        }
        setImageSource(prefix + dataStringBase64);
        setImageLoaded(true);
      },
      true // getThumbnail
    );
  };

  useEffect(() => {
    loadImage();
  }, [attachment.id]);

  return (
    <Card sx={{ cursor: "pointer", position: "relative" }}>
      {imageLoaded && (
        <Image
          alt={`Image for "${id}"`}
          ratio="1/1"
          src={imageSource}
          // onClick={() => onOpenLightbox(imageUrl)} // lightbox seems to be broken
          onClick={(id) => {
            downloadAsset(currentRecord, attachment);
          }}
        />
      )}

      {!imageLoaded && (
        <Image
          alt={`Loading image for "${id}"`}
          ratio="1/1"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOMOQkAAYcBJ/ui4IMAAAAASUVORK5CYII="
          // onClick={() => onOpenLightbox(imageUrl)} // lightbox seems to be broken
          onClick={(id) => {
            downloadAsset(currentRecord, attachment);
          }}
        />
      )}

      <CaptionStyle>
        <div>
          <Typography variant="subtitle1">{fileName}</Typography>
          {/* <Typography variant="body2" sx={{ opacity: 0.72 }}>
            {fDate(postAt)}
          </Typography> */}
        </div>
        {/* <IconButton color="inherit">
          <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
        </IconButton> */}
      </CaptionStyle>
    </Card>
  );
}
