import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
// @mui
import { Box, Grid, Card, Button, Typography } from "@mui/material";
// components
import Iconify from "../Iconify";
import { createSvgIcon } from "@mui/material/utils";
import { styled } from "@mui/material/styles";
// import PedalBikeIcon from "@mui/icons-material/PedalBike";
import { useSnackbar } from "notistack";
import assetService from "../../services/auth/asset.service";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import Moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import _uniqueId from "lodash/uniqueId";
import recordsService from "../../services/auth/records.service";
import { trigger } from "../../utils/events";
import * as _ from "lodash";
import RecordAvatar from "../RecordAvatar";
import { useSelector } from "react-redux";

import {
  setName,
  setDatabaseContents,
  setEncryptionPassword,
  setCurrentRecord,
  setEditLock,
} from "../../features/personalDatabaseSlice";
// import { getAvatarSVGSrc } from "../../utils/images";
import CustomDialogue from "../CustomDialogue";

// ----------------------------------------------------------------------

Records.propTypes = {
  records: PropTypes.array,
};

export default function Records({ records, setLoaderOpen }) {
  const reloading = useSelector((state) => state.uiState.reloading);

  // this loader is put up when closing existing record,
  // right before window refresh is initiated. It is to indicate
  // that the UI is not ready to the user
  useEffect(() => {
    if (reloading) {
      setLoaderOpen(true);
    }
  }, [reloading]);

  return (
    <Grid container spacing={2}>
      {records.length == 0 && (
        <Grid item xs={12} md={12}>
          <Typography variant="body2" noWrap>
            No record found.
          </Typography>
        </Grid>
      )}
      {records.length > 0 &&
        _.sortBy(records, [
          function (o) {
            return o.name.toLowerCase();
          },
        ]).map((record) => (
          <Grid key={record.id} item xs={12} md={12}>
            <RecordsCard record={record} setLoaderOpen={setLoaderOpen} />
          </Grid>
        ))}
    </Grid>
  );
}

// ----------------------------------------------------------------------

const InjuryIcon = createSvgIcon(
  <path d="M8 6C8 3.79 9.79 2 12 2S16 3.79 16 6 14.21 10 12 10 8 8.21 8 6M17 22H18C19.1 22 20 21.1 20 20V15.22C20 14.1 19.39 13.07 18.39 12.56C17.96 12.34 17.5 12.13 17 11.94V22M12.34 17L15 11.33C14.07 11.12 13.07 11 12 11C9.47 11 7.29 11.7 5.61 12.56C4.61 13.07 4 14.1 4 15.22V22H6.34C6.12 21.55 6 21.04 6 20.5C6 18.57 7.57 17 9.5 17H12.34M10 22L11.41 19H9.5C8.67 19 8 19.67 8 20.5S8.67 22 9.5 22H10Z" />,
  "Injury"
);

const HospitalIcon = createSvgIcon(
  <path fill="currentColor" d="M18 14H14V18H10V14H6V10H10V6H14V10H18" />,
  "Appointment"
);

const FollowerIconTMP = styled(Box)(({ theme }) => ({
  padding: 0,
  "& .MuiCardHeader-avatar": {
    marginRight: "18px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
    },
  },
  "& .MuiCardHeader-title, & .MuiCardHeader-subheader": {
    display: "inline-block",
    fontSize: "16px",
    fontWeight: "600",
  },
  "& .MuiCardHeader-title": {
    color: theme.palette.grey[600],
  },
  "& .MuiCardHeader-subheader": {
    color: theme.palette.grey[800],
    borderLeft: `1px solid ${theme.palette.grey[600]}`,
    paddingLeft: "5px",
    marginLeft: "5px",
  },
}));

RecordsCard.propTypes = {
  record: PropTypes.object,
};

function RecordsCard({ record, setLoaderOpen }) {
  const { name, created_at, dob, id, primary } = record;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const myDialogue = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRecord = (record) => {
    handleClose();

    myDialogue.current
      .prompt(
        `Are you sure you want to delete record ${name}?`,
        ["THIS ACTION CANNOT BE UNDONE."],
        "Type DELETE to proceed."
      )
      .then((answer) => {
        if (answer === "DELETE") {
          recordsService
            .destroy(id)
            .then(({ data }) => {
              // force-refresh ME?
              console.log(data);

              enqueueSnackbar("Record has been deleted", {
                variant: "success",
              });

              trigger("me:fetchMeNow");
            })
            .catch((error) => {
              console.log(error);
              enqueueSnackbar("Unable to delete records", {
                variant: "error",
              });
            });
        } else {
          console.log("Cancelled - not deleting", id);
          enqueueSnackbar(`Your answer did not match DELETE`, {
            variant: "error",
          });
        }
      })
      .catch((response) => {
        console.log("Cancelled - not deleting", id);
      });
  };

  const downloadAsset = (record) => {
    const downloadAssetDialogue = function (error) {
      myDialogue.current
        .prompt("Record locked", ["Please provide a password"], "Password", {
          fieldType: "password",
          errorMessage: error,
        })
        .then((dbPassword) => {
          // let's try downloading and decrypting the db!
          setLoaderOpen(true);

          assetService
            .download(record.id, record.primary)
            .then((response) => {
              console.log("File downloaded");
              console.log(response);

              const data = CryptoJS.AES.decrypt(
                response.data,
                dbPassword + record.password_salt
              );
              const dataString = data.toString(CryptoJS.enc.Utf8);
              console.log(data);
              console.log(dataString);

              // any migrations to apply?
              let schema = JSON.parse(dataString);
              // schema = migrateToLatest(schema); // now done inside of the reducer

              // now obtain edit lock and show record
              recordsService
                .obtainEditLock(record.id)
                .then(({ data }) => {
                  dispatch(setEncryptionPassword(dbPassword)); // for later reference / auto-encryption
                  dispatch(setEditLock(data));
                  dispatch(setCurrentRecord(record));
                  dispatch(setName(record.name));
                  dispatch(setDatabaseContents(schema));
                })
                .catch((error) => {
                  console.log(error);
                  setLoaderOpen(false);
                  downloadAssetDialogue(
                    "Unable to obtain edit lock and load records. Please try again later."
                  );
                });
            })
            .catch((response) => {
              setLoaderOpen(false);
              downloadAssetDialogue(`Unable to load record ${record.name}.`);
            });
        })
        .catch((response) => {
          console.log("Not continuing");
        });
    };
    downloadAssetDialogue();
  };

  // const [toggle, setToogle] = useState(isFollowed);

  const [elementId] = useState(_uniqueId("recordscard-"));

  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          p: 3,
          boxShadow:
            "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        }}
      >
        {/* <Avatar alt={name} src={getAvatarSVGSrc(record.id)} variant="rounded" /> */}

        <RecordAvatar
          record={record}
          sx={{
            width: 48,
            height: 48,
            backgroundColor: "rgba(145, 158, 171, 0.12)",
          }}
        />
        <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          {dob && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Iconify
                icon={"eva:calendar-fill"}
                sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }}
              />
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {Moment(dob).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          )}
        </Box>
        <Button
          size="small"
          onClick={() => downloadAsset(record)}
          variant="contained"
          color="primary"
        >
          Select
        </Button>

        <Button
          id={elementId + "-button"}
          aria-controls={open ? elementId + "-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            minWidth: 8,
          }}
        >
          <Iconify
            icon={"eva:more-vertical-fill"}
            sx={{ width: 16, height: 16, flexShrink: 0 }}
          />
        </Button>
        <Menu
          id={elementId + "-menu"}
          aria-labelledby={elementId + "-button"}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => deleteRecord(record)} sx={{ color: "red" }}>
            {"Delete "}
            <Iconify
              icon={"eva:trash-2-fill"}
              sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
            />
          </MenuItem>
        </Menu>
      </Card>
      <CustomDialogue ref={myDialogue} />
    </>
  );
}
