//import { initialiseEvents } from "events";

export const generateFreshDatabase = function (name, dateOfBirth) {
  const newDatabaseContents = {
    version: "3.4",
    categories: [],
    tags: [],
    events: [],
    measurementCategories: [],
    measurements: {},
    metadata: {
      dateOfBirth: dateOfBirth.toISOString(),
      name: name,
    },
  };

  // in-place modify
  //initialiseEvents(newDatabaseContents);

  return newDatabaseContents;
};

const nameAssignFunction = (acc, curr) => {
  acc[curr.name] = curr;
  return acc;
};

const generateFetchByName = (obj) => {
  return (name) => {
    /* Lookup by its name */
    if (obj.hasOwnProperty(name)) {
      return obj[name];
    } else {
      throw new Error(`${name} is not present name.`);
    }
  };
};

export const genLookupObjAndFun = function (collection) {
  const objFromCollection = collection.reduce(nameAssignFunction, {});
  const fetchByName = generateFetchByName(objFromCollection);

  return [objFromCollection, fetchByName];
};
