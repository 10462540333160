// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGBLocale from "date-fns/locale/en-GB";

import NotistackProvider from "./components/NotistackProvider";
// ----------------------------------------------------------------------

import { useEffect, useRef } from "react";
import authService from "./services/auth/auth.service";
import userService from "./services/auth/user.service";

import { useDispatch } from "react-redux";
import {
  storeCSRFToken,
  storeUser,
  setSessionAsInactive,
} from "./features/loginStateSlice";

import Debug from "./components/Debug";

import { on, off } from "./utils/events";
import Moment from "moment";
import { compileSchema } from "ajv/dist/compile";

const DEFAULT_ME_POLLING_RATE = 60; // 60 seconds

// indexdb purge
const check_and_clean_indexeddb = function () {
  /*
  Global function to check if databases are still active
  */

  const prefix = "state-keepdb-";

  // determine what to keep
  const dbsToKeep = [];
  const localStorageToRemove = [];
  const now = Moment();
  for (var i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, prefix.length) === prefix) {
      const key = localStorage.key(i);
      const val = localStorage.getItem(key);

      const parsedVal = Moment(val);
      window.leak = parsedVal;
      const diffMillis = now.diff(parsedVal);
      console.log("Checking", key, val, diffMillis);
      // let's say 60s validity
      if (diffMillis < 60 * 1000) {
        console.log("Keeping", key);

        // get DB name
        const dbName = key.substring(prefix.length);
        dbsToKeep.push(dbName);
      } else {
        console.log("Not keeping", key);
        localStorageToRemove.push(key);
      }

      console.log();
    }
  }

  // clean up any expired localstorage keys
  localStorageToRemove.map((localKey) => {
    localStorage.removeItem(localKey);
  });

  // now, let's go through the indexDBs
  const dbPrefix = "level-js-state";

  indexedDB
    .databases()
    .then((databases) => {
      console.log(databases);
      databases.map((database) => {
        if (database.name.substring(0, dbPrefix.length) === dbPrefix) {
          // we have a DB that is under our control
          if (!dbsToKeep.includes(database.name)) {
            // remove
            console.log("Removing", database.name);

            const req = indexedDB.deleteDatabase(database.name);
            req.onsuccess = function () {
              console.log("Deleted database successfully");
            };
            req.onerror = function () {
              console.log("Couldn't delete database");
            };
            req.onblocked = function () {
              console.log(
                "Couldn't delete database due to the operation being blocked"
              );
            };

            // // clean up the index
            // const request = indexedDB.open(database.name, 4);
            // request.onsuccess = (event) => {
            //  console.log("DB open, removing schema");
            //  const db = request.result;

            //  db.deleteObjectStore("")
            // }
          }
        }
      });
    })
    .catch((e) => {
      console.log("Unable to list databases", e);
    });
};
window.check_and_clean_indexeddb = check_and_clean_indexeddb;

window.addEventListener(
  "beforeunload",
  function (e) {
    console.log("Purging dbs before tab closing...");
    check_and_clean_indexeddb();
  },
  false
);

export default function App() {
  const dispatch = useDispatch();

  const csrfFetchTimer = useRef(null);
  const meUpdateTimer = useRef(null);

  useEffect(() => {
    let fetched = false;
    const fetchTokenFun = () => {
      authService
        .fetchToken()
        .then(({ data }) => {
          console.log("CSRF token fetched");
          fetched = true;
          dispatch(storeCSRFToken(data));
        })
        .catch((error) => {
          if (!fetched) {
            alert(
              "We were not able to contact the backend. Please reload the page and try again."
            );
          } else {
            console.log("CSRF Token fetch failed", error);

            // retry in 120s again
            setTimeout(fetchTokenFun, 120 * 1000);
          }
        });
    };

    const fetchMeInfo = () => {
      userService
        .me()
        .then(({ data }) => {
          console.log("USER LOGGED IN", data);

          dispatch(storeUser(data));

          // user logged in - refresh CSRF for good measure
          restorePollingInterval(); // restore polling interval back to 1m
          fetchTokenFun();
        })
        .catch((error) => {
          dispatch(setSessionAsInactive());

          if (error.response.status === 403) {
            // not logged in
            console.log("User not logged in");
            // TODO set flags accordingly
          } else {
            console.log(error);
            alert("Unable to fetch login info. We will try again later.");
          }
        });
    };

    // re-fetch token every 5 minutes
    csrfFetchTimer.current = setInterval(() => {
      fetchTokenFun();
    }, 5 * 60 * 1000);

    // fetch immediately after load
    fetchTokenFun();

    // re-fetch logged in user info every minute
    const destryAndCreatePollingForMe = (seconds) => {
      clearInterval(meUpdateTimer.current);

      // re-fetch token every 5 minutes
      meUpdateTimer.current = setInterval(() => {
        fetchMeInfo();
      }, seconds * 1000);
    };

    destryAndCreatePollingForMe(DEFAULT_ME_POLLING_RATE); // every minute

    // immediately fetch
    fetchMeInfo();

    const increasePollingInterval = () => {
      console.log("Increasing polling");
      destryAndCreatePollingForMe(2); // every 2 seconds
    };
    const restorePollingInterval = () => {
      console.log("Restoring polling");
      destryAndCreatePollingForMe(DEFAULT_ME_POLLING_RATE); // back to the default polling rate
    };

    on("me:increasePollingInterval", increasePollingInterval);
    on("me:restorePollingInterval", restorePollingInterval);
    on("me:fetchMeNow", fetchMeInfo);

    // clean up after itself
    return () => {
      //
      off("me:increasePollingInterval", increasePollingInterval);
      off("me:restorePollingInterval", restorePollingInterval);
      off("me:fetchMeNow", fetchMeInfo);
      clearInterval(csrfFetchTimer.current);
      clearInterval(meUpdateTimer.current);
    };
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={enGBLocale}
    >
      <ThemeConfig>
        <NotistackProvider>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router />
          <Debug />
        </NotistackProvider>
      </ThemeConfig>
    </LocalizationProvider>
  );
}
