import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
// material
import { styled, alpha } from "@mui/material/styles";
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
} from "@mui/material";
// component
import Iconify from "../../components/Iconify";
import SearchIndexContext from "../../contexts/SearchIndexContext";

import {
  setFilterExpanded,
  setSearchParams,
} from "../../features/uiStateSlice";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [textVal, setTextVal] = useState("");
  const dispatch = useDispatch();

  const { eventsSearchIndex } = useContext(SearchIndexContext);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleTextValChange = (event) => {
    const textValueIn = event.target.value;
    console.log(textValueIn);
    setTextVal(textValueIn);

    console.log(textValueIn);

    eventsSearchIndex.SEARCH([textValueIn]).then((res) => {
      console.log(res);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchAction = () => {
    const searchArr = textVal.split(" ");
    console.log(searchArr);
    eventsSearchIndex
      .SEARCH([
        {
          OR: searchArr,
        },
      ])
      .then((res) => {
        console.log(res);

        // set fulltextfilter
        dispatch(setFilterExpanded(true));
        dispatch(
          setSearchParams({
            search_string: textVal,
            search_ids: res.RESULT.map((rslt) => rslt._id),
          })
        );
        setOpen(false);
        setTextVal("");
      });
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}
        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              value={textVal}
              onChange={handleTextValChange}
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: "fontWeightBold" }}
            />
            <Button variant="contained" onClick={handleSearchAction}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
