import _uniqueId from "lodash/uniqueId";
import { forwardRef, useState, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OurTextField from "./forms/OurTextField";

function CustomDialogue(props, ref) {
  const [id] = useState(_uniqueId("custom-dialog-"));
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState({
    title: "",
    text: [],
    fieldLabel: "",
  });
  const [promiseVals, setPromiseVals] = useState(null);
  const [fieldType, setFieldType] = useState("text");
  const [width, setwidth] = useState("600px");

  const {
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    reset,
  } = useForm();

  useImperativeHandle(ref, () => ({
    confirm: (dialogTitle, dialogText, conf) => {
      setwidth("600px"); // reset to default
      if (conf && conf.width) {
        setwidth(conf.width);
      }
      setContent({ title: dialogTitle, text: dialogText });
      setOpen(true);
      const resp = new Promise((resolve, reject) => {
        setPromiseVals({
          reject: (message) => reject(message),
          resolve: () => resolve("Success"),
        });
      });
      return resp;
    },
    prompt: (dialogTitle, dialogText, fieldLabel, conf) => {
      setwidth("600px");
      if (conf && conf.width) {
        setwidth(conf.width);
      }
      setFieldType("text");
      if (conf && conf.fieldType === "password") {
        setFieldType("password");
      }
      if (conf && conf.errorMessage) {
        setError("myField", {
          type: "manual",
          message: conf.errorMessage,
        });
      }
      setContent({
        title: dialogTitle,
        text: dialogText,
        label: fieldLabel,
      });
      setOpen(true);

      const resp = new Promise((resolve, reject) => {
        setPromiseVals({
          reject: (message) => reject(message),
          resolve: (val) => resolve(val),
        });
      });
      return resp;
    },
  }));

  const yesButtonClick = () => {
    promiseVals.resolve();
    handleClose();
  };

  const noButtonClick = () => {
    promiseVals.reject("Cancelled");
    handleClose();
  };

  const handleClose = (event, reason) => {
    // don't close on click away
    if (reason && reason === "backdropClick") {
      return;
    }
    clearErrors();
    reset();

    setOpen(false);
    setPromiseVals(null);
  };

  const onSubmit = (data) => {
    promiseVals.resolve(data.myField);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`${id}-dialog-title`}
      aria-describedby={`${id}-dialog-description`}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: width,
          },
        },
      }}
    >
      <DialogTitle id={`${id}-dialog-title`}>{content.title}</DialogTitle>
      <DialogContent id={`${id}-dialog-description`}>
        {content.text.map((item, i) => (
          <DialogContentText key={i}>{item}</DialogContentText>
        ))}
        {content.label && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <OurTextField
              autoFocus={true}
              fieldName={"myField"}
              control={control}
              errors={errors}
              label={content.label}
              maxLength={500}
              inputType={fieldType}
              passwordAdornment={fieldType === "password" ? true : ""}
            />
            <DialogActions>
              <Button onClick={noButtonClick}>Cancel</Button>
              <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>

      {!content.label && (
        <DialogActions>
          <Button onClick={yesButtonClick} autoFocus>
            Yes
          </Button>
          <Button onClick={noButtonClick}>No</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default forwardRef(CustomDialogue);
