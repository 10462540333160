// material
import { Box, Typography } from "@mui/material";
// components

import React, { useState } from "react";

import Button from "@mui/material/Button";

import { useSelector, useDispatch } from "react-redux";

import { useSnackbar } from "notistack";

import OurFileUploadButton from "../components/forms/OurFileUploadButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { fileTypeFromBuffer } from "file-type";
import assetService from "../services/auth/asset.service";
import recordsService from "../services/auth/records.service";
import { setCurrentRecord, setDirty } from "../features/personalDatabaseSlice";
import Jimp from "jimp/es";

export default function UpdateProfilePhoto() {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const currentRecord = useSelector(
    (state) => state.personalDatabase.currentRecord
  );

  const removeCustomPhoto = () => {
    setOpen(true);

    assetService
      .destroy(currentRecord.id, currentRecord.custom_photo)
      .then(() => {
        setOpen(false);

        enqueueSnackbar("Custom records image has been removed", {
          variant: "success",
        });
        dispatch(setCurrentRecord({ ...currentRecord, custom_photo: null }));
      })
      .catch((error) => {
        setOpen(false);

        console.log(error);
        enqueueSnackbar("Unable to remove custom records image", {
          variant: "error",
        });
      });
  };

  const fileUploaded = (fileName, fileContentsBuffer) => {
    console.log("File received uploaded");

    // 1. Resize and Upload image WITHOUT encrypting it first
    //    making sure it's actually an image

    fileTypeFromBuffer(fileContentsBuffer)
      .then((detectedFileType) => {
        console.log("File type", detectedFileType);

        // TODO move this to a utility class
        const supportedImageMimes = ["image/jpeg", "image/png"];

        // text type comes back empty
        if (
          !detectedFileType ||
          !supportedImageMimes.includes(detectedFileType.mime)
        ) {
          enqueueSnackbar("Invalid file type - please use JPEG or PNG", {
            variant: "error",
          });
          setOpen(false);
        } else {
          // we should be good to go!
          // now resize the image
          Jimp.read(fileContentsBuffer)
            .then((image) => {
              // Do stuff with the image.
              const resultPromise = image
                .cover(256, 256)
                .getBufferAsync(detectedFileType.mime);

              resultPromise
                .then((resultBuffer) => {
                  console.log("Image resize success");

                  // now upload WITHOUT encrypting
                  assetService
                    .create(currentRecord.id, {
                      asset: resultBuffer,
                    })
                    .then(({ data }) => {
                      console.log("Image upload success", data);

                      // 2. Update records with the ID of the asset, which will then do the necessary
                      // validation
                      console.log(data.id);
                      console.log(data.url);

                      const customImageAssetData = data;

                      recordsService
                        .update(currentRecord.id, {
                          custom_photo: customImageAssetData.id,
                        })
                        .then(({ data }) => {
                          // force-refresh ME?
                          console.log("Updated record", data);

                          enqueueSnackbar("Custom records image has been set", {
                            variant: "success",
                          });

                          dispatch(setCurrentRecord(data));
                          setOpen(false);
                        })
                        .catch((error) => {
                          console.log(error);
                          enqueueSnackbar("Unable to set records image", {
                            variant: "error",
                          });
                          setOpen(false);
                        });
                    })
                    .catch((error) => {
                      setOpen(false);

                      console.log(error);
                      enqueueSnackbar(
                        "Unable to upload a file, please try again later.",
                        {
                          variant: "error",
                        }
                      );
                    });
                })
                .catch((err) => {
                  console.log("Image resize failed", err);
                  setOpen(false);
                });
            })
            .catch((err) => {
              // Handle an exception.
              console.log("Unable to read file:", err);
              enqueueSnackbar("Unable to process the file", {
                variant: "error",
              });
              setOpen(false);
            });
        }
      })
      .catch((e) => {
        console.log("Unable to determine file type", e);
        setOpen(false);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <OurFileUploadButton
        label="Upload custom photo"
        handleUploadedFile={fileUploaded}
        setLoaderOpen={setOpen}
      />
      {currentRecord.custom_photo && (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="outlined"
            onClick={removeCustomPhoto}
            component="div"
          >
            Remove custom photo
          </Button>
        </>
      )}
      <Box sx={{ pb: 2, pt: 2 }}>
        <Typography variant="body2">
          Custom records image is not encrypted using the records password as is
          present in un-encrypted contexts as well, for example, the records
          selection screen.
        </Typography>
      </Box>
    </>
  );
}
