import { Link as RouterLink } from "react-router-dom";
// import Logo from "../components/Logo";

// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Divider,
  Box,
} from "@mui/material";
// layouts
//import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import RestoreDatabase from "../components/RestoreDatabase";
import StartDatabase from "../components/StartDatabase";
import Records from "../components/records/Records";

import { useSelector, useDispatch, useStore } from "react-redux";
import { useEffect, useMemo } from "react";

import { generateMockDatabase } from "../utils/data/mockData";

// import * as React from "react";
import { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as _ from "lodash";
import { useSnackbar } from "notistack";
import { HeaderStyle } from "../layouts/LogoOnlyLayout";
import userService from "../services/auth/user.service";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyleContainer = styled(Box)(({ theme }) => ({
  maxHeight: "100vh",
  width: "100%",
  maxWidth: 464,
  display: "flex",
  // flexDirection: "column",
  // justifyContent: "center",
  position: "relative",
}));
const SectionStyle = styled(Card)(({ theme }) => ({
  width: `calc(50% - ${theme.spacing(4)})`,
  maxWidth: 444,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
  position: "fixed",
  height: `calc(100vh - ${theme.spacing(4)})`,
  top: theme.spacing(0),
  boxShadow: "0 16px 32px -4px rgba(145, 158, 171, 0.16)",
  borderRadius: "16px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const AuthLayout = function ({ children }) {
  const HeaderStyle = styled("header")(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    padding: theme.spacing(3),
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      padding: theme.spacing(7, 5, 0, 7),
    },
  }));

  const Logo = (sx) => (
    <Box sx={{ width: 31, height: 31 }}>
      <Box
        component="img"
        src="/static/logo.svg"
        sx={{ width: 31, height: 31, position: { md: "fixed" }, ...sx }}
      />
    </Box>
  );

  const LogoStyle = styled(Logo)(({ theme }) => ({
    position: "fixed",
    display: "inline-block",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
    },
  }));

  return (
    <HeaderStyle>
      <LogoStyle />

      <Typography
        variant="body2"
        sx={{
          display: { xs: "none", sm: "block" },
          mt: { md: -2 },
        }}
      >
        {children}
      </Typography>
    </HeaderStyle>
  );
};

export default function InitDatabase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [ignoreRedirs, setIgnoreRedirs] = useState(false);

  const sessionActive = useSelector((state) => state.loginState.sessionActive);
  const loadingLoginState = useSelector((state) => state.loginState.loading);
  const records = useSelector((state) => state.loginState.user.records);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const populateMockData = () => {
    // pass-on dispatch to the mock data generating function
    setOpen(true);
    setIgnoreRedirs(true);
    // this needs to be in the next tick to allow a bit of time to show the spinner

    setTimeout(() => {
      enqueueSnackbar("Mock data loaded", { variant: "info" });
    }, 900);

    setTimeout(() => {
      setTimeout(() => {
        generateMockDatabase(dispatch);
      }, 150);

      setTimeout(() => {
        const eventBday = new CustomEvent("run-our-task", {
          detail: { taskName: "birthdayEventTask" },
        });
        const eventMonthMarker = new CustomEvent("run-our-task", {
          detail: { taskName: "monthMarkerEventsTask" },
        });

        document.dispatchEvent(eventBday);
        document.dispatchEvent(eventMonthMarker);
      }, 500);

      setTimeout(() => {
        // TODO - this is not cancelled properly
        console.log("Redirecting populatemock...");
        navigate("/dashboard/timeline");
      }, 1000);
    }, 0);
  };

  const handleStoreUpdate = function () {
    if (ignoreRedirs) {
      console.log("Ignoring redir");
      return;
    }
    console.log("Store update!");
    const state = store.getState();
    const personalDatabaseContentsInternal = state.personalDatabase.contents;
    console.log(personalDatabaseContentsInternal);
    if (personalDatabaseContentsInternal !== null && !ignoreRedirs) {
      setIgnoreRedirs(true);

      const eventBday = new CustomEvent("run-our-task", {
        detail: { taskName: "birthdayEventTask" },
      });
      const eventMonthMarker = new CustomEvent("run-our-task", {
        detail: { taskName: "monthMarkerEventsTask" },
      });

      document.dispatchEvent(eventBday);
      document.dispatchEvent(eventMonthMarker);

      setTimeout(() => {
        enqueueSnackbar("Records loaded", { variant: "info" });
      }, 900);

      setTimeout(() => {
        // TODO - this is not cancelled properly
        console.log("Redirecting auto...");
        navigate("/dashboard/timeline");
      }, 1000);
    }
  };

  const THROTTLE_DELAY = 2000;
  const handleStoreUpdateThrottled = useMemo(
    () =>
      _.throttle(() => {
        handleStoreUpdate();
      }, THROTTLE_DELAY),
    [] //
  );

  useEffect(() => {
    setIgnoreRedirs(false);

    // Anything in here is fired on component mount.
    const unsubscribe = store.subscribe(handleStoreUpdateThrottled);

    // check just in case
    handleStoreUpdateThrottled();

    return () => {
      // Anything in here is fired on component unmount.
      unsubscribe();
      try {
        handleStoreUpdateThrottled.cancel();
      } catch (e) {
        console.log("Unable to cancel: ", e);
      }
    };
  }, []);

  // useEffect(() => {
  //   store.subscribe(handleChange)
  //   let didCancel = false;
  //   const goToProfilePage = () => navigate("/profile/database");
  //   if (personalDatabaseContents !== null) {
  //     goToProfilePage();
  //   }
  //   return () => {
  //     didCancel = true;
  //   };
  // }, [navigate]);

  return (
    <RootStyle title="Records | Holistory">
      {!sessionActive && loadingLoginState && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!sessionActive && !loadingLoginState && (
        <>
          {/* <HeaderStyle>
            <Logo />
          </HeaderStyle> */}
          Logged in user is required.
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/login"
          >
            Go to login
          </Link>
        </>
      )}
      {sessionActive && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <AuthLayout>
            {/* Don’t have an account? &nbsp;
              <Link
                underline="none"
                variant="subtitle2"
                component={RouterLink}
                to="https://framewurk.io"
              >
                Hello world
              </Link> */}
            {/* Hello world! */}
            {/* <RestoreDatabase /> */}

            {/* <Button
              disabled={open}
              variant="contained"
              onClick={populateMockData}
              component="span"
              sx={{ marginRight: "60px" }}
            >
              Load mock data
            </Button> */}
            {/* <Link
              disabled={open}
              variant="contained"
              onClick={() => userService.logout()}
              sx={{ textDecoration: "none", cursor: "pointer" }}
              component="a"
            >
              Logout
            </Link> */}

            <Link
              underline="none"
              variant="subtitle2"
              sx={{ fontWeight: 600, cursor: "pointer" }}
              onClick={() => userService.logout()}
            >
              Logout
            </Link>
          </AuthLayout>
          <SectionStyleContainer>
            <SectionStyle sx={{ display: { xs: "none", md: "flex" } }}>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome back
              </Typography>
              {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
            </SectionStyle>
          </SectionStyleContainer>

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack sx={{ mb: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Create a new record
                </Typography>
                {/* <Typography sx={{ color: "text.secondary" }}>
                  Initialise or restore your database
                </Typography> */}
              </Stack>

              <StartDatabase />

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  OR
                </Typography>
              </Divider>

              <Stack sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Choose an existing record
                </Typography>
                {/* <Typography sx={{ color: "text.secondary" }}>
                  Initialise or restore your database
                </Typography> */}
              </Stack>

              {records && (
                <Records records={records} setLoaderOpen={setOpen}></Records>
              )}

              <Typography
                variant="body2"
                align="center"
                sx={{
                  mt: 3,
                  display: { sm: "none" },
                }}
              >
                Hello world
              </Typography>
            </ContentStyle>
          </Container>
        </>
      )}
    </RootStyle>
  );
}
